.Certificats {
    display: flex;
    flex-wrap: wrap;
    margin-top: u($spacing-unit * -1);
    margin-left: u($spacing-unit * -1);
    opacity: 0.4;

    @include mq($from: $viewport--md) {
        justify-content: space-between;
    }

    > .Certificat {
        padding-top: u($spacing-unit);
        padding-left: u($spacing-unit);
    }
}
