.Block--team {

    .Filters {
        display: flex;
        gap: u(10);
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: u(30);
        flex-wrap: wrap;

        &-title {
            margin-top: u(2);
            margin-bottom: 0;
            margin-right: u(5);
            color: $cc-grey--darker;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 1.5px;
            text-transform: uppercase;
        }
    }
}

.Team {

    &-department {

        & + & {
            margin-top: u(60);
        }

        &-title {
            color: $cc-red;
            margin-bottom: u(22);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            letter-spacing: 1.5px;
        }
    }

    .Name {
        margin-top: u(14);
        display: block;
        font-weight: 500;
        font-size: u(14);
        color: $cc-grey--darker;
        line-height: normal;
    }

    .Function {
        margin-top: u(0);
        display: block;
        font-size: u(14);
        opacity: 0.6;
        color: $cc-grey--darker;
        line-height: normal;
    }

    &Member {
        @include position(relative, $z: 0);
        background-color: $white;
        overflow: hidden;
        width: 100%;

        &:hover {
            .TeamMember-body {
                transform: translateY(0);
            }
        }

        &-body {
            @include transition(transform, .275s, ease);
            background-color: $white;
            border-bottom: px(4) solid $cc-red;
            display: flex;
            flex-direction: column;
            row-gap: u($spacing-unit--xs);
            padding: u(8, 0);
            font-size: u(12);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            transform: translateY(100%);
        }

        &-innerBody {
            margin-top: auto;
            margin-bottom: auto;
            padding: u($spacing-unit);
            text-align: center;
        }

        &-item {
            color: $cc-black;
            display: flex;
            align-items: center;
            padding: u(0);

            &,
            &:active,
            &:focus,
            &:hover {
                color: currentColor;
                text-decoration: none;
            }

            &:hover {
                color: $cc-red;
            }
        }

        &-icon {
            color: $cc-grey;
            flex-shrink: 0;
            margin-right: u($spacing-unit--sm);
        }

        &-image {
            width: 100%;
            margin-right: auto;
            margin-left: auto;
        }

        &-title {
            @include position(absolute, $right: u($spacing-unit--sm), $bottom: u($spacing-unit--sm), $left: u($spacing-unit--sm), $z: 1);
            background-image: linear-gradient(to bottom, rgba($cc-black, 0), rgba($cc-black, 0.6));
            color: $white;
            height: u(60);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;
            text-transform: none;
            padding: u($spacing-unit--sm);
        }
    }
}

