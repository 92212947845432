.Tip {
    background-color: $cc-red;
    border-radius: u($spacing-unit);
    color: $white;
    display: flex;
    font-size: u(14);
    font-weight: 700;
    padding: u($spacing-unit--xs + 1, $spacing-unit);
    text-transform: uppercase;
    letter-spacing: px(1.5);
    clear: left;
    float: left;
    margin-top: u($spacing-unit--xs);
    margin-right: u($spacing-unit);
    margin-bottom: u($spacing-unit--xs);

    .Tip-icon {
        display: block;
        margin-right: u($spacing-unit--sm);
        flex-shrink: 0;
    }
}