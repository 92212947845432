.Block {
    &--text {
        .Container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            
            * {
                text-align: center;
                max-width: u(600);
            }
        }
    }
    &--textleft {
        .Container {
            align-items: flex-start;
            * {
                text-align: left;
            }
        }
    }
    &--textcenter {
        .Container {
            align-items: center;
            * {
                text-align: center;
            }
        }
    }
    &--textright {
        .Container {
            align-items: flex-end;
            * {
                text-align: right;
            }
        }
    }
}