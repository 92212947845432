.Block--cart {

    .Block--intro + &.Block {
        margin-top: u($spacing-unit * 1.5);
    }

    .Table {
        table-layout: auto;

        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit * -0.75);

            &-cell {
            }
        }

        &-cell {

            &--remove {

                @include mq($until: $viewport--md - 1) {
                    margin-top: u($spacing-unit--sm);
                    order: 2;
                }
            }
        }

        .Form-field[type="number"] {
            max-width: u(80);
        }
    }

    .Remove {
        @include transition(color);
        color: $cc-grey;
        cursor: pointer;
        display: flex;
        align-items: center;

        @include mq($until: $viewport--md - 1) {
            font-size: 90%;
        }

        &-icon {
            display: block;

            @include mq($until: $viewport--md - 1) {
                margin-right: u($spacing-unit--sm);
            }
        }

        &:hover {
            color: $cc-red;
        }
    }

    .Cart-items {

        @include mq($from: $viewport--md) {
            padding-bottom: u(155);
        }
    }
}

.Cart {
    @include transition(#{height, padding});
    background-color: $cc-red--dark;
    display: flex;
    align-items: center;
    padding: u($spacing-unit * 0.75);
    text-transform: lowercase;
    text-decoration: none;

    @include mq($from: $viewport--md) {
        padding: u($spacing-unit--xs, 24);
        height: u(60);

        &:hover {
            height: u(80);
        }
    }

    &,
    &:active,
    &:focus,
    &:hover {
        color: $white;
    }

    &-object {
        @include transition(width);
        flex-shrink: 0;
        width: u(23);

        @include mq($from: $viewport--md) {
            margin-right: u($spacing-unit);
        }

        &[data-total] {
            @include position(relative, $z: 1);

            &::after {
                @include transition(#{top, right, transform, opacity});
                @include position(absolute, $top: 0, $right: 0, $z: 1);
                @include dimensions(16);
                background-color: $white;
                border-radius: 100%;
                border: px(1) solid $cc-red--dark;
                content: attr(data-total);
                color: $cc-red--dark;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 70%;
                font-weight: 500;
                transform: translate(65%, -25%);
            }
        }
    }

    &-icon {
        @include transition(#{transform, opacity});
    }

    .Header.is-notTop.is-pinned & {
        height: u(40);
        padding-right: u($spacing-unit);
        padding-left: u($spacing-unit);

        &-object {
            width: 0;

            @include mq($from: $viewport--md) {
                width: u(9);
            }

            &::after {
                top: 50%;
                transform: translate(50%, -50%);
            }
        }

        &-icon {
            transform: translateY(-100%);
            opacity: 0;
        }
    }
}
