.Client {

    &.Block-item {
        padding-top: u($spacing-unit * 1.5);
        padding-bottom: u($spacing-unit * 1.5);
    }

    &-image {
        margin-right: auto;
        margin-bottom: u($spacing-unit);
        margin-left: auto;
    }

    &-object {
        margin-bottom: u($spacing-unit);
    }

    &-body {
        text-align: center;

        > * {
            display: inline;
        }

        :last-child {
            margin-bottom: 0;
        }
    }

    &-subtitle {
        text-transform: none;

        &::after {
            content: ':';
        }
    }
}

.Flow {
    max-width: u(740);

    &-item {

        & + & {
            margin-top: u($spacing-unit--lg);
        }
    }
}

.Project-header {

    .Nav--pagination {

        .Nav-list {
            justify-content: flex-start;
        }
    }

    @include mq($from: $viewport--md) {
        display: flex;

        .Intro-title {
            flex-grow: 1;
        }

        .Nav--pagination {
            margin-left: u($spacing-unit--sm);
            align-self: baseline;
            flex-shrink: 0;
        }
    }
}
