/* General */

// LazySizes
.LazyLoad,
.LazyLoading {
    opacity: 0;
}

.LazyLoaded {
    @include transition(opacity, 300ms);
    opacity: 1;
}

// Tabbing
body:not(.user-is-tabbing) {

    span:focus,
    button:focus,
    input:focus,
    select:focus,
    textarea:focus {
        outline: none;
    }
}

// Headroom
.Main {
    padding-top: u(120);
    overflow: hidden;

    @include mq($from: $viewport--md) {
        padding-top: u(180);
    }

    div {
        &:not(:last-of-type) {

            &.Block--boxes {
                overflow: hidden;
                padding-bottom: u($spacing-unit--lg * 3);
            }
        }
    }
}

// Chemicals
.site-chemicals {

    .Header {
        .Logo {
            @include mq($until: 1044) {
                width: u(100);
            }

            @include mq($from: 1044, $until: 1099) {
                width: u(150);
            }
        }

        &.is-notTop {

            .Logo {
                @include mq($from: 1044) {
                    width: u(150);
                }
            }
        }
    }


    .Shape {
        width: calc(200px / 5);
        height: calc(105px / 5);

        &::before {
            background-image: url('/assets/img/icons/chemicals.svg');
            width: calc(200px / 5);
            height: calc(105px / 5);
        }
    }
}

// Industry
.site-industry {

    .Header {
        .Logo {
            @include mq($until: 1044) {
                width: u(100);
            }

            @include mq($from: 1044, $until: 1099) {
                width: u(150);
            }
        }

        &.is-notTop {

            .Logo {
                @include mq($from: 1044) {
                    width: u(150);
                }
            }
        }
    }


    .Shape {
        width: calc(200px / 6);
        height: calc(140px / 6);

        &::before {
            background-image: url('/assets/img/icons/industry.svg');
            width: calc(200px / 6);
            height: calc(140px / 6);
        }
    }
}

// Maritime
.site-maritime {


    .Header {
        .Logo {
            @include mq($until: 1044) {
                width: u(100);
            }

            @include mq($from: 1044, $until: 1099) {
                width: u(150);
            }
        }

        &.is-notTop {

            .Logo {
                @include mq($from: 1044) {
                    width: u(150);
                }
            }
        }
    }

    .Shape {
        width: calc(200px / 8);
        height: calc(106px / 8);

        &::before {
            background-image: url('/assets/img/icons/maritime.svg');
            width: calc(200px / 8);
            height: calc(106px / 8);
        }
    }
}

// Group
.site-group {

    .Shape {
        width: calc(200px / 4);
        height: calc(110px / 4);

        &::before {
            background-image: url('/assets/img/icons/group.svg');
            width: calc(200px / 4);
            height: calc(110px / 4);
        }
    }
}


// Shape
.Shape {
    @include dimensions(50);
    display: block;
    flex-shrink: 0;

    &::before {
        content: "";
        display: block;
        background-repeat: no-repeat;
    }
}

.has-shape {
    position: relative;

    .Shape {
        position: absolute;
        right: calc(100% + 20px);
        top: 50%;
        transform: translateY(-50%);
    }
}

// Link
.Link {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }

    &,
    &:active,
    &:focus,
    &:hover {
        color: currentColor;
    }

    &--secondary {
        display: inline-flex;
        align-items: center;

        .Link-icon {
            margin-right: u($spacing-unit--sm);

            &--bottom {
                transform: rotate(90deg);
                display: block;
                margin-top: u($spacing-unit--sm);
                margin-right: auto;
                margin-left: auto;
            }
        }

        &,
        &:active,
        &:focus,
        &:hover {
            color: $cc-red;
        }
    }

    &--tertiary {
        display: inline-flex;
        align-items: center;
        text-decoration: none;

        .Link-icon {
            margin-right: u($spacing-unit--xs + 1);
        }

        &:hover {
            text-decoration: underline;
        }

        &,
        &:active,
        &:focus,
        &:hover {
            color: $cc-grey;
        }
    }
}

// Definition
.Definition {
    color: $cc-grey--dark;
    display: block;
    font-size: 75%;
    font-style: normal;
    font-weight: 400;
    line-height: 1.33;
    margin-top: u($spacing-unit--xs);
}

// List
ul:not([class]) {
    list-style-type: none;
    padding-left: 0;

    > li {
        margin-bottom: 0;
        padding-left: u(25);
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: u(5);
            @include dimensions(15);
            background-image: svg-url("<svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.74997 9.93241L3.31747 7.49991L2.48914 8.32241L5.74997 11.5832L12.75 4.58324L11.9275 3.76074L5.74997 9.93241Z' fill='#C4281A'/></svg>");
        }
    }
}

// Play
.Play {
    color: $white;
    font-weight: 500;
    text-transform: uppercase;
    text-shadow: 0 0 u(8) $black;
    letter-spacing: 1.5px;
    line-height: 2.14;

    &-icon {
        display: block;
        margin-right: auto;
        margin-bottom: u($spacing-unit / 2.5);
        margin-left: auto;
    }
}

.Comment {
    white-space: nowrap;

    .Icon {
        @include transition(color);
        color: rgba($cc-black, .8);
        cursor: pointer;

        &:hover {
            color: $cc-black;
        }
    }
}

.Price {
    color: $cc-red--dark;
    font-weight: 750;
    font-size: u(20);
    line-height: u(28);

    &-tax {
        color: $cc-grey--dark;
        font-weight: 400;
        font-size: u(14);
        line-height: u(18);
    }
}