.TextColumns {
    column-count: 2;
    column-width: u(300);
    column-rule: px(1) solid rgba($cc-grey, 0.2);
    column-gap: u($spacing-unit--xl);

    .TextColumn {
        break-inside: avoid;

        &-title {
            color: $cc-red;
        }
    }
}