.Block--form {

    .Intro-body {
        text-align: center;
        margin-right: auto;
        margin-left: auto;
    }

    &.Block--boxes {

        .Boxes {
            padding-bottom: 0;
        }
    }

}

.Form {

    &:not(.Form--search) {
        background-color: $white;
        border: px(1) solid rgba($cc-grey, 0.2);
        max-width: u(740);
        margin-right: auto;
        margin-left: auto;
        padding: u($spacing-unit * 1.5);

        @include mq($from: $viewport--md) {
            padding: u($spacing-unit--lg, $spacing-unit * 3);
        }
    }

    .Grid--withGutter {
        margin-left: u($spacing-unit * -1);

        > .Grid-cell {
            padding-left: u($spacing-unit);
        }
    }

    .Block--cart & {
        margin-top: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit * -5);
        }

        &-item {
            margin-bottom: 0;
        }

        &-item--action {
            margin-top: u($spacing-unit * 1.5);
            text-align: center;
        }
    }
}
