.Nav--socialMedia {
    color: rgba($cc-grey, 0.8);

    // List
    .Nav-list {
        margin-left: u($spacing-unit * -1);
    }

    // Item
    .Nav-item {
        padding-left: u($spacing-unit);
    }

    // Item
    .Nav-link {
        color: currentColor;

        &:active,
        &:focus,
        &:hover {
            color: $cc-red;
        }
    }

    // Icon
    .Nav-icon {
        fill: currentColor;
        display: block;
    }
}
