.Nav--breadcrumbs {

    .Nav-list {
        align-items: center;

        @include mq($from: $viewport--md) {
            flex-wrap: nowrap;
        }
    }

    .Nav-item {

        &:not(.has-truncate) {
            flex-shrink: 0;
        }

        &.has-truncate {
            flex-shrink: 1;
            min-width: 0;
        }

        &:not(:last-child) {
            margin-right: u(12);
        }
    }

    .Nav-link {
        font-size: u(11);
        margin-bottom: 0;

        &,
        &:active,
        &:focus,
        &:hover {
            color: $cc-black;
        }

        &:not(.is-active):hover {
            color: $cc-red;
        }
    }

    .Nav-icon {
        display: block;
        margin-top: u(-2);

        @include mq($until: $viewport--md - 1) {
            transform: scaleX(-1);
        }
    }
}
