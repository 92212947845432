.Block--related {

    + .Block--related {
        padding-top: u($spacing-unit * 3);

        @include mq($from: $viewport--md) {
            padding-top: u($spacing-unit * 6);
        }
    }
}
