.NumbersInfo {

    @include mq($from: $viewport--md) {
        display: flex;
        align-items: center;
        margin-left: u($spacing-unit--xl * -1);

        &-body,
        &-object {
            padding-left: u($spacing-unit--xl);
        }
    }

    &-title {
        color: $cc-black;
    }

    + .Numbers {
        margin-top: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit--xl);
        }
    }
}

.Numbers {
    display: flex;
    flex-wrap: wrap;
    margin-top: px(-1);
    margin-left: px(-1);

    > .Number {
        border: px(1) solid rgba($cc-grey, 0.2);
        padding: u($spacing-unit * 1.5);
        flex-shrink: 0;
        margin-top: px(-1);
        margin-left: px(-1);
        width: 100%;

        @include mq($from: $viewport--sm, $until: $viewport--lg - 1) {
            width: 50%;
        }

        @include mq($from: $viewport--lg) {
            width: 25%;
        }
    }
}

.Number {
    text-align: center;

    &-object,
    &-value {
        color: $cc-red;
    }

    &-label {
        color: $cc-grey;
    }
}
