.Jobs {

    &-title {
        font-size: inherit;
        font-weight: 700;
        margin-bottom: 0;
        color: $cc-red;
        font-size: u(16);
        line-height: u(28);
    }

    &-item {
        border-top: px(1) solid rgba($cc-grey, 0.4);
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;
        padding: u($spacing-unit);
        line-height: 1.4;

        .Nav-icon {
            color: $cc-red;
        }

        &:last-child {
            border-bottom: px(1) solid rgba($cc-grey, 0.4);
        }

        &,
        &:active,
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }

    &-items {
        margin-top: u(20);
        margin-bottom: u(48);
    }
}
