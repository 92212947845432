/* Print */
@include mq($media-type: 'print') {
    *,
    *::before,
    *::after,
    *::first-letter,
    p::first-line,
    div::first-line,
    blockquote::first-line,
    li::first-line {
        background: transparent !important;
        box-shadow: none !important;
        color: $black !important;
        letter-spacing: 0;
        text-shadow: none !important;
    }

    a,
    a:visited { text-decoration: underline; }

    a[href]::after { content: " (" attr(href) ")"; }

    abbr[title]::after { content: " (" attr(title) ")"; }

    /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */

    a[href^="#"]::after,
    a[href^="javascript:"]::after { content: ""; }

    pre { white-space: pre-wrap !important; }

    pre,
    blockquote {
        border: 1px solid $grey;
        page-break-inside: avoid;
    }

    /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */

    thead { display: table-header-group; }

    tr,
    img { page-break-inside: avoid; }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 { page-break-after: avoid; }

    /**
     * General
     */

    html {
        font-family: sans-serif;
        font-size: 9pt;
    }

    .Container {
        max-width: none;
    }

    h1,
    h2,
    h3 {
        margin-bottom: 9pt;
        text-transform: none;
    }

    h1 {
        font-size: 18pt;
    }

    h2 {
        font-size: 12pt;
    }

    h3 {
        font-size: 10pt;
    }

    /**
     * Custom
     */

    .Header,
    .Footer,
    .Shape,
    .Nav--breadcrumbs,
    .AddToCart,
    .Info-object > .Thumbnails {
        display: none;
    }

    .Container {
        max-width: none;
        padding-right: 0;
        padding-left: 0;
    }

    .Main {
        padding-top: 0;
    }

    .Slider-item {
        width: 100% !important;

        &:not(:only-child):not(.is-active) {
            display: none;
        }
    }

    .Block {

        &-more {
            display: none;
        }

        .Block--intro + & {
            margin-top: 0;
        }

        &:first-child {

            .Main > & {
                margin-top: 0;
            }
        }
    }

    .Info {
        display: flex;

        &-object {
            width: (100% / 3);
        }

        &-body {
            width: (100% / 3 * 2);
        }
    }

    .ProductsPage .Intro-title {
        margin-bottom: 0;
    }

    .Intro-header {
        margin-bottom: 24pt;
    }

    .Info-object > .Slider {
        border: 0;
    }

    .Block-item {
        border: 0;
        padding: 0;

        &.Products {

            td:last-child,
            th:last-child {
                display: none;
            }

            .Products-link::after {
                content: none;
            }
        }
    }

    .ProductInfo-item {
        padding: 0;
        padding-bottom: 3pt;
        margin-bottom: 3pt;
    }

    .ProductInfo-label {
        font-weight: 700;
    }
}
