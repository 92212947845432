.Teaser {
    @include position(relative, $z: 0);
    display: block;
    text-decoration: none;
    max-width: u(390);
    margin-right: auto;
    margin-left: auto;
    width: 100%;

    &--spotlight {
        max-width: u(795);
    }

    &,
    &:active,
    &:focus,
    &:hover {
        color: $white;
    }

    &::after {
        @include dimensions(0, 6);
        @include transition(width);
        @include position(absolute, $right: 0, $bottom: 0, $left: 0, $z: 3);
        background-color: $cc-red;
        content: '';
        display: block;
    }

    &:hover::after {
        width: 100%;
    }

    &-body {
        @include position(absolute, $right: 0, $bottom: 0, $left: 0, $z: 2);
        padding: u($spacing-unit * 1.5, $spacing-unit--lg);

        .Button {
            display: inline-flex;
            align-items: center;

            &-icon {
                margin-left: u($spacing-unit);
            }
        }
    }

    &-object {
        @include position(relative, $z: 0);
        background-color: $cc-black;
        padding-bottom: (100% / 15 * 16);
        overflow: hidden;

        .Teaser--spotlight & {

            @include mq($from: $viewport--md) {
                padding-bottom: (100% / 795 * 416);
            }
        }

        &::after {
            @include position(absolute, 50%, 0, 0, 0, 1);
            background-image: linear-gradient(182deg, rgba($black, 0), $black);
            content: '';
        }
    }

    &-image {
        @include dimensions(100%);
        @include position(absolute, 0, 0, 0, 0, 1);
        @include transition(#{opacity, transform});
        object-fit: cover;

        .Teaser:hover & {
            opacity: 0.9;
            transform: scale(1.1);
        }
    }
}
