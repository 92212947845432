.Block--teasers {
    max-width: 125rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: u(map-get($grid-gutters, sm));
    padding-right: u(map-get($grid-gutters, sm));
}

.TeaserGridItem {
    @include position(relative, $z: 0);
    display: block;
    text-decoration: none;
    //max-width: u(390);
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    
    @include mq($until: $viewport--xl - 1) {
        min-height: 400px;
    }

    &--spotlight {
        max-width: u(795);
    }

    &,
    &:active,
    &:focus,
    &:hover {
        @include transition(#{height, margin-top});
        color: $white;
        height: 100%;

        @include mq($until: $viewport--lg) {
            max-height: 320px;
            overflow: hidden;
            max-width: 100%;
        }
    }

    &:hover {
        height: calc(100% + 60px);
        @include transition(#{height, margin-top});
        margin-top: -30px;

        @include mq($until: $viewport--lg) {
            height: 100%;
            margin-top: 0;    
        }

        .TeaserGridItem-hidden {
            // visibility: visible;
            display: block;
            height: auto;
            width: auto;
            opacity: 1;
            @include transition(opacity);
        }
    }

    &:hover::after {
        width: 100%;
    }

    &-body {
        @include position(absolute, $right: 0, $bottom: 0, $left: 0, $z: 2);
        padding: u($spacing-unit * 1.5, $spacing-unit--lg);
        
        .TeaserGridItem-hidden {
            // visibility: hidden;
            display: none;
            height: 0px;
            width: 0px;
            opacity: 0;
            @include transition(opacity);

            @include mq($until: $viewport--lg) {
                visibility: visible;
                display: block;
                height: auto;
                width: auto;
                opacity: 1;
            }
        }

        .TeaserGridItem-text {
            margin-top: u(10);
            font-size: u(16);
            font-weight: 400;
        }

        .TeaserGridItem-logo {
            height: u(160);
            margin-left: u(-16);
            margin-bottom: u(10);
        }

        .Button {
            align-items: flex-start;
            display: flex;
            flex-direction: column;

            &--arrow {
                display: block;
                width: 100%;
                font-size: u(18);
                text-align: left;
            }

            

            &-icon {
                margin-left: u(10);
            }
        }
    }

    &-object {
        @include position(relative, $z: 0);
        @include transition(height);
        background-color: $cc-black;
        padding-bottom: (100% / 15 * 16);
        overflow: hidden;
        height: 100%;

        .TeaserGridItem--spotlight & {

            @include mq($from: $viewport--md) {
                padding-bottom: (100% / 795 * 416);
            }
        }

        &::after {
            @include position(absolute, 50%, 0, 0, 0, 1);
            background-image: linear-gradient(182deg, rgba($black, 0), $black);
            content: '';
        }
    }

    &-image {
        @include dimensions(100%);
        height: 100%;
        width: 100%;

        @include position(absolute, 0, 0, 0, 0, 1);
        @include transition(#{opacity, transform, width, height});
        object-fit: cover;

        @include mq($until: $viewport--lg) {
            opacity: 0.2;
        }

        .TeaserGridItem:hover & {
            opacity: 0.2;
        }
    }

    .Button--primary {
        padding: 8px 5px;
        display: inline-block;
        font-weight: 400;
        font-size: u(14);
    }
}