@charset "UTF-8";
/* Style */
/* Settings: Base */
/* Settings: Colors */
/* Colors: Palette */
/* Colors: Custom */
/* Colors: Theme */
/* Settings: Fonts */
/* Elements: Heading */
/* Elements: HR */
/* Elements: Image */
/* Elements: Link */
/* Elements: List */
/* Elements: Paragraph */
/* Elements: Selection */
/* Objects: Alert */
/* Components: Button */
/* Objects: FlexEmbed */
/* Objects: Form */
/* Objects: Grid */
/* Objects: Nav */
/* Objects: Structure */
/* Objects: Table */
/* Objects: Toggle */
/* Components: Fields */
/* Components: Modal */
/* Components: DigitalPulse */
/* Functions: Throw */
/* Functions: Checks */
/* Functions: Truncate list */
/* Functions: Maps */
/* Functions: Maps */
/* Functions: Order index */
/* Functions: Units */
/* Functions: String */
/* Functions: SVG */
/* Mixin: Arrow */
/* Mixin: Background SVG */
/* Mixin: Caret */
/* Mixin: Context */
/* Mixin: Dimensions */
/* Mixin: Family */
/* Mixin: Font-responsive */
/* Mixin: Font-size */
/* Mixin: Font-smoothing */
/* Mixin: Grid */
/* Mixin: Image rendering */
/* Mixin: Last-children */
/* Mixin: Line-height crop */
/* Mixin: Maps */
/* Mixin: ParentNthStatus */
/* Mixin: ParentState */
/* Mixin: ParentStatus */
/* Mixin: Position */
/* Mixin: Maps */
/* Mixin: Transition */
/* Mixin: Truncate */
/* Reset */
*, *::before, *::after {
  box-sizing: inherit; }

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 1em;
  line-height: 1.75;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0; }

iframe {
  border: 0; }

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  display: block;
  width: 100%; }

option {
  font: inherit; }

textarea {
  resize: vertical; }

button {
  background: transparent;
  border: 0;
  padding: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

abbr[title],
dfn[title] {
  cursor: help; }

u,
ins {
  text-decoration: underline; }

img {
  font-style: italic; }

::selection {
  text-shadow: none; }

[tabindex="-1"]:focus {
  outline: none !important; }

/* HR */
hr {
  margin-top: 1.25rem;
  margin-bottom: 1.1875rem;
  color: #bdbdbd;
  border: 0;
  border-bottom: 1px solid; }

/* Image */
img {
  max-width: 100%;
  height: auto; }

figure > img,
picture > img,
picture {
  display: block; }

/* Link */
a {
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  color: #C4281A;
  text-decoration: underline; }
  a:hover, a:focus, a:active {
    color: #C4281A;
    text-decoration: none; }

/* List */
ol,
ul {
  margin-bottom: 1.25rem;
  padding-left: 1.25rem; }
  ol li,
  ul li {
    margin-bottom: 0.125rem; }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }

ul {
  list-style-type: disc; }

ul ul {
  list-style-type: circle; }

ul ul ul {
  list-style-type: square; }

ol {
  list-style-type: decimal; }

ol ol {
  list-style-type: upper-alpha; }

ol ol ol {
  list-style-type: lower-roman; }

ol ol ol ol {
  list-style-type: lower-alpha; }

dt {
  font-weight: 700; }

/* Selection */
::selection {
  color: #ffffff;
  background-color: #C4281A; }

/* Heading */
h1, .Alfa,
h2, .Bravo,
h3, .Charlie,
h4, .Delta,
h5, .Echo,
h6, .Foxtrot {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .fonts-loaded h1, .fonts-loaded .Alfa, .fonts-loaded
  h2, .fonts-loaded .Bravo, .fonts-loaded
  h3, .fonts-loaded .Charlie, .fonts-loaded
  h4, .fonts-loaded .Delta, .fonts-loaded
  h5, .fonts-loaded .Echo, .fonts-loaded
  h6, .fonts-loaded .Foxtrot {
    font-family: inherit; }
  h1 small, .Alfa small,
  h2 small, .Bravo small,
  h3 small, .Charlie small,
  h4 small, .Delta small,
  h5 small, .Echo small,
  h6 small, .Foxtrot small {
    font-weight: normal; }

h1, .Alfa {
  margin-bottom: 1.75rem;
  font-size: 2.5rem;
  line-height: 1.2;
  color: #9d1d06; }

h2, .Bravo {
  margin-bottom: 1.875rem;
  font-size: 1.875rem;
  line-height: 1.2;
  color: #C4281A;
  margin-bottom: 1.875rem;
  line-height: 1.29; }

h3, .Charlie {
  margin-bottom: 1.75rem;
  font-size: 1.25rem;
  color: #9d1d06;
  line-height: 1.4; }

h4, .Delta {
  margin-bottom: 1.75rem;
  font-size: 0.875rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #313131; }

h5, .Echo {
  margin-bottom: 1.75rem;
  font-size: 0.875rem; }

h6, .Foxtrot {
  margin-bottom: 1.75rem;
  font-size: 0.875rem; }

.Giga {
  margin-bottom: 1.75rem;
  font-size: 6rem;
  line-height: 1.1; }

.Mega {
  margin-bottom: 1.75rem;
  font-size: 4.5rem;
  line-height: 1.1; }

.Kilo {
  margin-bottom: 1.75rem;
  font-size: 3rem;
  line-height: 1.2; }

.Milli {
  margin-bottom: 1.75rem;
  font-size: 0.75rem; }

.Micro {
  margin-bottom: 1.75rem;
  font-size: 0.625rem; }

/* Paragraph */
p {
  margin-bottom: 1.25rem; }

.Lead {
  font-size: 1.15rem; }

/* Main */
html {
  background-color: #ffffff;
  color: rgba(49, 49, 49, 0.6);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100%; }
  html.fonts-loaded {
    font-family: "DaxlinePro", sans-serif; }
  html.hide-scroll {
    overflow: hidden; }

body {
  min-height: 100%;
  position: relative; }
  @media screen and (min-width: 48em) {
    body::after {
      transition-property: opacity, visibility;
      transition-duration: 200ms;
      transition-timing-function: linear;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      background-color: rgba(49, 49, 49, 0.6);
      content: '';
      transition-delay: 200ms;
      opacity: 0;
      visibility: hidden; }
      .show-nav body::after,
      .show-search body::after {
        opacity: 1;
        visibility: visible; } }

/* Alert */
.Alert {
  position: relative;
  margin-bottom: 1.25rem;
  padding: 0.625em 0.83333em 0.625em 0.83333em;
  border: 1px solid;
  border-radius: 2px; }
  .Alert.is-dismissable {
    padding-right: 1.66667rem; }
  .Alert p,
  .Alert ul {
    margin-bottom: 0; }
    .Alert p + p,
    .Alert p + ul,
    .Alert ul + p,
    .Alert ul + ul {
      margin-top: 1.25rem; }
  .Alert a:not(.Button--close) {
    font-weight: bold; }
    .Alert a:not(.Button--close):hover {
      text-decoration: underline; }

/* Button */
/*
 * 1. Corrects inability to style clickable `input` types in iOS.
 * 2. Normalize `box-sizing` across all elements that this component could be
 *    applied to.
 * 3. Inherit text color from ancestor.
 * 4. Inherit font styles from ancestor.
 * 5. Normalize `line-height`. For `input`, it can't be changed from `normal`
 *    in Firefox 4+.
 * 6. Prevent button text from being selectable.
 * 7. Make sure `input` will wrap text across multiple lines.
 *
 */
.Button {
  appearance: none;
  background: transparent;
  border-color: transparent;
  border-radius: 0;
  border-style: solid;
  border-width: 0px;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0.625em 1.875em 0.625em 1.875em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: normal; }
  .Button::-moz-focus-inner {
    border: 0;
    padding: 0; }
  .Button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }
  .Button:active, .Button:focus, .Button:hover, .Button.is-active {
    color: inherit;
    text-decoration: none; }
  .Button:disabled, .Button.is-disabled {
    cursor: default;
    opacity: 0.6;
    pointer-events: none; }

/* FlexEmbed */
.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative; }
  .FlexEmbed--placeholder .FlexEmbed-content {
    background-color: #eeeeee;
    background-image: url("../img/email/logo--footer.png");
    background-repeat: no-repeat;
    background-size: 75% auto;
    background-position: 50% 50%; }
  .FlexEmbed::before {
    content: '';
    display: block;
    padding-bottom: 100%;
    width: 100%; }

.FlexEmbed-content,
.FlexEmbed-content > iframe,
.FlexEmbed-content > object,
.FlexEmbed-content > embed,
.FlexEmbed-content > video {
  height: 100% !important;
  width: 100% !important;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

img.FlexEmbed-content {
  object-fit: cover; }

.FlexEmbed--2by1:before {
  padding-bottom: 50%; }

.FlexEmbed--3by1:before {
  padding-bottom: 33.33333%; }

.FlexEmbed--3by2:before {
  padding-bottom: 66.66667%; }

.FlexEmbed--4by3:before {
  padding-bottom: 75%; }

.FlexEmbed--16by9:before {
  padding-bottom: 56.25%; }

.FlexEmbed--1by2:before {
  padding-bottom: 200%; }

.FlexEmbed--1by3:before {
  padding-bottom: 300%; }

.FlexEmbed--2by3:before {
  padding-bottom: 150%; }

.FlexEmbed--3by4:before {
  padding-bottom: 133.33333%; }

.FlexEmbed--9by16:before {
  padding-bottom: 177.77778%; }

/* Form */
.Form-item:not(.Form-item--action) {
  margin-bottom: 1.875rem; }

.Form-label {
  display: inline-block;
  margin-bottom: 0rem; }
  .Form-item:not(.is-required) .Form-label[data-optional]::after {
    content: attr(data-optional);
    font-size: 65%;
    font-style: italic;
    margin-left: 0.3125rem;
    text-transform: lowercase; }
  .Form-item.has-error .Form-label {
    color: #f44336; }
  .Form-item.is-success .Form-label {
    color: #4caf50; }

.Form-help {
  margin-top: 0.3125rem;
  color: #bdbdbd;
  font-size: 0.75rem; }
  .Form-help:first-child {
    margin-top: 0;
    margin-bottom: 0.625rem; }
  .Form-help :last-child {
    margin-bottom: 0; }
  .Form-help + .Form-help {
    margin-top: 0.625rem;
    padding-top: 0.625rem;
    border-top: 1px solid #e0e0e0; }

.Form-item.has-error .Form-help--validation {
  color: #f44336; }

.Form-item.is-success .Form-help--validation {
  color: #4caf50; }

.Form-controls {
  position: relative; }

@media screen and (min-width: 37.5em) {
  .Form--horizontal .Form-item {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start; }
  .Form--horizontal .Form-label {
    margin-top: 0rem;
    margin-bottom: 0;
    padding-right: 1.25rem;
    width: 33.33333%; }
  .Form--horizontal .Form-controls {
    width: 66.66667%; }
  .Form--horizontal .Form-item--action {
    padding-left: 33.33333%; } }

.Media-offscreen {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal; }

.Media-container {
  background: #000;
  box-sizing: border-box;
  font-family: 'Helvetica', Arial, serif;
  position: relative;
  text-align: left;
  text-indent: 0;
  vertical-align: top; }

.Media-container * {
  box-sizing: border-box; }

/* Hide native play button and control bar from iOS to favor plugin button */
.Media-container video::-webkit-media-controls,
.Media-container video::-webkit-media-controls-panel,
.Media-container video::-webkit-media-controls-panel-container,
.Media-container video::-webkit-media-controls-start-playback-button {
  -webkit-appearance: none;
  display: none !important; }

.Media-fill-container,
.Media-fill-container .Media-container {
  height: 100%;
  width: 100%; }

.Media-fill-container {
  background: transparent;
  margin: 0 auto;
  overflow: hidden;
  position: relative; }

.Media-container:focus {
  outline: none; }

.Media-iframe-overlay {
  height: 100%;
  position: absolute;
  width: 100%; }

.Media-embed,
.Media-embed body {
  background: #000;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

.Media-fullscreen {
  overflow: hidden !important; }

.Media-container-fullscreen {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000; }

.Media-container-fullscreen .Media-mediaelement,
.Media-container-fullscreen video {
  height: 100% !important;
  width: 100% !important; }

/* Start: LAYERS */
.Media-background {
  left: 0;
  position: absolute;
  top: 0; }

.Media-mediaelement {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0; }

.Media-poster {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1; }

:root .Media-poster-img {
  display: none; }

.Media-poster-img {
  border: 0;
  padding: 0; }

.Media-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0; }

.Media-layer {
  z-index: 1; }

.Media-overlay-play {
  cursor: pointer; }

.Media-overlay-button {
  background: url("../img/media/controls.svg") no-repeat;
  background-position: 0 -39px;
  height: 80px;
  width: 80px; }

.Media-overlay:hover > .Media-overlay-button {
  background-position: -80px -39px; }

.Media-overlay-loading {
  height: 80px;
  width: 80px; }

.Media-overlay-loading-bg-img {
  animation: Media-loading-spinner 1s linear infinite;
  background: transparent url("../img/media/controls.svg") -160px -40px no-repeat;
  display: block;
  height: 80px;
  width: 80px;
  z-index: 1; }

@keyframes Media-loading-spinner {
  100% {
    transform: rotate(360deg); } }

/* End: LAYERS */
/* Start: CONTROL BAR */
.Media-controls {
  bottom: 0;
  display: flex;
  height: 40px;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 0 10px;
  position: absolute;
  width: 100%;
  z-index: 3; }

.Media-controls:not([style*='display: none']) {
  background: rgba(255, 0, 0, 0.7);
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.35)); }

.Media-button,
.Media-time,
.Media-time-rail {
  font-size: 10px;
  height: 40px;
  line-height: 10px;
  margin: 0;
  width: 32px; }

.Media-button > button {
  background: transparent url("../img/media/controls.svg");
  border: 0;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  margin: 10px 6px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-decoration: none;
  width: 20px; }

/* :focus for accessibility */
.Media-button > button:focus {
  outline: dotted 1px #999; }

.Media-container-keyboard-inactive a,
.Media-container-keyboard-inactive a:focus,
.Media-container-keyboard-inactive button,
.Media-container-keyboard-inactive button:focus,
.Media-container-keyboard-inactive [role=slider],
.Media-container-keyboard-inactive [role=slider]:focus {
  outline: 0; }

/* End: CONTROL BAR */
/* Start: Time (Current / Duration) */
.Media-time {
  box-sizing: content-box;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  height: 24px;
  overflow: hidden;
  padding: 16px 6px 0;
  text-align: center;
  width: auto; }

/* End: Time (Current / Duration) */
/* Start: Play/Pause/Stop */
.Media-play > button {
  background-position: 0 0; }

.Media-pause > button {
  background-position: -20px 0; }

.Media-replay > button {
  background-position: -160px 0; }

/* End: Play/Pause/Stop */
/* Start: Progress Bar */
.Media-time-rail {
  direction: ltr;
  flex-grow: 1;
  height: 40px;
  margin: 0 10px;
  padding-top: 10px;
  position: relative; }

.Media-time-total,
.Media-time-buffering,
.Media-time-loaded,
.Media-time-current,
.Media-time-float,
.Media-time-hovered,
.Media-time-float-current,
.Media-time-float-corner,
.Media-time-marker {
  border-radius: 2px;
  cursor: pointer;
  display: block;
  height: 10px;
  position: absolute; }

.Media-time-total {
  background: rgba(255, 255, 255, 0.3);
  margin: 5px 0 0;
  width: 100%; }

.Media-time-buffering {
  animation: buffering-stripes 2s linear infinite;
  background: linear-gradient(-45deg, rgba(255, 255, 255, 0.4) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0.4) 75%, transparent 75%, transparent);
  background-size: 15px 15px;
  width: 100%; }

@keyframes buffering-stripes {
  from {
    background-position: 0 0; }
  to {
    background-position: 30px 0; } }

.Media-time-loaded {
  background: rgba(255, 255, 255, 0.3); }

.Media-time-current,
.Media-time-handle-content {
  background: rgba(255, 255, 255, 0.9); }

.Media-time-hovered {
  background: rgba(255, 255, 255, 0.5);
  z-index: 10; }

.Media-time-hovered.negative {
  background: rgba(0, 0, 0, 0.2); }

.Media-time-current,
.Media-time-buffering,
.Media-time-loaded,
.Media-time-hovered {
  left: 0;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: 0.15s ease-in all;
  width: 100%; }

.Media-time-buffering {
  transform: scaleX(1); }

.Media-time-hovered {
  transition: height 0.1s cubic-bezier(0.44, 0, 1, 1); }

.Media-time-hovered.no-hover {
  transform: scaleX(0) !important; }

.Media-time-handle,
.Media-time-handle-content {
  border: 4px solid transparent;
  cursor: pointer;
  left: 0;
  position: absolute;
  transform: translateX(0);
  z-index: 11; }

.Media-time-handle-content {
  border: 4px solid rgba(255, 255, 255, 0.9);
  border-radius: 50%;
  height: 10px;
  left: -7px;
  top: -4px;
  transform: scale(0);
  width: 10px; }

.Media-time-rail:hover .Media-time-handle-content,
.Media-time-rail .Media-time-handle-content:focus,
.Media-time-rail .Media-time-handle-content:active {
  transform: scale(1); }

.Media-time-float {
  background: #eee;
  border: solid 1px #333;
  bottom: 100%;
  color: #111;
  display: none;
  height: 17px;
  margin-bottom: 9px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: 36px; }

.Media-time-float-current {
  display: block;
  left: 0;
  margin: 2px;
  text-align: center;
  width: 30px; }

.Media-time-float-corner {
  border: solid 5px #eee;
  border-color: #eee transparent transparent;
  border-radius: 0;
  display: block;
  height: 0;
  left: 50%;
  line-height: 0;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
  width: 0; }

.Media-long-video .Media-time-float {
  margin-left: -23px;
  width: 64px; }

.Media-long-video .Media-time-float-current {
  width: 60px; }

.Media-broadcast {
  color: #fff;
  height: 10px;
  position: absolute;
  top: 15px;
  width: 100%; }

/* End: Progress Bar */
/* Start: Fullscreen */
.Media-fullscreen-button > button {
  background-position: -80px 0; }

.Media-unfullscreen > button {
  background-position: -100px 0; }

/* End: Fullscreen */
/* Start: Mute/Volume */
.Media-mute > button {
  background-position: -60px 0; }

.Media-unmute > button {
  background-position: -40px 0; }

.Media-volume-button {
  position: relative; }

.Media-volume-button > .Media-volume-slider {
  -webkit-backface-visibility: hidden;
  background: rgba(50, 50, 50, 0.7);
  border-radius: 0;
  bottom: 100%;
  display: none;
  height: 115px;
  left: 50%;
  margin: 0;
  position: absolute;
  transform: translateX(-50%);
  width: 25px;
  z-index: 1; }

.Media-volume-button:hover {
  border-radius: 0 0 4px 4px; }

.Media-volume-total {
  background: rgba(255, 255, 255, 0.5);
  height: 100px;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 8px;
  transform: translateX(-50%);
  width: 2px; }

.Media-volume-current {
  background: rgba(255, 255, 255, 0.9);
  left: 0;
  margin: 0;
  position: absolute;
  width: 100%; }

.Media-volume-handle {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 1px;
  cursor: ns-resize;
  height: 6px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 16px; }

.Media-horizontal-volume-slider {
  display: block;
  height: 36px;
  position: relative;
  vertical-align: middle;
  width: 56px; }

.Media-horizontal-volume-total {
  background: rgba(50, 50, 50, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 8px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 16px;
  width: 50px; }

.Media-horizontal-volume-current {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 2px;
  font-size: 1px;
  height: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%; }

.Media-horizontal-volume-handle {
  display: none; }

/* End: Mute/Volume */
/* Start: Track (Captions and Chapters) */
.Media-captions-button,
.Media-chapters-button {
  position: relative; }

.Media-captions-button > button {
  background-position: -140px 0; }

.Media-chapters-button > button {
  background-position: -180px 0; }

.Media-captions-button > .Media-captions-selector,
.Media-chapters-button > .Media-chapters-selector {
  background: rgba(50, 50, 50, 0.7);
  border: solid 1px transparent;
  border-radius: 0;
  bottom: 100%;
  margin-right: -43px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 50%;
  visibility: visible;
  width: 86px; }

.Media-chapters-button > .Media-chapters-selector {
  margin-right: -55px;
  width: 110px; }

.Media-captions-selector-list,
.Media-chapters-selector-list {
  list-style-type: none !important;
  margin: 0;
  overflow: hidden;
  padding: 0; }

.Media-captions-selector-list-item,
.Media-chapters-selector-list-item {
  color: #fff;
  cursor: pointer;
  display: block;
  list-style-type: none !important;
  margin: 0 0 6px;
  overflow: hidden;
  padding: 0; }

.Media-captions-selector-list-item:hover,
.Media-chapters-selector-list-item:hover {
  background-color: #c8c8c8 !important;
  background-color: rgba(255, 255, 255, 0.4) !important; }

.Media-captions-selector-input,
.Media-chapters-selector-input {
  clear: both;
  float: left;
  left: -1000px;
  margin: 3px 3px 0 5px;
  position: absolute; }

.Media-captions-selector-label,
.Media-chapters-selector-label {
  cursor: pointer;
  float: left;
  font-size: 10px;
  line-height: 15px;
  padding: 4px 10px 0;
  width: 100%; }

.Media-captions-selected,
.Media-chapters-selected {
  color: #21f8f8; }

.Media-captions-translations {
  font-size: 10px;
  margin: 0 0 5px; }

.Media-captions-layer {
  bottom: 0;
  color: #fff;
  font-size: 16px;
  left: 0;
  line-height: 20px;
  position: absolute;
  text-align: center; }

.Media-captions-layer a {
  color: #fff;
  text-decoration: underline; }

.Media-captions-layer[lang=ar] {
  font-size: 20px;
  font-weight: normal; }

.Media-captions-position {
  bottom: 15px;
  left: 0;
  position: absolute;
  width: 100%; }

.Media-captions-position-hover {
  bottom: 35px; }

.Media-captions-text,
.Media-captions-text * {
  background: rgba(20, 20, 20, 0.5);
  box-shadow: 5px 0 0 rgba(20, 20, 20, 0.5), -5px 0 0 rgba(20, 20, 20, 0.5);
  padding: 0;
  white-space: pre-wrap; }

.Media-container.Media-hide-cues video::-webkit-media-text-track-container {
  display: none; }

/* End: Track (Captions and Chapters) */
/* Start: Error */
.Media-overlay-error {
  position: relative; }

.Media-overlay-error > img {
  left: 0;
  max-width: 100%;
  position: absolute;
  top: 0;
  z-index: -1; }

.Media-cannotplay,
.Media-cannotplay a {
  color: #fff;
  font-size: 0.8em; }

.Media-cannotplay {
  position: relative; }

.Media-cannotplay p,
.Media-cannotplay a {
  display: inline-block;
  padding: 0 15px;
  width: 100%; }

/* Grid */
/**
 * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
 * widths and offsets should be applied to child grid cells.
 */
.Grid {
  box-sizing: border-box;
  display: flex;
  /* 1 */
  flex-flow: row wrap;
  margin: 0;
  /* 1 */
  padding: 0;
  /* 1 */ }

/**
    * No explicit width by default. Rely on combining `Grid-cell` with a dimension
    * utility or a component class that extends 'Grid'.
    *
    * 1. Set flex items to full width by default
    * 2. Fix issue where elements with overflow extend past the
    *      `Grid-cell` container - https://git.io/vw5oF
    */
.Grid-cell {
  box-sizing: inherit;
  flex-basis: 100%;
  /* 1 */
  min-width: 0;
  /* 2 */ }

/**
    * Modifiers
    */
.Grid--alignCenter {
  justify-content: center; }

.Grid--alignRight {
  justify-content: flex-end; }

.Grid--alignMiddle {
  align-items: center; }

.Grid--alignBottom {
  align-items: flex-end; }

.Grid--fill > .Grid-cell {
  flex: 1 1 0%;
  /* 1 */ }

.Grid--fit > .Grid-cell {
  flex-basis: auto; }

.Grid--equalHeight > .Grid-cell {
  display: flex; }

.Grid--withExtraSmallGutter {
  margin-top: -0.3125rem;
  margin-left: -0.3125rem; }
  .Grid--withExtraSmallGutter > .Grid-cell {
    padding-top: 0.3125rem;
    padding-left: 0.3125rem; }

.Grid--withSmallGutter {
  margin-top: -0.9375rem;
  margin-left: -0.9375rem; }
  .Grid--withSmallGutter > .Grid-cell {
    padding-top: 0.9375rem;
    padding-left: 0.9375rem; }

.Grid--withGutter {
  margin-top: -1.875rem;
  margin-left: -1.875rem; }
  .Grid--withGutter > .Grid-cell {
    padding-top: 1.875rem;
    padding-left: 1.875rem; }

.Grid--withLargeGutter {
  margin-top: -2.8125rem;
  margin-left: -2.8125rem; }
  .Grid--withLargeGutter > .Grid-cell {
    padding-top: 2.8125rem;
    padding-left: 2.8125rem; }

.Grid--withExtraLargeGutter {
  margin-top: -3.75rem;
  margin-left: -3.75rem; }
  .Grid--withExtraLargeGutter > .Grid-cell {
    padding-top: 3.75rem;
    padding-left: 3.75rem; }

/* Icon */
.Icon {
  display: inline-flex;
  align-self: center;
  flex-shrink: 0; }
  .Icon svg {
    fill: currentColor;
    height: 1em;
    width: 1em; }
  .Icon.Icon--baseline svg {
    top: 0.125em;
    position: relative; }

/* Nav */
.Nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto; }

.Nav-item {
  margin: 0; }

.Nav-link {
  user-select: none;
  display: block; }
  .Nav-link, .Nav-link:active, .Nav-link:focus, .Nav-link:hover {
    text-decoration: none; }

.Nav--stacked .Nav-list {
  flex-direction: column;
  flex-wrap: nowrap; }

.Nav--fit:not(.Nav--stacked) .Nav-item {
  flex: 1 1 auto; }

.Nav--alignCenter {
  text-align: center; }
  .Nav--alignCenter:not(.Nav--stacked):not(.Nav--fit) .Nav-list {
    justify-content: center; }

.Nav--alignRight {
  text-align: right; }
  .Nav--alignRight:not(.Nav--stacked):not(.Nav--fit) .Nav-list {
    justify-content: flex-end; }

/* Structure */
.Container {
  margin-right: auto;
  margin-left: auto;
  max-width: 92.5rem;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  position: relative;
  width: 100%; }
  .Container--xl {
    max-width: 117.5rem; }
  .Container--lg {
    max-width: 106.25rem; }
  .Container--md {
    max-width: 77.5rem; }
  .Container--sm {
    max-width: 67.5rem; }
  .Container--xs {
    max-width: 57.5rem; }
  @media screen and (min-width: 48em) {
    .Container {
      max-width: 95rem;
      padding-right: 2.5rem;
      padding-left: 2.5rem; }
      .Container--xl {
        max-width: 120rem; }
      .Container--lg {
        max-width: 108.75rem; }
      .Container--md {
        max-width: 80rem; }
      .Container--sm {
        max-width: 70rem; }
      .Container--xs {
        max-width: 60rem; } }

body {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "header" "main" "footer"; }

.Header {
  position: relative;
  z-index: 3;
  grid-area: header; }
  @media screen and (min-width: 48em) {
    .Header {
      height: auto; } }
  .Header > .Container {
    height: 100%; }

.Footer {
  position: relative;
  z-index: 1;
  grid-area: footer; }

.Main {
  position: relative;
  z-index: 2;
  grid-area: main; }
  .Main > .Container {
    padding-top: 5rem;
    padding-bottom: 5rem; }
    @media screen and (min-width: 48em) {
      .Main > .Container {
        grid-gap: 2.5rem; }
        .Main > .Container.has-leftSide {
          display: grid;
          grid-template-columns: 33.33333% 1fr;
          grid-template-rows: auto;
          grid-template-areas: "left content"; }
        .Main > .Container.has-rightSide {
          display: grid;
          grid-template-columns: 1fr 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "content right"; }
        .Main > .Container.has-sides {
          display: grid;
          grid-template-columns: 33.33333% auto 33.33333%;
          grid-template-rows: auto;
          grid-template-areas: "left content right"; } }

.Side {
  position: relative; }
  @media screen and (max-width: 47.9375em) {
    .Side {
      margin-top: 1.25rem; } }
  @media screen and (min-width: 48em) {
    .Side--left {
      grid-area: left; }
    .Side--right {
      grid-area: right; } }

.Content {
  grid-area: content;
  position: relative; }

/* Table */
.Table {
  background-color: transparent;
  border: 0;
  border-spacing: 0;
  table-layout: fixed;
  text-align: left;
  width: 100%; }
  .Table th,
  .Table td {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem; }
    .Table th:not(:first-child),
    .Table td:not(:first-child) {
      padding-left: 0.625rem; }
    .Table th:not(:last-child),
    .Table td:not(:last-child) {
      padding-right: 0.625rem; }
  .Table td {
    vertical-align: middle; }
  .Table th {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    vertical-align: top; }
  .Table thead {
    background-color: transparent;
    border-bottom: 2px solid rgba(177, 177, 176, 0.4); }
  .Table tbody td {
    border-top: 1px solid rgba(177, 177, 176, 0.4); }
  @media screen and (min-width: 48em) {
    .Table tbody tr:last-child > td {
      border-bottom: 1px solid rgba(177, 177, 176, 0.4); } }
  .Table tfoot {
    background-color: transparent;
    border-top: 2px solid rgba(177, 177, 176, 0.4); }
  @media screen and (max-width: 47.9375em) {
    .Table.is-responsive {
      border-width: 0; }
      .Table.is-responsive th,
      .Table.is-responsive td {
        padding: 0; }
      .Table.is-responsive tr + tr {
        border-top: 1px solid rgba(177, 177, 176, 0.4);
        margin-top: 1.25rem;
        padding-top: 1.25rem; }
      .Table.is-responsive td + td {
        margin-top: 0.625rem; }
      .Table.is-responsive,
      .Table.is-responsive tbody,
      .Table.is-responsive tfoot,
      .Table.is-responsive tr {
        display: block; }
      .Table.is-responsive tbody tr {
        display: flex;
        flex-direction: column; }
      .Table.is-responsive tbody td {
        border-top: 0; }
        tr:last-child > .Table.is-responsive tbody td {
          border-bottom: 0; }
      .Table.is-responsive thead {
        display: none; }
      .Table.is-responsive tfoot {
        border: 1px solid rgba(177, 177, 176, 0.4);
        border-top-width: 2px; }
      .Table.is-responsive .Table-cell {
        display: flex;
        vertical-align: top;
        width: 100%; }
        .Table.is-responsive .Table-cell[data-label]::before {
          content: attr(data-label);
          font-size: 90%;
          font-weight: 500;
          display: block;
          flex-shrink: 0;
          text-align: left;
          width: 7.5rem; } }

.Table--striped tbody tr:nth-child(even) {
  background-color: #fafafa; }

@media screen and (max-width: 63.9375em) {
  .Table--striped.is-responsive tbody tr:nth-child(even) {
    background-color: transparent; } }

.Table--minimized td {
  padding: 0 !important;
  border: 0 !important; }

.Toggle {
  height: 1.125rem;
  width: 1.5rem;
  position: relative;
  cursor: pointer;
  transform: rotate(0deg); }
  .Toggle-item {
    height: 0.125rem;
    width: 100%;
    position: absolute;
    transition-property: top, transform, opacity;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
    background-color: currentColor;
    border-radius: 0px;
    display: block;
    transform: rotate(0deg);
    background-color: #313131; }
    .Toggle-item:nth-child(1) {
      top: 0; }
      .show-nav .Toggle-item:nth-child(1) {
        top: 0.5rem;
        transform: rotate(135deg); }
    .Toggle-item:nth-child(2) {
      top: 0.5rem; }
      .show-nav .Toggle-item:nth-child(2) {
        opacity: 0; }
    .Toggle-item:nth-child(3) {
      top: 1rem; }
      .show-nav .Toggle-item:nth-child(3) {
        top: 0.5rem;
        transform: rotate(-135deg); }

/* Alert */
.Alert--success {
  color: #659667;
  background-color: #eef6ef;
  border-color: #dfeddf; }
  .Alert--success a {
    color: #4c714d; }

.Alert--info {
  color: #5690bf;
  background-color: #ebf4fb;
  border-color: #d7e9f7; }
  .Alert--info a {
    color: #396d96; }

.Alert--warning {
  color: #bf8c40;
  background-color: #fcf4e9;
  border-color: #f9ead3; }
  .Alert--warning a {
    color: #8f6930; }

.Alert--danger {
  color: #c56c66;
  background-color: #fceeed;
  border-color: #f8dbd9; }
  .Alert--danger a {
    color: #a2453e; }

/* Button */
.Button.is-sticky {
  position: fixed;
  bottom: 3.75rem;
  right: calc((100vw - 1440px) / 2);
  z-index: 5; }
  @media screen and (max-width: 80em) {
    .Button.is-sticky {
      right: 2.5rem; } }

.Button:not(.Button--bare) {
  transition-property: background-color, box-shadow, transform;
  transition-duration: 200ms;
  transition-timing-function: linear;
  font-weight: 500;
  letter-spacing: 0.4px;
  line-height: 1.5;
  margin-bottom: 0.375rem;
  max-width: 100%;
  min-width: 14.625rem; }
  .Button:not(.Button--bare):active, .Button:not(.Button--bare).is-active {
    transform: translate(0.375rem, 0.375rem); }

.Button--filter {
  padding: 0.1875rem 0.875rem 0.125rem;
  border-radius: 15px;
  border: 1px solid rgba(177, 177, 176, 0.2);
  background: #f9f9f9;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  transition: all 0.5s ease;
  cursor: pointer;
  min-width: auto !important; }
  .Button--filter:hover, .Button--filter.is-active {
    color: #ffffff;
    background-color: #C4281A;
    border-color: #C4281A;
    transform: none !important; }

.Button--primary {
  box-shadow: 0.375rem 0.375rem rgba(196, 40, 26, 0.16);
  background-color: #C4281A;
  color: #ffffff; }
  .Button--primary:active, .Button--primary.is-active {
    box-shadow: 0 0 rgba(157, 29, 6, 0.16); }
  .Button--primary:active, .Button--primary:focus, .Button--primary:hover, .Button--primary.is-active, .Button--primary.is-focused, .Button--primary.is-hovered {
    background-color: #9d1d06;
    color: #ffffff; }

.Button--secondary {
  box-shadow: 0.375rem 0.375rem rgba(49, 49, 49, 0.16);
  background-color: #313131;
  color: #ffffff; }
  .Button--secondary:active, .Button--secondary.is-active {
    box-shadow: 0 0 rgba(49, 49, 49, 0.16); }
  .Button--secondary:active, .Button--secondary:focus, .Button--secondary:hover, .Button--secondary.is-active, .Button--secondary.is-focused, .Button--secondary.is-hovered {
    background-color: #000000;
    color: #ffffff; }

.Button--info {
  background-color: #2196f3;
  color: #ffffff; }
  .Button--info:not(.Button--outline):active, .Button--info:not(.Button--outline):focus, .Button--info:not(.Button--outline):hover, .Button--info:not(.Button--outline).is-active {
    background-color: #1976d2;
    color: #ffffff; }
  .Button--info.Button--outline {
    color: #2196f3; }
    .Button--info.Button--outline:active, .Button--info.Button--outline:focus, .Button--info.Button--outline:hover, .Button--info.Button--outline.is-active {
      color: #1976d2; }

.Button--success {
  background-color: #4caf50;
  color: #ffffff; }
  .Button--success:not(.Button--outline):active, .Button--success:not(.Button--outline):focus, .Button--success:not(.Button--outline):hover, .Button--success:not(.Button--outline).is-active {
    background-color: #388e3c;
    color: #ffffff; }
  .Button--success.Button--outline {
    color: #4caf50; }
    .Button--success.Button--outline:active, .Button--success.Button--outline:focus, .Button--success.Button--outline:hover, .Button--success.Button--outline.is-active {
      color: #388e3c; }

.Button--warning {
  background-color: #ff9800;
  color: #ffffff; }
  .Button--warning:not(.Button--outline):active, .Button--warning:not(.Button--outline):focus, .Button--warning:not(.Button--outline):hover, .Button--warning:not(.Button--outline).is-active {
    background-color: #f57c00;
    color: #ffffff; }
  .Button--warning.Button--outline {
    color: #ff9800; }
    .Button--warning.Button--outline:active, .Button--warning.Button--outline:focus, .Button--warning.Button--outline:hover, .Button--warning.Button--outline.is-active {
      color: #f57c00; }

.Button--danger {
  background-color: #f44336;
  color: #ffffff; }
  .Button--danger:not(.Button--outline):active, .Button--danger:not(.Button--outline):focus, .Button--danger:not(.Button--outline):hover, .Button--danger:not(.Button--outline).is-active {
    background-color: #d32f2f;
    color: #ffffff; }
  .Button--danger.Button--outline {
    color: #f44336; }
    .Button--danger.Button--outline:active, .Button--danger.Button--outline:focus, .Button--danger.Button--outline:hover, .Button--danger.Button--outline.is-active {
      color: #d32f2f; }

.Button--outline {
  background-color: transparent;
  border-color: currentColor; }

.Button--large {
  font-size: 1.25rem;
  padding-right: 3.75rem;
  padding-left: 3.75rem; }

.Button--small {
  font-size: 0.75rem;
  padding-right: 1.25rem;
  padding-left: 1.25rem; }

.Button--full {
  display: block;
  width: 100%; }

.Button--bare {
  border: 0;
  border-radius: 0;
  padding: 0;
  text-align: left;
  line-height: 1.4;
  font-weight: 600; }
  .Teaser .Button--bare,
  .Bar .Button--bare {
    font-weight: 800; }
  .Banner .Button--bare {
    font-weight: 750;
    font-size: 18px;
    line-height: 26px; }
    .Banner .Button--bare .Button-icon {
      color: #C4281A; }
    .Banner .Button--bare + .Button--bare {
      margin-top: 0.625rem; }
    .Banner .Button--bare:hover {
      color: #C4281A; }
  .CookieConsent .Button--bare:hover {
    text-decoration: underline; }
  .Button--bare .Button-icon {
    flex-shrink: 0;
    margin-left: 0.625rem; }

.Modal {
  transition-property: opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10; }
  .Modal, .Modal:before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
  .Modal::before {
    background-color: rgba(0, 0, 0, 0.5);
    content: '';
    z-index: -1; }
  .Modal:not(.is-active) {
    opacity: 0;
    visibility: hidden; }
  .Modal-inner {
    display: flex;
    align-items: center;
    min-height: 100%; }
  .Modal-innerBody {
    background-color: #ffffff;
    border-radius: 0;
    border: 1px solid #b1b1b0;
    border-color: #b1b1b0;
    margin: 1.25rem 1.25rem 1.25rem 1.25rem;
    width: auto; }
    @media screen and (min-width: 37.5em) {
      .Modal-innerBody {
        margin: 2.5rem auto;
        max-width: 31.25rem;
        width: 100%; } }
  .Modal-header {
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.625rem 0.625rem 0.625rem 0.625rem; }
  .Modal-title {
    font-size: 1.25rem;
    line-height: 1.5;
    margin-bottom: 0; }
  .Modal-close {
    font-size: 1.5rem;
    transition-property: opacity;
    transition-duration: visibility;
    transition-timing-function: linear;
    cursor: pointer;
    font-weight: 700;
    line-height: 1;
    padding: 0.625rem 0.625rem 0.625rem 0.625rem;
    margin: -0.625rem -0.625rem -0.625rem auto; }
    html:not(.is-tabbing) .Modal-close:focus {
      outline: 0; }
    .Modal-close:not(:hover) {
      opacity: .5; }
  .Modal-body {
    padding: 0.625em 0.625em 0.625em 0.625em; }
  .Modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0.625em 0.625em 0.625em 0.625em;
    border-top: 1px solid #e0e0e0; }

/* Outdated Browser */
.Outdated {
  background-color: #f44336;
  color: #ffffff;
  font-size: 12px;
  padding: 12px 24px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1500; }
  .Outdated-title {
    display: block;
    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 6px; }
  .Outdated-text {
    margin-bottom: 0;
    line-height: 1; }
  .Outdated-button {
    border: 2px solid #ffffff;
    color: #ffffff;
    cursor: pointer;
    display: block;
    font-weight: 700;
    line-height: 1;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    width: 230px;
    /*need for IE*/
    position: absolute;
    top: 12px;
    right: 24px; }
    .Outdated-button:hover {
      background-color: #ffffff;
      color: #f44336; }
  * html .Outdated {
    position: absolute; }

.cookie-though {
  /* The colors used to style the modal */
  --ct-primary-400: #9d1d06;
  --ct-primary-300: #C4281A;
  --ct-primary-200: #D9D9D9;
  --ct-primary-100: #f9f9f9;
  /* The colors used to style: customizationButton, headerDescription and cookiePolicy */
  --ct-text: #313131;
  /* The colors used to style the title elements */
  --ct-subtitle: var(--ct-primary-300);
  --ct-title: var(--ct-primary-400);
  /* The color used to style the cookie svg */
  --ct-cookie: var(--ct-primary-400);
  /* The colors used to style a policy */
  --ct-policy: var(--ct-white);
  --ct-policy-enabled: var(--ct-primary-400);
  --ct-policy-text: var(--ct-primary-400);
  --ct-policy-text-enabled: var(--ct-white);
  --ct-policy-essential: var(--ct-primary-100);
  /* The colors used to style the sliders */
  --ct-slider-primary: var(--ct-text);
  --ct-slider-secondary: #d1d5db;
  --ct-slider-enabled-primary: #9B0030;
  --ct-slider-enabled-secondary: #D01044;
  /* The font color of the text inside a policy when it's enabled  */
  --ct-enabled-option-color: var(--ct-white);
  /* The white color */
  --ct-white: #ffffff;
  /* The margin underneath text elements */
  --ct-text-margin: 0.25rem;
  /* The padding of the modal and margin of the banner, toggleButton and customization */
  --ct-default-padding: 1rem;
  /* The padding/margin used to seperate the options and the permission buttons */
  --ct-seperator-padding: 0.5rem;
  /* The font size of the header title */
  --ct-heading-font-size: 1.25rem;
  /* The font size of the header sub title */
  --ct-sub-heading-font-size: 0.875rem;
  /* The font size of text */
  --ct-text-font-size: 0.75rem;
  /* The font size of the customize button and permission button labels */
  --ct-button-font-size: 0.8125rem;
  /* The font used in the app */
  --ct-primary-font: inherit;
  /* The font used for header description and policy description */
  --ct-secondary-font: inherit;
  /* The z-index you want the root container to have */
  --ct-z-index: 9999; }

/* Header */
.Header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 4;
  background-color: #ffffff;
  color: #313131;
  line-height: 1.5; }
  @media screen and (max-width: 63.9375em) {
    .Header > .Nav--group {
      display: none; } }
  .Header-top {
    background-color: #C4281A;
    color: #ffffff;
    height: 2.5rem; }
    .Header-top > .Container {
      display: flex;
      justify-content: space-between; }
  .Header-group-dropdown {
    align-self: flex-start;
    text-decoration: none;
    letter-spacing: -0.49px;
    padding: 0.5rem 1.25rem;
    cursor: pointer;
    background-color: #ffffff;
    color: #C4281A; }
    @media screen and (max-width: 63.9375em) {
      .Header-group-dropdown {
        background-color: #f9f9f9;
        width: calc(100% + 40px);
        margin-left: -1.25rem;
        padding: 1.25rem 2.5rem;
        margin-top: 1.25rem; } }
    @media screen and (max-width: 48em) {
      .Header-group-dropdown {
        padding: 1.25rem 1.25rem; } }
    .Header-group-dropdown:active, .Header-group-dropdown:focus, .Header-group-dropdown:hover, .Header-group-dropdown.is-active {
      background-color: #f9f9f9; }
  .Header-link {
    align-self: flex-start;
    text-decoration: none;
    letter-spacing: -0.49px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
    .Header-link:hover {
      text-decoration: underline; }
    .Header-link, .Header-link:active, .Header-link:focus, .Header-link:hover {
      color: currentColor; }
  .Header-links {
    display: flex;
    gap: 1.25rem;
    flex-direction: row;
    z-index: 3; }
    @media screen and (max-width: 64em) {
      .Header-links {
        justify-content: center;
        text-align: center;
        width: 100%; } }
  .Header-bottom > .Container {
    transition-property: height;
    transition-duration: 200ms;
    transition-timing-function: linear;
    display: flex;
    align-items: center;
    height: 5rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    position: static; }
    @media screen and (min-width: 48em) {
      .Header-bottom > .Container {
        height: 8.75rem; } }
  .Header--sticky {
    transition-property: background-color, color, height, transform;
    transition-duration: 200ms;
    transition-timing-function: linear; }
    .Header--sticky.is-unpinned {
      transform: translateY(-100%); }
    .Header--sticky.is-notTop.is-pinned > .Header-bottom > .Container {
      height: 5rem; }
    .Header--sticky.is-notTop {
      box-shadow: 0 0 1px 1px rgba(177, 177, 176, 0.2); }

/* Navigation */
.Navigation-toggle {
  display: block; }

.Navigation-body {
  display: flex; }
  .Navigation-bodyInner {
    display: flex; }
    @media screen and (max-width: 63.9375em) {
      .Navigation-bodyInner {
        flex-direction: column;
        justify-content: space-between;
        height: 100%; } }
  .Navigation-body .Nav--group {
    display: none; }
    @media screen and (max-width: 63.9375em) {
      .Navigation-body .Nav--group {
        display: block; } }
  @media screen and (min-width: 64em) {
    .Navigation-body {
      margin-left: -1.25rem; }
      .Navigation-body > .Nav,
      .Navigation-body .Search {
        padding-left: 1.25rem; } }
  @media screen and (max-width: 63.9375em) {
    .Navigation-body {
      position: fixed;
      top: 11.25rem;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      transition-property: opacity, visibility;
      transition-duration: 200ms;
      transition-timing-function: linear;
      background-color: #ffffff;
      padding: 1.25rem;
      display: block;
      overflow-y: auto;
      overflow-x: hidden; }
      .Header.is-notTop.is-pinned .Navigation-body {
        top: 7.5rem; }
      html:not(.show-nav) .Navigation-body {
        opacity: 0;
        visibility: hidden; } }
  @media screen and (max-width: 47.9375em) {
    .Navigation-body {
      top: 7.5rem; } }

.Navigation .Caret {
  position: relative;
  height: 0.375rem;
  width: 0.75rem;
  margin-left: 0.5rem; }
  .Navigation .Caret:before, .Navigation .Caret:after {
    content: "";
    position: absolute; }
  .Navigation .Caret:before {
    top: 0;
    left: 0;
    border-top: 0.375rem solid currentColor;
    border-left: 0.375rem solid transparent;
    border-right: 0.375rem solid transparent; }
  .Navigation .Caret:after {
    left: 0.0625rem;
    top: 0;
    border-top: 0.3125rem solid #ffffff;
    border-left: 0.3125rem solid transparent;
    border-right: 0.3125rem solid transparent; }

/* Types */
/* Main */
@media screen and (max-width: 63.9375em) {
  .Nav--main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 1.25rem; } }

@media screen and (max-width: 47.9375em) {
  .Nav--main {
    padding-left: 0rem; } }

.Nav--main > .Nav-list {
  margin-left: -1.875rem; }
  @media screen and (max-width: 63.9375em) {
    .Nav--main > .Nav-list {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start; } }
  .Nav--main > .Nav-list > .Nav-item {
    padding-left: 1.875rem; }
    .Nav--main > .Nav-list > .Nav-item.has-children {
      display: flex;
      align-items: center; }
      .Nav--main > .Nav-list > .Nav-item.has-children.is-small {
        right: auto;
        left: auto; }
        .Nav--main > .Nav-list > .Nav-item.has-children.is-small [data-level="1"] > .Nav-item > .Nav-link {
          margin-bottom: 0rem; }
        .Nav--main > .Nav-list > .Nav-item.has-children.is-small [data-level="1"] .Nav-item + .Nav-item {
          margin-top: 0.3125rem; }
        .Nav--main > .Nav-list > .Nav-item.has-children.is-small .Nav--children {
          right: auto;
          left: auto;
          top: 130px;
          border-left: 1px solid rgba(177, 177, 176, 0.2);
          border-right: 1px solid rgba(177, 177, 176, 0.2);
          padding: 0.625rem 1.25rem; }
        .Nav--main > .Nav-list > .Nav-item.has-children.is-small::before {
          display: none; }
    @media screen and (max-width: 63.9375em) {
      .Nav--main > .Nav-list > .Nav-item + .Nav-item {
        margin-top: 1.25rem; }
      .show-nav .Nav--main > .Nav-list > .Nav-item {
        transition-property: opacity, transform;
        transition-duration: 300ms, 1s;
        transition-timing-function: linear, cubic-bezier(0.19, 1, 0.22, 1); }
        .show-nav .Nav--main > .Nav-list > .Nav-item:nth-child(1) {
          transition-delay: 0.02s; }
        .show-nav .Nav--main > .Nav-list > .Nav-item:nth-child(2) {
          transition-delay: 0.04s; }
        .show-nav .Nav--main > .Nav-list > .Nav-item:nth-child(3) {
          transition-delay: 0.06s; }
        .show-nav .Nav--main > .Nav-list > .Nav-item:nth-child(4) {
          transition-delay: 0.08s; }
        .show-nav .Nav--main > .Nav-list > .Nav-item:nth-child(5) {
          transition-delay: 0.1s; }
        .show-nav .Nav--main > .Nav-list > .Nav-item:nth-child(6) {
          transition-delay: 0.12s; }
        .show-nav .Nav--main > .Nav-list > .Nav-item:nth-child(7) {
          transition-delay: 0.14s; }
        .show-nav .Nav--main > .Nav-list > .Nav-item:nth-child(8) {
          transition-delay: 0.16s; }
        .show-nav .Nav--main > .Nav-list > .Nav-item:nth-child(9) {
          transition-delay: 0.18s; }
        .show-nav .Nav--main > .Nav-list > .Nav-item:nth-child(10) {
          transition-delay: 0.2s; }
      html:not(.show-nav) .Nav--main > .Nav-list > .Nav-item {
        opacity: 0;
        transform: translateY(1.25rem); } }

.Nav--main .Nav-link {
  color: currentColor; }
  .Nav--main .Nav-link.Delta {
    text-transform: uppercase; }
  .Nav--main .Nav-link:active, .Nav--main .Nav-link:focus, .Nav--main .Nav-link:hover, .Nav--main .Nav-link.is-active {
    color: #C4281A; }

.Nav--main .Nav--children {
  transition-property: opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  position: absolute;
  top: 11.25rem;
  right: 0;
  left: 0;
  background-color: #f9f9f9;
  border-top: 1px solid rgba(177, 177, 176, 0.2);
  border-bottom: 1px solid rgba(177, 177, 176, 0.2);
  color: #313131;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; }
  .Header.is-notTop.is-pinned .Nav--main .Nav--children {
    top: 7.5rem; }
  .Nav--main .Nav--children::before {
    height: 3.75rem;
    width: 100%;
    position: absolute;
    top: 0;
    content: '';
    transform: translateY(-100%); }
  .Nav--main .Nav--children > .Container > .Nav-list {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 1.875rem; }
  .Nav--main .Nav--children [data-level="1"] > .Nav-item > .Nav-link {
    margin-bottom: 1.25rem; }
  .Nav--main .Nav--children [data-level="2"] {
    color: rgba(49, 49, 49, 0.6);
    line-height: 1.4125; }
    .Nav--main .Nav--children [data-level="2"] .Nav-item + .Nav-item {
      margin-top: 0.5rem; }
    .Nav--main .Nav--children [data-level="2"] .Nav-item.is-active {
      color: #C4281A;
      display: flex;
      align-items: center; }
      .Nav--main .Nav--children [data-level="2"] .Nav-item.is-active .Nav-icon {
        display: block;
        flex-shrink: 0;
        margin-right: 0.5rem; }

.Nav--main .Nav-item:not(.is-hover) > .Nav--children {
  opacity: 0;
  visibility: hidden; }

/* Lang */
@media screen and (min-width: 64em) {
  .Nav--lang {
    border-left: 1px solid #b1b1b0;
    margin-left: 1.875rem;
    padding-left: 1.875rem; } }

@media screen and (max-width: 63.9375em) {
  .Nav--lang {
    order: 1; } }

@media screen and (min-width: 64em) {
  .Nav--lang {
    position: relative;
    z-index: 1; }
    .Nav--lang:not(:hover) > .Nav-list {
      opacity: 0;
      visibility: hidden; } }

@media screen and (max-width: 63.9375em) {
  .Nav--lang {
    margin-top: 1.25rem; } }

.Nav--lang .Nav-toggle {
  cursor: default;
  align-items: center;
  display: flex;
  text-transform: uppercase; }

.Nav--lang .Nav-list {
  gap: 0.3125rem; }
  @media screen and (min-width: 64em) {
    .Nav--lang .Nav-list {
      min-width: 16.25rem; } }
  .Nav--lang .Nav-list > .Nav-item + .Nav-item {
    margin-top: .25rem; }
    @media screen and (max-width: 63.9375em) {
      .Nav--lang .Nav-list > .Nav-item + .Nav-item {
        margin-top: 0rem; } }

@media screen and (min-width: 64em) {
  .Nav--lang > .Nav-list {
    transition-property: opacity, visibility;
    transition-duration: 200ms;
    transition-timing-function: linear;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #f9f9f9;
    border: 1px solid rgba(177, 177, 176, 0.2);
    color: rgba(49, 49, 49, 0.6);
    flex-direction: column;
    padding: 0.625rem 1.25rem;
    margin-top: 0.625rem;
    line-height: 1.4125; }
    .Nav--lang > .Nav-list::before {
      height: 0.625rem;
      width: 100%;
      position: absolute;
      right: 0;
      bottom: 100%;
      content: ''; } }

@media screen and (max-width: 63.9375em) {
  .Nav--lang > .Nav-list {
    justify-content: center;
    text-transform: uppercase; } }

.Nav--lang .Nav-link {
  color: currentColor;
  display: flex;
  gap: 0.625rem; }
  .Nav--lang .Nav-link .Flag {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 100%;
    overflow: hidden;
    object-fit: cover; }
  @media screen and (max-width: 63.9375em) {
    .Nav--lang .Nav-link {
      padding: 0.3125rem; }
      .Nav--lang .Nav-link::before {
        content: attr(lang); } }
  .Nav--lang .Nav-link.is-active {
    display: flex;
    align-items: center; }
    .Nav--lang .Nav-link.is-active .Nav-icon {
      display: block;
      flex-shrink: 0;
      margin-right: 0.5rem; }
  .Nav--lang .Nav-link:hover, .Nav--lang .Nav-link.is-active {
    color: #C4281A; }

.Nav--lang .Nav-flag {
  max-width: 1rem;
  margin-bottom: .375rem; }
  @media screen and (max-width: 63.9375em) {
    .Nav--lang .Nav-flag {
      display: none; } }

.Nav--breadcrumbs .Nav-list {
  align-items: center; }
  @media screen and (min-width: 48em) {
    .Nav--breadcrumbs .Nav-list {
      flex-wrap: nowrap; } }

.Nav--breadcrumbs .Nav-item:not(.has-truncate) {
  flex-shrink: 0; }

.Nav--breadcrumbs .Nav-item.has-truncate {
  flex-shrink: 1;
  min-width: 0; }

.Nav--breadcrumbs .Nav-item:not(:last-child) {
  margin-right: 0.75rem; }

.Nav--breadcrumbs .Nav-link {
  font-size: 0.6875rem;
  margin-bottom: 0; }
  .Nav--breadcrumbs .Nav-link, .Nav--breadcrumbs .Nav-link:active, .Nav--breadcrumbs .Nav-link:focus, .Nav--breadcrumbs .Nav-link:hover {
    color: #313131; }
  .Nav--breadcrumbs .Nav-link:not(.is-active):hover {
    color: #C4281A; }

.Nav--breadcrumbs .Nav-icon {
  display: block;
  margin-top: -0.125rem; }
  @media screen and (max-width: 47.9375em) {
    .Nav--breadcrumbs .Nav-icon {
      transform: scaleX(-1); } }

.Nav--socialMedia {
  color: rgba(177, 177, 176, 0.8); }
  .Nav--socialMedia .Nav-list {
    margin-left: -1.25rem; }
  .Nav--socialMedia .Nav-item {
    padding-left: 1.25rem; }
  .Nav--socialMedia .Nav-link {
    color: currentColor; }
    .Nav--socialMedia .Nav-link:active, .Nav--socialMedia .Nav-link:focus, .Nav--socialMedia .Nav-link:hover {
      color: #C4281A; }
  .Nav--socialMedia .Nav-icon {
    fill: currentColor;
    display: block; }

.Nav--contact {
  font-size: 125%;
  font-weight: 100; }
  .Nav--contact .Nav-item {
    display: flex;
    align-items: center; }
    .Nav--contact .Nav-item + .Nav-item {
      margin-top: 0.625rem; }
  .Nav--contact .Nav-object {
    color: #ffffff; }
  .Nav--contact .Nav-link {
    color: #313131;
    flex-grow: 1; }
    .Nav--contact .Nav-link:active, .Nav--contact .Nav-link:focus, .Nav--contact .Nav-link:hover {
      color: #C4281A; }
  .Nav--contact .Nav-object {
    height: 2.5rem;
    width: 2.5rem;
    background-color: #C4281A;
    border-radius: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 1.25rem; }

.Nav--footer {
  line-height: 1.88; }
  .Nav--footer .Nav-item {
    display: flex; }
    .Nav--footer .Nav-item::before {
      content: '•';
      flex-shrink: 0;
      margin-right: 1.25rem; }
  .Nav--footer .Nav-link {
    color: currentColor; }
    .Nav--footer .Nav-link:active, .Nav--footer .Nav-link:focus, .Nav--footer .Nav-link:hover, .Nav--footer .Nav-link.is-active {
      color: #C4281A; }

.Nav--policies {
  line-height: 1.33; }
  .Nav--policies .Nav-item {
    display: flex; }
    .Nav--policies .Nav-item:not(:first-child)::before {
      content: '|';
      margin-right: 0.3125rem;
      margin-left: 0.3125rem; }
  .Nav--policies .Nav-link {
    color: currentColor; }
    .Nav--policies .Nav-link:active, .Nav--policies .Nav-link:focus, .Nav--policies .Nav-link:hover {
      color: #C4281A; }

.Nav--group {
  height: 0;
  opacity: 0;
  overflow: hidden; }
  @media screen and (min-width: 64em) {
    .Nav--group {
      position: absolute;
      z-index: 2;
      background: white; } }
  @media screen and (max-width: 63.9375em) {
    .Nav--group {
      width: calc(100% + 40px);
      margin-left: -1.25rem;
      margin-top: 0.375rem; } }
  .Nav--group.is-active {
    height: auto;
    opacity: 1;
    transition: all 0.5s ease;
    overflow: auto; }
  .Nav--group .Nav-list {
    display: grid;
    gap: 0.375rem; }
    .Nav--group .Nav-list.has-3-items {
      grid-template-columns: repeat(3, 1fr); }
      @media screen and (max-width: 64em) {
        .Nav--group .Nav-list.has-3-items {
          grid-template-columns: repeat(1, 1fr); } }
    .Nav--group .Nav-list.has-4-items {
      grid-template-columns: repeat(4, 1fr); }
      @media screen and (max-width: 64em) {
        .Nav--group .Nav-list.has-4-items {
          grid-template-columns: repeat(1, 1fr); } }
  .Nav--group .Nav-item {
    background-color: #f9f9f9; }
  .Nav--group .Nav-link {
    padding: 2.5rem;
    color: #414042;
    height: 100%; }
    @media screen and (max-width: 63.9375em) {
      .Nav--group .Nav-link {
        padding: 1.25rem 2.5rem; } }
    @media screen and (max-width: 48em) {
      .Nav--group .Nav-link {
        padding: 1.25rem; } }
    .Nav--group .Nav-link:active, .Nav--group .Nav-link:focus, .Nav--group .Nav-link:hover {
      background-color: #C4281A;
      color: #ffffff; }
    .Nav--group .Nav-link .Title {
      font-weight: 700;
      font-size: 1.25rem;
      line-height: 1.75rem;
      margin-bottom: 0.5rem;
      color: inherit; }
    .Nav--group .Nav-link .Text {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.3125rem;
      letter-spacing: -0.492308px;
      margin-bottom: 0; }

/* Pagination */
.Nav--pagination {
  margin-top: 5rem; }
  .Nav--pagination .Nav-list {
    justify-content: center;
    margin-top: -0.5rem;
    margin-left: -0.5rem; }
  .Nav--pagination .Nav-item {
    margin-top: 0.5rem;
    margin-left: 0.5rem; }
    .Nav--pagination .Nav-item--prev .Nav-icon {
      transform: scaleX(-1); }
  .Nav--pagination .Nav-link {
    transition-property: background-color, border-color, color;
    transition-duration: 200ms;
    transition-timing-function: linear;
    height: 2.5rem;
    width: 2.5rem;
    border: 1px solid #b1b1b0;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: static;
    transform: none; }
    .Nav--pagination .Nav-link, .Nav--pagination .Nav-link:active, .Nav--pagination .Nav-link:focus, .Nav--pagination .Nav-link:hover {
      color: #313131; }
    .Nav--pagination .Nav-link.is-active, .Nav--pagination .Nav-link:not(.is-disabled):hover {
      background-color: #C4281A;
      border-color: #C4281A;
      color: #ffffff; }

/* Logo */
.Logo {
  flex-shrink: 0;
  margin-right: auto; }
  .Header .Logo {
    transition-property: width;
    transition-duration: 200ms;
    transition-timing-function: linear;
    overflow: hidden; }
    @media screen and (min-width: 48em) and (max-width: 68.6875em) {
      .Header .Logo {
        width: 11.34625rem; } }
    @media screen and (min-width: 68.75em) {
      .Header .Logo {
        width: 21.0625rem; } }
  @media screen and (min-width: 48em) {
    .Header.is-notTop .Logo {
      width: 11.34625rem; } }
  .Logo-object {
    height: 3.5625rem;
    width: 11.34625rem;
    display: block;
    max-width: 100%;
    width: auto; }

/* Fields */
/* Fields: field */
.Form-field--input,
.Form-field--textarea, .Form-field--select, .Form-field--select[multiple] {
  transition-property: background-color, border-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
  background-color: #ffffff;
  border-radius: 0;
  border: 1px solid rgba(177, 177, 176, 0.2);
  color: #424242;
  vertical-align: middle;
  width: 100%; }
  .Form-item.has-error .Form-field--input,
  .Form-item.has-error .Form-field--textarea, .Form-item.has-error .Form-field--select {
    border-color: #f44336; }
  .Form-item.is-success .Form-field--input,
  .Form-item.is-success .Form-field--textarea, .Form-item.is-success .Form-field--select {
    border-color: #4caf50; }

.Form-field--input:focus,
.Form-field--textarea:focus, .Form-field--select:focus {
  border-color: #757575;
  outline: 0;
  z-index: 1; }
  .Form-item.has-error .Form-field--input:focus,
  .Form-item.has-error .Form-field--textarea:focus, .Form-item.has-error .Form-field--select:focus { }
  .Form-item.is-success .Form-field--input:focus,
  .Form-item.is-success .Form-field--textarea:focus, .Form-item.is-success .Form-field--select:focus { }

.Form-field {
  font-family: inherit;
  position: relative; }

.Form-indicator {
  user-select: none; }

.Form-field--xs {
  max-width: 3.75rem !important; }

.Form-field--sm {
  max-width: 6.25rem !important; }

.Form-field--md {
  max-width: 12.5rem !important; }

.Form-field--lg {
  max-width: 18.75rem !important; }

.Form-field--xl {
  max-width: 25rem !important; }

.Form-field--full {
  max-width: none !important; }

/* Fields: check */
.Form-field--radio,
.Form-field--checkbox {
  cursor: pointer;
  display: flex;
  user-select: none; }
  .Form-field--radio:not(:first-of-type),
  .Form-field--checkbox:not(:first-of-type) {
    margin-top: 0.15625rem; }
  .Form-field--radio input[type="radio"],
  .Form-field--radio input[type="checkbox"],
  .Form-field--checkbox input[type="radio"],
  .Form-field--checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; }
    .Form-field--radio input[type="radio"]:checked ~ .Form-indicator,
    .Form-field--radio input[type="checkbox"]:checked ~ .Form-indicator,
    .Form-field--checkbox input[type="radio"]:checked ~ .Form-indicator,
    .Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
      background-color: #757575;
      border-color: #757575; }
    .Form-field--radio input[type="radio"]:focus ~ .Form-indicator,
    .Form-field--radio input[type="checkbox"]:focus ~ .Form-indicator,
    .Form-field--checkbox input[type="radio"]:focus ~ .Form-indicator,
    .Form-field--checkbox input[type="checkbox"]:focus ~ .Form-indicator {
      border-color: #757575; }
  .Form-field--radio .Form-indicator,
  .Form-field--checkbox .Form-indicator {
    background-color: #ffffff;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 0.5625em 0.5625em;
    border: 1px solid rgba(177, 177, 176, 0.2);
    flex-shrink: 0;
    height: 1em;
    margin-right: 0.625rem;
    width: 1em;
    position: relative;
    top: 0.375em; }

.Form-field--radio > .Form-indicator {
  border-radius: 100%; }

.Form-field--radio input[type="radio"]:checked ~ .Form-indicator::after {
  background-color: #ffffff;
  border-radius: 100%;
  content: '';
  height: 0.3em;
  width: 0.3em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.Form-field--checkbox > .Form-indicator {
  border-radius: 0; }

.Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.8 6.3'%3E%3Ctitle%3ECheck%3C/title%3E%3Cpath d='M6.4,0,5.7.7,2.9,3.5l-.8-.8L1.4,2,0,3.4l.7.7L2.2,5.6l.7.7.7-.7L7.1,2.1l.7-.7Z' fill='%23fff'/%3E%3C/svg%3E"); }

/* Fields: file */
.Form-field--file .Form-field--label {
  display: none; }

.Form-field--file .Form-field-input {
  cursor: pointer;
  flex-grow: 1; }
  .Form-field--file .Form-field-input::file-selector-button {
    transition-property: background-color;
    transition-duration: 200ms;
    transition-timing-function: linear;
    appearance: none;
    background-color: #C4281A;
    border: none;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.4px;
    padding: .875rem 1.75rem;
    text-align: center;
    cursor: pointer;
    margin-right: 1.25rem; }
    .Form-field--file .Form-field-input::file-selector-button:hover {
      background-color: #9d1d06;
      color: #ffffff; }

/* Fields: input */
.Form-field--input::placeholder,
.Form-field--textarea::placeholder {
  color: rgba(65, 64, 66, 0.3); }

.Form-field--input {
  padding: 0.75em 1.25em 0.75em 1.25em; }

.Form-field--textarea {
  padding: 0.625em 1.25em 0.625em 1.25em; }

/* Fields: select */
select {
  cursor: pointer;
  width: 100% !important; }

.Form-field--select {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20292.36%20402%22%3E%3Cpath%20d%3D%22M18.27%2C164.45H274.09a18.17%2C18.17%2C0%2C0%2C0%2C12.85-31.12L159%2C5.42a17.93%2C17.93%2C0%2C0%2C0-25.7%2C0L5.42%2C133.33a17.93%2C17.93%2C0%2C0%2C0%2C0%2C25.7A17.57%2C17.57%2C0%2C0%2C0%2C18.27%2C164.45Z%22%2F%3E%3Cpath%20d%3D%22M274.09%2C237.55H18.27A17.57%2C17.57%2C0%2C0%2C0%2C5.42%2C243a17.93%2C17.93%2C0%2C0%2C0%2C0%2C25.7l127.91%2C127.9a17.92%2C17.92%2C0%2C0%2C0%2C25.7%2C0L286.93%2C268.66a18.27%2C18.27%2C0%2C0%2C0-12.85-31.12Z%22%2F%3E%3C%2Fsvg%3E");
  background-position: right 0.625rem center;
  background-repeat: no-repeat;
  background-size: 0.625rem;
  line-height: normal;
  overflow: hidden;
  padding: 0.75em 2.5em 0.75em 1.25em; }

.Form-field--select[multiple] {
  height: auto; }
  .Form-field--select[multiple] option {
    padding: 0.375em 1.25em 0.375em 1.25em; }
    .Form-field--select[multiple] option + option {
      border-top: 1px solid rgba(177, 177, 176, 0.2); }

/* Slider */
/* Slider: core */
.Slider {
  position: relative;
  z-index: 1;
  /* Fix of Webkit flickering */
  overflow: hidden; }
  .Slider--no-flexbox .Slider-item {
    float: left; }
  .Slider--vertical > .Slider-items {
    flex-direction: column; }
  .Slider-items {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    display: flex;
    transition-property: transform; }
  .Slider--android .Slider-item, .Slider-items {
    transform: translate3d(0px, 0, 0); }
  .Slider--multirow > .Slider-items {
    flex-wrap: wrap; }
  .Slider--free-mode > .Slider-items {
    transition-timing-function: ease-out;
    margin: 0 auto; }
  .Slider-item {
    height: 100%;
    width: 100%;
    flex-shrink: 0;
    position: relative;
    transition-property: transform; }
    .Slider-item.is-blank {
      visibility: hidden; }
  .Slider--autoheight,
  .Slider--autoheight .Slider-item {
    height: auto; }
  .Slider--autoheight .Slider-items {
    align-items: flex-start;
    transition-property: transform, height; }
  .Slider--3d {
    perspective: 1200px; }
    .Slider--3d .Slider-items,
    .Slider--3d .Slider-item,
    .Slider--3d .Slider-item-shadow-left,
    .Slider--3d .Slider-item-shadow-right,
    .Slider--3d .Slider-item-shadow-top,
    .Slider--3d .Slider-item-shadow-bottom,
    .Slider--3d .swiper-cube-shadow {
      transform-style: preserve-3d; }
    .Slider--3d .Slider-item-shadow-left,
    .Slider--3d .Slider-item-shadow-right,
    .Slider--3d .Slider-item-shadow-top,
    .Slider--3d .Slider-item-shadow-bottom {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      z-index: 10; }
    .Slider--3d .Slider-item-shadow-left {
      background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-right {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-top {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .Slider--3d .Slider-item-shadow-bottom {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
  .Slider--wp8-horizontal,
  .Slider--wp8-horizontal > .Slider-items {
    touch-action: pan-y; }
  .Slider--wp8-vertical,
  .Slider--wp8-vertical > .Slider-items {
    touch-action: pan-x; }

/* Slider: a11y */
.Slider .Slider-notification {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1000;
  pointer-events: none;
  opacity: 0; }

/* Slider: fade */
.Slider--fade.Slider--free-mode .Slider-item {
  transition-timing-function: ease-out; }

.Slider--fade .Slider-item {
  pointer-events: none;
  transition-property: opacity; }
  .Slider--fade .Slider-item .Slider-item {
    pointer-events: none; }

.Slider--fade .is-active,
.Slider--fade .is-active .is-active {
  pointer-events: auto; }

/* Slider: coverflow */
.Slider--coverflow .Slider-items {
  -ms-perspective: 1200px;
  /* Windows 8 IE 10 fix */ }

/* Slider: cube */
.Slider--cube {
  overflow: visible; }
  .Slider--cube .Slider-item {
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    pointer-events: none;
    transform-origin: 0 0;
    visibility: hidden;
    z-index: 1; }
    .Slider--cube .Slider-item .Slider-item {
      pointer-events: none; }
  .Slider--cube.Slider--rtl .Slider-item {
    transform-origin: 100% 0; }
  .Slider--cube .is-active,
  .Slider--cube .is-active .is-active {
    pointer-events: auto; }
  .Slider--cube .is-active,
  .Slider--cube .is-next,
  .Slider--cube .is-prev,
  .Slider--cube .is-next + .Slider-item {
    pointer-events: auto;
    visibility: visible; }
  .Slider--cube .swiper-slide-shadow-top,
  .Slider--cube .swiper-slide-shadow-bottom,
  .Slider--cube .swiper-slide-shadow-left,
  .Slider--cube .swiper-slide-shadow-right {
    backface-visibility: hidden;
    z-index: 0; }
  .Slider--cube .swiper-cube-shadow {
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    background: #000000;
    filter: blur(50px);
    opacity: 0.6; }

/* Slider: flip */
.Slider--flip {
  overflow: visible; }
  .Slider--flip .Slider-item {
    backface-visibility: hidden;
    pointer-events: none;
    z-index: 1; }
    .Slider--flip .Slider-item .Slider-item {
      pointer-events: none; }
  .Slider--flip .is-active,
  .Slider--flip .is-active .is-active {
    pointer-events: auto; }
  .Slider--flip .Slider-item--shadow-top,
  .Slider--flip .Slider-item--shadow-bottom,
  .Slider--flip .Slider-item--shadow-left,
  .Slider--flip .Slider-item--shadow-right {
    backface-visibility: hidden;
    z-index: 0; }

/* Slider: lazy */
.Slider-preloader {
  height: 2.625rem;
  width: 2.625rem;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
  margin-top: -1.3125rem;
  margin-left: -1.3125rem; }
  .Slider-preloader:after {
    height: 100%;
    width: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    content: '';
    display: block; }

.Slider-preloader--white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

/* Slider: navigation */
.Slider-button {
  transition-property: opacity;
  transition-duration: 200ms;
  transition-timing-function: linear;
  height: 2.5625rem;
  width: 2.5625rem;
  position: absolute;
  top: 50%;
  z-index: 10;
  cursor: pointer;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%) translateY(-1.53125rem);
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.5)); }
  .Slider-button.is-disabled {
    cursor: auto;
    opacity: 0.35; }
  .Slider-button.is-locked {
    display: none; }

.Slider-button--prev,
.Slider--rtl .Slider-button--next {
  left: 0;
  transform: scaleX(-1) translateY(-50%) translateY(-1.53125rem); }

.Slider-button--next,
.Slider--rtl .Slider-button--prev {
  right: 0; }

/* Slider: pagination */
.Slider-pagination {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;
  position: absolute;
  z-index: 10;
  text-align: center;
  transform: translate3d(0, 0, 0); }
  .Slider-pagination.is-hidden {
    opacity: 0; }
  .Slider-pagination.is-locked {
    display: none; }

.Slider-pagination--fraction,
.Slider-pagination--custom,
.Slider--horizontal > .Slider-pagination--bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

.Slider-pagination--bullets-dynamic {
  overflow: hidden;
  font-size: 0; }
  .Slider-pagination--bullets-dynamic .Slider-pagination-item {
    transform: scale(0.33);
    position: relative; }
  .Slider-pagination--bullets-dynamic .is-active {
    transform: scale(1); }
  .Slider-pagination--bullets-dynamic .is-active-main {
    transform: scale(1); }
  .Slider-pagination--bullets-dynamic .is-active-prev {
    transform: scale(0.66); }
  .Slider-pagination--bullets-dynamic .is-active-prev-prev {
    transform: scale(0.33); }
  .Slider-pagination--bullets-dynamic .is-active-next {
    transform: scale(0.66); }
  .Slider-pagination--bullets-dynamic .is-active-next-next {
    transform: scale(0.33); }

.Slider-pagination-item {
  height: 0.5rem;
  width: 0.5rem;
  background: #000000;
  border-radius: 100%;
  display: inline-block;
  opacity: 0.2; }
  button.Slider-pagination-item {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    appearance: none; }
  .is-clickable .Slider-pagination-item {
    cursor: pointer; }
  .Slider-pagination-item.is-active {
    background-color: currentColor;
    opacity: 1; }

.Slider--vertical > .Slider-pagination--bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0); }
  .Slider--vertical > .Slider-pagination--bullets .Slider-pagination-item {
    display: block;
    margin-right: 0.375rem;
    margin-left: 0.375rem; }
  .Slider--vertical > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px; }
    .Slider--vertical > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic .Slider-pagination-item {
      display: inline-block;
      transition-property: transform, top;
      transition-duration: 200ms; }

.Slider--horizontal > .Slider-pagination--bullets .Slider-pagination-item {
  margin: 0 4px; }

.Slider--horizontal > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap; }
  .Slider--horizontal > .Slider-pagination--bullets.Slider-pagination--bullets-dynamic .Slider-pagination-item {
    transition-property: transform, left;
    transition-duration: 200ms; }

.Slider--horizontal.Slider--rtl > .Slider-pagination--bullets-dynamic .Slider-pagination-item {
  transition-property: transform, right;
  transition-duration: 200ms; }

.Slider-pagination--progressbar {
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute; }
  .Slider-pagination--progressbar .Slider-pagination-fill {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: currentColor;
    transform: scale(0);
    transform-origin: left top; }
  .Slider--rtl .Slider-pagination--progressbar .Slider-pagination-fill {
    transform-origin: right top; }
  .Slider--horizontal > .Slider-pagination--progressbar,
  .Slider--vertical > .Slider-pagination--progressbar.Slider-pagination--progressbar-opposite {
    height: 0.25rem;
    width: 100%;
    left: 0;
    top: 0; }
  .Slider--vertical > .Slider-pagination--progressbar,
  .Slider--horizontal > .Slider-pagination--progressbar.Slider-pagination--progressbar-opposite {
    height: 100%;
    width: 0.25rem;
    left: 0;
    top: 0; }

/* Slider: scrollbar */
.Slider-scrollbar {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.625rem;
  position: relative;
  -ms-touch-action: none; }
  .Slider--horizontal > .Slider-scrollbar {
    height: 0.3125rem;
    width: 98%;
    position: absolute;
    bottom: 3px;
    left: 1%;
    z-index: 50; }
  .Slider--vertical > .Slider-scrollbar {
    height: 98%;
    width: 0.3125rem;
    position: absolute;
    top: 1%;
    right: 3px;
    z-index: 50; }
  .Slider-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.625rem; }
  .Slider-scrollbar-cursor-drag {
    cursor: move; }
  .Slider-scrollbar .is-locked {
    display: none; }

/* Slider: thumbs */
/* Slider: zoom */
.Slider--zoom {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .Slider--zoom > img,
  .Slider--zoom > svg,
  .Slider--zoom > canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; }
  .Slider--zoom .is-zoomed {
    cursor: move; }

/* General */
.LazyLoad,
.LazyLoading {
  opacity: 0; }

.LazyLoaded {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: linear;
  opacity: 1; }

body:not(.user-is-tabbing) span:focus,
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none; }

.Main {
  padding-top: 7.5rem;
  overflow: hidden; }
  @media screen and (min-width: 48em) {
    .Main {
      padding-top: 11.25rem; } }
  .Main div:not(:last-of-type).Block--boxes {
    overflow: hidden;
    padding-bottom: 7.5rem; }

@media screen and (max-width: 65.25em) {
  .site-chemicals .Header .Logo {
    width: 6.25rem; } }

@media screen and (min-width: 65.25em) and (max-width: 68.6875em) {
  .site-chemicals .Header .Logo {
    width: 9.375rem; } }

@media screen and (min-width: 65.25em) {
  .site-chemicals .Header.is-notTop .Logo {
    width: 9.375rem; } }

.site-chemicals .Shape {
  width: calc(200px / 5);
  height: calc(105px / 5); }
  .site-chemicals .Shape::before {
    background-image: url("/assets/img/icons/chemicals.svg");
    width: calc(200px / 5);
    height: calc(105px / 5); }

@media screen and (max-width: 65.25em) {
  .site-industry .Header .Logo {
    width: 6.25rem; } }

@media screen and (min-width: 65.25em) and (max-width: 68.6875em) {
  .site-industry .Header .Logo {
    width: 9.375rem; } }

@media screen and (min-width: 65.25em) {
  .site-industry .Header.is-notTop .Logo {
    width: 9.375rem; } }

.site-industry .Shape {
  width: calc(200px / 6);
  height: calc(140px / 6); }
  .site-industry .Shape::before {
    background-image: url("/assets/img/icons/industry.svg");
    width: calc(200px / 6);
    height: calc(140px / 6); }

@media screen and (max-width: 65.25em) {
  .site-maritime .Header .Logo {
    width: 6.25rem; } }

@media screen and (min-width: 65.25em) and (max-width: 68.6875em) {
  .site-maritime .Header .Logo {
    width: 9.375rem; } }

@media screen and (min-width: 65.25em) {
  .site-maritime .Header.is-notTop .Logo {
    width: 9.375rem; } }

.site-maritime .Shape {
  width: calc(200px / 8);
  height: calc(106px / 8); }
  .site-maritime .Shape::before {
    background-image: url("/assets/img/icons/maritime.svg");
    width: calc(200px / 8);
    height: calc(106px / 8); }

.site-group .Shape {
  width: calc(200px / 4);
  height: calc(110px / 4); }
  .site-group .Shape::before {
    background-image: url("/assets/img/icons/group.svg");
    width: calc(200px / 4);
    height: calc(110px / 4); }

.Shape {
  height: 3.125rem;
  width: 3.125rem;
  display: block;
  flex-shrink: 0; }
  .Shape::before {
    content: "";
    display: block;
    background-repeat: no-repeat; }

.has-shape {
  position: relative; }
  .has-shape .Shape {
    position: absolute;
    right: calc(100% + 20px);
    top: 50%;
    transform: translateY(-50%); }

.Link {
  cursor: pointer;
  text-decoration: underline; }
  .Link:hover {
    text-decoration: none; }
  .Link, .Link:active, .Link:focus, .Link:hover {
    color: currentColor; }
  .Link--secondary {
    display: inline-flex;
    align-items: center; }
    .Link--secondary .Link-icon {
      margin-right: 0.625rem; }
      .Link--secondary .Link-icon--bottom {
        transform: rotate(90deg);
        display: block;
        margin-top: 0.625rem;
        margin-right: auto;
        margin-left: auto; }
    .Link--secondary, .Link--secondary:active, .Link--secondary:focus, .Link--secondary:hover {
      color: #C4281A; }
  .Link--tertiary {
    display: inline-flex;
    align-items: center;
    text-decoration: none; }
    .Link--tertiary .Link-icon {
      margin-right: 0.375rem; }
    .Link--tertiary:hover {
      text-decoration: underline; }
    .Link--tertiary, .Link--tertiary:active, .Link--tertiary:focus, .Link--tertiary:hover {
      color: #b1b1b0; }

.Definition {
  color: #767676;
  display: block;
  font-size: 75%;
  font-style: normal;
  font-weight: 400;
  line-height: 1.33;
  margin-top: 0.3125rem; }

ul:not([class]) {
  list-style-type: none;
  padding-left: 0; }
  ul:not([class]) > li {
    margin-bottom: 0;
    padding-left: 1.5625rem;
    position: relative; }
    ul:not([class]) > li::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0.3125rem;
      height: 0.9375rem;
      width: 0.9375rem;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'15'%20height%3D'15'%20viewBox%3D'0%200%2015%2015'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M5.74997%209.93241L3.31747%207.49991L2.48914%208.32241L5.74997%2011.5832L12.75%204.58324L11.9275%203.76074L5.74997%209.93241Z'%20fill%3D'%23C4281A'%2F%3E%3C%2Fsvg%3E"); }

.Play {
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  text-shadow: 0 0 0.5rem #000000;
  letter-spacing: 1.5px;
  line-height: 2.14; }
  .Play-icon {
    display: block;
    margin-right: auto;
    margin-bottom: 0.5rem;
    margin-left: auto; }

.Comment {
  white-space: nowrap; }
  .Comment .Icon {
    transition-property: color;
    transition-duration: 200ms;
    transition-timing-function: linear;
    color: rgba(49, 49, 49, 0.8);
    cursor: pointer; }
    .Comment .Icon:hover {
      color: #313131; }

.Price {
  color: #9d1d06;
  font-weight: 750;
  font-size: 1.25rem;
  line-height: 1.75rem; }
  .Price-tax {
    color: #767676;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem; }

.Block--intro + .Block--cart.Block {
  margin-top: 1.875rem; }

.Block--cart .Table {
  table-layout: auto; }
  @media screen and (min-width: 48em) {
    .Block--cart .Table {
      margin-top: -0.9375rem; } }
  @media screen and (max-width: 47.9375em) {
    .Block--cart .Table-cell--remove {
      margin-top: 0.625rem;
      order: 2; } }
  .Block--cart .Table .Form-field[type="number"] {
    max-width: 5rem; }

.Block--cart .Remove {
  transition-property: color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  color: #b1b1b0;
  cursor: pointer;
  display: flex;
  align-items: center; }
  @media screen and (max-width: 47.9375em) {
    .Block--cart .Remove {
      font-size: 90%; } }
  .Block--cart .Remove-icon {
    display: block; }
    @media screen and (max-width: 47.9375em) {
      .Block--cart .Remove-icon {
        margin-right: 0.625rem; } }
  .Block--cart .Remove:hover {
    color: #C4281A; }

@media screen and (min-width: 48em) {
  .Block--cart .Cart-items {
    padding-bottom: 9.6875rem; } }

.Cart {
  transition-property: height, padding;
  transition-duration: 200ms;
  transition-timing-function: linear;
  background-color: #9d1d06;
  display: flex;
  align-items: center;
  padding: 0.9375rem;
  text-transform: lowercase;
  text-decoration: none; }
  @media screen and (min-width: 48em) {
    .Cart {
      padding: 0.3125rem 1.5rem;
      height: 3.75rem; }
      .Cart:hover {
        height: 5rem; } }
  .Cart, .Cart:active, .Cart:focus, .Cart:hover {
    color: #ffffff; }
  .Cart-object {
    transition-property: width;
    transition-duration: 200ms;
    transition-timing-function: linear;
    flex-shrink: 0;
    width: 1.4375rem; }
    @media screen and (min-width: 48em) {
      .Cart-object {
        margin-right: 1.25rem; } }
    .Cart-object[data-total] {
      position: relative;
      z-index: 1; }
      .Cart-object[data-total]::after {
        transition-property: top, right, transform, opacity;
        transition-duration: 200ms;
        transition-timing-function: linear;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        height: 1rem;
        width: 1rem;
        background-color: #ffffff;
        border-radius: 100%;
        border: 1px solid #9d1d06;
        content: attr(data-total);
        color: #9d1d06;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 70%;
        font-weight: 500;
        transform: translate(65%, -25%); }
  .Cart-icon {
    transition-property: transform, opacity;
    transition-duration: 200ms;
    transition-timing-function: linear; }
  .Header.is-notTop.is-pinned .Cart {
    height: 2.5rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem; }
    .Header.is-notTop.is-pinned .Cart-object {
      width: 0; }
      @media screen and (min-width: 48em) {
        .Header.is-notTop.is-pinned .Cart-object {
          width: 0.5625rem; } }
      .Header.is-notTop.is-pinned .Cart-object::after {
        top: 50%;
        transform: translate(50%, -50%); }
    .Header.is-notTop.is-pinned .Cart-icon {
      transform: translateY(-100%);
      opacity: 0; }

.Certificats {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1.25rem;
  margin-left: -1.25rem;
  opacity: 0.4; }
  @media screen and (min-width: 48em) {
    .Certificats {
      justify-content: space-between; } }
  .Certificats > .Certificat {
    padding-top: 1.25rem;
    padding-left: 1.25rem; }

.Block--form .Intro-body {
  text-align: center;
  margin-right: auto;
  margin-left: auto; }

.Block--form.Block--boxes .Boxes {
  padding-bottom: 0; }

.Form:not(.Form--search) {
  background-color: #ffffff;
  border: 1px solid rgba(177, 177, 176, 0.2);
  max-width: 46.25rem;
  margin-right: auto;
  margin-left: auto;
  padding: 1.875rem; }
  @media screen and (min-width: 48em) {
    .Form:not(.Form--search) {
      padding: 2.5rem 3.75rem; } }

.Form .Grid--withGutter {
  margin-left: -1.25rem; }
  .Form .Grid--withGutter > .Grid-cell {
    padding-left: 1.25rem; }

.Block--cart .Form {
  margin-top: 2.5rem; }
  @media screen and (min-width: 48em) {
    .Block--cart .Form {
      margin-top: -6.25rem; } }
  .Block--cart .Form-item {
    margin-bottom: 0; }
  .Block--cart .Form-item--action {
    margin-top: 1.875rem;
    text-align: center; }

.Tooltip {
  transition-property: opacity, visibility;
  transition-duration: 200ms;
  transition-timing-function: linear;
  position: fixed;
  z-index: 5; }
  @media screen and (min-width: 64em) {
    .Tooltip {
      position: absolute;
      transform: translateY(-0.9375rem) translateY(-0.375rem);
      max-width: 40rem;
      width: 50vw; } }
  .Tooltip.Block-item {
    padding: 1.25rem; }
    @media screen and (min-width: 48em) {
      .Tooltip.Block-item {
        padding: 1.875rem 2.5rem; } }
  @media screen and (min-width: 64em) {
    .Tooltip--right {
      margin-right: 0.9375rem; }
      .Tooltip--right .Caret {
        position: relative;
        height: 0.9375rem;
        width: 0.46875rem;
        position: absolute;
        top: 1.25rem;
        left: 100%;
        z-index: -1; }
        .Tooltip--right .Caret:before, .Tooltip--right .Caret:after {
          content: "";
          position: absolute; }
        .Tooltip--right .Caret:before {
          top: 0;
          left: 0;
          border-left: 0.46875rem solid rgba(177, 177, 176, 0.2);
          border-top: 0.46875rem solid transparent;
          border-bottom: 0.46875rem solid transparent; }
        .Tooltip--right .Caret:after {
          left: 0;
          top: 0.0625rem;
          border-left: 0.40625rem solid #f9f9f9;
          border-top: 0.40625rem solid transparent;
          border-bottom: 0.40625rem solid transparent; }
    .Tooltip--left {
      margin-left: 0.9375rem; }
      .Tooltip--left .Caret {
        position: relative;
        height: 0.75rem;
        width: 0.9375rem;
        position: absolute;
        top: 1.25rem;
        right: 100%;
        z-index: -1; }
        .Tooltip--left .Caret:before, .Tooltip--left .Caret:after {
          content: "";
          position: absolute; }
        .Tooltip--left .Caret:before {
          top: 0;
          left: 0;
          border-right: 0.9375rem solid rgba(177, 177, 176, 0.2);
          border-top: 0.75rem solid transparent;
          border-bottom: 0.75rem solid transparent; }
        .Tooltip--left .Caret:after {
          left: 0.0625rem;
          top: 0.0625rem;
          border-right: 0.875rem solid #f9f9f9;
          border-top: 0.6875rem solid transparent;
          border-bottom: 0.6875rem solid transparent; } }
  @media screen and (max-width: 47.9375em) {
    .Tooltip-body {
      font-size: 75%; } }
  .Tooltip-body > :last-child {
    margin-bottom: 0; }
  .Tooltip:not(.is-active) {
    opacity: 0;
    visibility: hidden; }

.Client.Block-item {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }

.Client-image {
  margin-right: auto;
  margin-bottom: 1.25rem;
  margin-left: auto; }

.Client-object {
  margin-bottom: 1.25rem; }

.Client-body {
  text-align: center; }
  .Client-body > * {
    display: inline; }
  .Client-body :last-child {
    margin-bottom: 0; }

.Client-subtitle {
  text-transform: none; }
  .Client-subtitle::after {
    content: ':'; }

.Flow {
  max-width: 46.25rem; }
  .Flow-item + .Flow-item {
    margin-top: 2.5rem; }

.Project-header .Nav--pagination .Nav-list {
  justify-content: flex-start; }

@media screen and (min-width: 48em) {
  .Project-header {
    display: flex; }
    .Project-header .Intro-title {
      flex-grow: 1; }
    .Project-header .Nav--pagination {
      margin-left: 0.625rem;
      align-self: baseline;
      flex-shrink: 0; } }

@media screen and (min-width: 48em) {
  .ProjectsPage .Block + .Block--gallery.Block, .ProjectsPage
  .Block--gallery.Block + .Block {
    margin-top: 5rem; } }

@media screen and (min-width: 48em) {
  .Block--content + .Block--gallery.Block,
  .Block--gallery.Block + .Block--content {
    margin-top: 3.75rem; } }

.Gallery {
  margin-top: -0.9375rem;
  margin-left: -0.9375rem; }
  @media screen and (min-width: 37.5em) {
    .Gallery--3 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto; }
      .Gallery--3 .Gallery-item:nth-child(1) {
        grid-column: 1/2;
        grid-row: 1/2; }
      .Gallery--3 .Gallery-item:nth-child(2) {
        grid-column: 2/3;
        grid-row: 1/2; }
      .Gallery--3 .Gallery-item:nth-child(3) {
        grid-column: 3/4;
        grid-row: 1/2; }
      .Gallery--3 .Gallery-object {
        padding-bottom: 106.66667%; }
    .Gallery--4 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: auto auto; }
      .Gallery--4 .Gallery-item:nth-child(1) {
        grid-column: 1/3;
        grid-row: 1/2; }
        .Gallery--4 .Gallery-item:nth-child(1) .Gallery-object {
          padding-bottom: 52.32704%; }
      .Gallery--4 .Gallery-item:nth-child(2) {
        grid-column: 3/4;
        grid-row: 1/2; }
        .Gallery--4 .Gallery-item:nth-child(2) .Gallery-object {
          padding-bottom: 106.66667%; }
      .Gallery--4 .Gallery-item:nth-child(3) {
        grid-column: 1/2;
        grid-row: 2/3; }
        .Gallery--4 .Gallery-item:nth-child(3) .Gallery-object {
          padding-bottom: 106.66667%; }
      .Gallery--4 .Gallery-item:nth-child(4) {
        grid-column: 2/4;
        grid-row: 2/3; }
        .Gallery--4 .Gallery-item:nth-child(4) .Gallery-object {
          padding-bottom: 52.32704%; } }
  .Gallery + .Gallery {
    padding-top: 0.9375rem; }
  .Gallery-item {
    padding-top: 0.9375rem;
    padding-left: 0.9375rem; }
  .Gallery-image, .Gallery-object {
    height: 100%; }
  @media screen and (min-width: 37.5em) {
    .Gallery-object {
      position: relative;
      z-index: 0; }
    .Gallery-image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      height: 100%;
      width: 100%;
      object-fit: cover; } }

/* Blocks */
.Block + .Block, .Block .Anchor, .Anchor + .Block, .Anchor .Anchor {
  margin-top: 3.75rem; }
  @media screen and (min-width: 48em) {
    .Block + .Block, .Block .Anchor, .Anchor + .Block, .Anchor .Anchor {
      margin-top: 7.5rem; } }

.Main > .Block:first-child, .Main > .Anchor:first-child {
  margin-top: 2.5rem; }
  @media screen and (min-width: 48em) {
    .Main > .Block:first-child, .Main > .Anchor:first-child {
      margin-top: 5rem; } }

.Block.has-color, .Anchor.has-color {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; }
  .Block.has-color + .has-color, .Anchor.has-color + .has-color {
    margin-top: 0; }
  .Block.has-color:last-child, .Anchor.has-color:last-child {
    margin-bottom: -3.75rem; }
  @media screen and (min-width: 48em) {
    .Block.has-color, .Anchor.has-color {
      padding-top: 7.5rem;
      padding-bottom: 7.5rem; }
      .Block.has-color:last-child, .Anchor.has-color:last-child {
        margin-bottom: -7.5rem; } }

.Block.is-grey, .Anchor.is-grey {
  background-color: #f9f9f9;
  color: rgba(49, 49, 49, 0.6); }

.Block-item, .Anchor-item {
  background-color: #f9f9f9;
  border: 1px solid rgba(177, 177, 176, 0.2);
  padding: 1.25rem; }
  .has-color .Block-item, .has-color .Anchor-item {
    background-color: #ffffff; }
  .Block-item :last-child, .Anchor-item :last-child {
    margin-bottom: 0; }
  @media screen and (min-width: 64em) {
    .Block-item, .Anchor-item {
      padding: 2.5rem; }
      .Block-item--sm, .Anchor-item--sm {
        padding: 1.875rem; }
      .Block-item--xs, .Anchor-item--xs {
        padding: 0.625rem 1.25rem; } }
  .Block-item + .Block-item, .Anchor-item + .Block-item {
    border-top: none; }

.BlockItem-body, .AnchorItem-body {
  max-width: 33.75rem;
  margin-right: auto;
  margin-left: auto; }

.Block-body, .Anchor-body {
  max-width: 40rem; }

.Block-more, .Anchor-more {
  text-align: center;
  margin-top: 3.75rem; }

.Block--banner {
  color: #313131; }
  @media screen and (min-width: 48em) {
    .Block--banner:first-child {
      margin-top: 2.5rem; } }

@media screen and (min-width: 48em) {
  .Banner {
    display: grid;
    grid-template-columns: calc(65% - 14.375rem) minmax(min-content, 14.375rem) 1fr;
    grid-template-rows: 1fr; }
    .Banner-object {
      grid-column: 1/3;
      grid-row: 1/2; }
    .Banner-body {
      grid-column: 2/4;
      grid-row: 1/2;
      align-self: center; } }
    @media screen and (min-width: 48em) and (min-width: 48em) {
      .Banner-body {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem; } }

.Banner-body {
  position: relative;
  z-index: 1;
  background-color: #f9f9f9;
  font-weight: 100;
  padding: 2.5rem;
  line-height: 1.4; }
  @media screen and (min-width: 48em) {
    .Banner-body {
      padding: 5rem; } }

.Banner-title {
  margin-bottom: 0.625rem;
  color: inherit; }
  @media screen and (min-width: 48em) {
    .Banner-title {
      line-height: 1.5; } }
  .Banner-title strong {
    color: #9d1d06;
    font-weight: inherit; }

.Block--bigBanner {
  position: relative;
  color: #313131;
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem; }
  @media screen and (min-width: 48em) {
    .Block--bigBanner:first-child {
      margin-top: 2.5rem; } }
  .Block--bigBanner--first {
    margin-top: 0 !important; }

.BigBannerImage-object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .BigBannerImage-object img {
    object-fit: cover;
    width: 100%;
    height: 100%; }

.BigBanner {
  position: relative; }
  .BigBanner-body {
    position: relative;
    z-index: 1;
    background-color: rgba(249, 249, 249, 0.9);
    font-weight: 400;
    padding: 2.5rem;
    line-height: 1.4;
    max-width: 38rem; }
    @media screen and (min-width: 48em) {
      .BigBanner-body {
        padding: 5rem; } }
  .BigBanner-title {
    margin-bottom: 0.625rem;
    color: inherit; }
    @media screen and (min-width: 48em) {
      .BigBanner-title {
        line-height: 1.5; } }
    .BigBanner-title strong {
      color: #9d1d06;
      font-weight: inherit; }
  .BigBanner .Button {
    margin-top: 2.5rem; }

.Block--columns {
  background-color: #C4281A;
  color: #ffffff;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; }
  .Block--columns a:not([class]) {
    text-decoration: underline; }
    .Block--columns a:not([class]):hover {
      text-decoration: none; }
    .Block--columns a:not([class]), .Block--columns a:not([class]):active, .Block--columns a:not([class]):focus, .Block--columns a:not([class]):hover {
      color: currentColor; }
  @media screen and (min-width: 48em) {
    .Block--columns {
      padding-top: 6.25rem;
      padding-bottom: 6.25rem; } }
  .Block--columns + .Block--content {
    margin-top: 3.75rem; }
  .Block--columns--white {
    background-color: white;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #767676; }
    .Block--columns--white .Column-object {
      background-color: transparent;
      padding: 0; }
    .Block--columns--white .Column-title {
      color: #C4281A; }
    @media screen and (max-width: 37.5em) {
      .Block--columns--white .Column-object {
        height: auto; } }

.Column.has-link {
  color: currentColor;
  text-decoration: none; }
  .Column.has-link:hover .Column-icon {
    transform: scale(1.2); }
  .Column.has-link .Column-icon {
    transition-property: transform;
    transition-duration: 200ms;
    transition-timing-function: linear; }
  .Column.has-link + .Column-button {
    margin-top: 1rem; }

.Column-object {
  height: 9.375rem;
  width: 9.375rem;
  background-color: #ffffff;
  border-radius: 100%;
  color: #313131;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-bottom: 1.875rem;
  margin-left: auto;
  padding: 0 1rem; }

.Column-body {
  text-align: center;
  max-width: 17.5rem;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (max-width: 47.9375em) {
    .Column-body {
      max-width: 80%; } }
  .Column-body :last-child {
    margin-bottom: 0; }
  .Column-body + .Column-button {
    margin-top: 1rem; }

.Column-title {
  margin-bottom: 0.625rem;
  color: inherit; }

.Column-button {
  min-width: unset !important; }

.Teaser {
  position: relative;
  z-index: 0;
  display: block;
  text-decoration: none;
  max-width: 24.375rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%; }
  .Teaser--spotlight {
    max-width: 49.6875rem; }
  .Teaser, .Teaser:active, .Teaser:focus, .Teaser:hover {
    color: #ffffff; }
  .Teaser::after {
    height: 0.375rem;
    width: 0rem;
    transition-property: width;
    transition-duration: 200ms;
    transition-timing-function: linear;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    background-color: #C4281A;
    content: '';
    display: block; }
  .Teaser:hover::after {
    width: 100%; }
  .Teaser-body {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding: 1.875rem 2.5rem; }
    .Teaser-body .Button {
      display: inline-flex;
      align-items: center; }
      .Teaser-body .Button-icon {
        margin-left: 1.25rem; }
  .Teaser-object {
    position: relative;
    z-index: 0;
    background-color: #313131;
    padding-bottom: 106.66667%;
    overflow: hidden; }
    @media screen and (min-width: 48em) {
      .Teaser--spotlight .Teaser-object {
        padding-bottom: 52.32704%; } }
    .Teaser-object::after {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-image: linear-gradient(182deg, rgba(0, 0, 0, 0), #000000);
      content: ''; }
  .Teaser-image {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    transition-property: opacity, transform;
    transition-duration: 200ms;
    transition-timing-function: linear;
    object-fit: cover; }
    .Teaser:hover .Teaser-image {
      opacity: 0.9;
      transform: scale(1.1); }

.Block--teasers {
  max-width: 125rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem; }

.TeaserGridItem {
  position: relative;
  z-index: 0;
  display: block;
  text-decoration: none;
  margin-right: auto;
  margin-left: auto;
  width: 100%; }
  @media screen and (max-width: 79.9375em) {
    .TeaserGridItem {
      min-height: 400px; } }
  .TeaserGridItem--spotlight {
    max-width: 49.6875rem; }
  .TeaserGridItem, .TeaserGridItem:active, .TeaserGridItem:focus, .TeaserGridItem:hover {
    transition-property: height, margin-top;
    transition-duration: 200ms;
    transition-timing-function: linear;
    color: #ffffff;
    height: 100%; }
    @media screen and (max-width: 64em) {
      .TeaserGridItem, .TeaserGridItem:active, .TeaserGridItem:focus, .TeaserGridItem:hover {
        max-height: 320px;
        overflow: hidden;
        max-width: 100%; } }
  .TeaserGridItem:hover {
    height: calc(100% + 60px);
    transition-property: height, margin-top;
    transition-duration: 200ms;
    transition-timing-function: linear;
    margin-top: -30px; }
    @media screen and (max-width: 64em) {
      .TeaserGridItem:hover {
        height: 100%;
        margin-top: 0; } }
    .TeaserGridItem:hover .TeaserGridItem-hidden {
      display: block;
      height: auto;
      width: auto;
      opacity: 1;
      transition-property: opacity;
      transition-duration: 200ms;
      transition-timing-function: linear; }
  .TeaserGridItem:hover::after {
    width: 100%; }
  .TeaserGridItem-body {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding: 1.875rem 2.5rem; }
    .TeaserGridItem-body .TeaserGridItem-hidden {
      display: none;
      height: 0px;
      width: 0px;
      opacity: 0;
      transition-property: opacity;
      transition-duration: 200ms;
      transition-timing-function: linear; }
      @media screen and (max-width: 64em) {
        .TeaserGridItem-body .TeaserGridItem-hidden {
          visibility: visible;
          display: block;
          height: auto;
          width: auto;
          opacity: 1; } }
    .TeaserGridItem-body .TeaserGridItem-text {
      margin-top: 0.625rem;
      font-size: 1rem;
      font-weight: 400; }
    .TeaserGridItem-body .TeaserGridItem-logo {
      height: 10rem;
      margin-left: -1rem;
      margin-bottom: 0.625rem; }
    .TeaserGridItem-body .Button {
      align-items: flex-start;
      display: flex;
      flex-direction: column; }
      .TeaserGridItem-body .Button--arrow {
        display: block;
        width: 100%;
        font-size: 1.125rem;
        text-align: left; }
      .TeaserGridItem-body .Button-icon {
        margin-left: 0.625rem; }
  .TeaserGridItem-object {
    position: relative;
    z-index: 0;
    transition-property: height;
    transition-duration: 200ms;
    transition-timing-function: linear;
    background-color: #313131;
    padding-bottom: 106.66667%;
    overflow: hidden;
    height: 100%; }
    @media screen and (min-width: 48em) {
      .TeaserGridItem--spotlight .TeaserGridItem-object {
        padding-bottom: 52.32704%; } }
    .TeaserGridItem-object::after {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-image: linear-gradient(182deg, rgba(0, 0, 0, 0), #000000);
      content: ''; }
  .TeaserGridItem-image {
    height: 100%;
    width: 100%;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    transition-property: opacity, transform, width, height;
    transition-duration: 200ms;
    transition-timing-function: linear;
    object-fit: cover; }
    @media screen and (max-width: 64em) {
      .TeaserGridItem-image {
        opacity: 0.2; } }
    .TeaserGridItem:hover .TeaserGridItem-image {
      opacity: 0.2; }
  .TeaserGridItem .Button--primary {
    padding: 8px 5px;
    display: inline-block;
    font-weight: 400;
    font-size: 0.875rem; }

.Block--tiles .Title {
  max-width: 50rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto; }

.Block--tiles .Tile {
  position: relative;
  z-index: 0;
  display: block;
  text-decoration: none;
  max-width: 24.375rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%; }
  @media screen and (max-width: 79.9375em) {
    .Block--tiles .Tile {
      min-height: 400px; } }
  .Block--tiles .Tile--spotlight {
    max-width: 49.6875rem; }
  .Block--tiles .Tile, .Block--tiles .Tile:active, .Block--tiles .Tile:focus, .Block--tiles .Tile:hover {
    transition-property: height, margin-top;
    transition-duration: 200ms;
    transition-timing-function: linear;
    color: #ffffff;
    height: 100%; }
    @media screen and (max-width: 64em) {
      .Block--tiles .Tile, .Block--tiles .Tile:active, .Block--tiles .Tile:focus, .Block--tiles .Tile:hover {
        max-height: 320px;
        overflow: hidden;
        max-width: 100%; } }
  .Block--tiles .Tile:hover {
    height: calc(100% + 60px);
    background: red;
    transition-property: height, margin-top;
    transition-duration: 200ms;
    transition-timing-function: linear;
    margin-top: -30px; }
    @media screen and (max-width: 64em) {
      .Block--tiles .Tile:hover {
        height: 100%;
        margin-top: 0; } }
    .Block--tiles .Tile:hover .Tile-hidden {
      display: block;
      height: auto;
      width: auto;
      opacity: 1;
      transition-property: opacity;
      transition-duration: 200ms;
      transition-timing-function: linear; }
  .Block--tiles .Tile:hover::after {
    width: 100%; }
  .Block--tiles .Tile-body {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding: 1.875rem 2.5rem; }
    .Block--tiles .Tile-body .Tile-hidden {
      display: none;
      height: 0px;
      width: 0px;
      opacity: 0;
      transition-property: opacity;
      transition-duration: 200ms;
      transition-timing-function: linear; }
      @media screen and (max-width: 64em) {
        .Block--tiles .Tile-body .Tile-hidden {
          visibility: visible;
          display: block;
          height: auto;
          width: auto;
          opacity: 1; } }
    .Block--tiles .Tile-body .Tile-text {
      margin-top: 0.625rem;
      font-size: 1rem;
      font-weight: 400; }
    .Block--tiles .Tile-body .Tile-logo {
      height: 10rem;
      margin-left: -1rem;
      margin-bottom: 0.625rem; }
    .Block--tiles .Tile-body .Button {
      align-items: flex-start;
      display: flex;
      flex-direction: column; }
      .Block--tiles .Tile-body .Button--arrow {
        display: block;
        width: 100%;
        font-size: 1.125rem;
        text-align: left; }
      .Block--tiles .Tile-body .Button-icon {
        margin-left: 0.625rem; }
  .Block--tiles .Tile-object {
    position: relative;
    z-index: 0;
    transition-property: height;
    transition-duration: 200ms;
    transition-timing-function: linear;
    background-color: #313131;
    padding-bottom: 106.66667%;
    overflow: hidden;
    height: 100%; }
    @media screen and (min-width: 48em) {
      .Tile--spotlight .Block--tiles .Tile-object {
        padding-bottom: 52.32704%; } }
    .Block--tiles .Tile-object::after {
      position: absolute;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background-image: linear-gradient(182deg, rgba(0, 0, 0, 0), #000000);
      content: ''; }
  .Block--tiles .Tile-image {
    height: 100%;
    width: 100%;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    transition-property: opacity, transform, width, height;
    transition-duration: 200ms;
    transition-timing-function: linear;
    object-fit: cover; }
    @media screen and (max-width: 64em) {
      .Block--tiles .Tile-image {
        opacity: 0.2; } }
    .Tile:hover .Block--tiles .Tile-image {
      opacity: 0.2; }
  .Block--tiles .Tile .Button--primary {
    padding: 8px 5px;
    display: inline-block;
    font-weight: 400;
    font-size: 0.875rem; }

.Block--content + .Block--cta {
  margin-top: 2.5rem; }
  @media screen and (min-width: 48em) {
    .Block--content + .Block--cta {
      margin-top: 5rem; } }

.Cta {
  background-color: #f9f9f9;
  padding: 3.75rem 1.25rem;
  text-align: center; }
  .Cta-body {
    line-height: 1.5;
    max-width: 54.375rem;
    margin-right: auto;
    margin-left: auto; }
    .Cta-body > :last-child {
      margin-bottom: 0; }
    .Cta-body p, .Cta-body ul, .Cta-body ol {
      margin-bottom: 1.875rem; }
  .Cta-title {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .Cta-title .Shape {
      margin-bottom: 1.25rem; }

.Block--text .Container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .Block--text .Container * {
    text-align: center;
    max-width: 37.5rem; }

.Block--textleft .Container {
  align-items: flex-start; }
  .Block--textleft .Container * {
    text-align: left; }

.Block--textcenter .Container {
  align-items: center; }
  .Block--textcenter .Container * {
    text-align: center; }

.Block--textright .Container {
  align-items: flex-end; }
  .Block--textright .Container * {
    text-align: right; }

.Block--textMedia.is-jobs .TextMedia {
  margin-left: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.9375rem; }
  @media screen and (max-width: 63.9375em) {
    .Block--textMedia.is-jobs .TextMedia {
      display: flex;
      flex-direction: column; } }
  .Block--textMedia.is-jobs .TextMedia-object, .Block--textMedia.is-jobs .TextMedia .Media-object {
    display: block;
    grid-column: 1/3;
    margin-left: 0; }
    .Block--textMedia.is-jobs .TextMedia-object::after, .Block--textMedia.is-jobs .TextMedia .Media-object::after {
      display: none; }
  .Block--textMedia.is-jobs .TextMedia-body {
    background-color: #C4281A;
    color: #ffffff;
    margin-left: 0;
    margin-top: 0;
    grid-column: 3/4;
    padding: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
    width: 100%;
    max-width: 100%; }
    .Block--textMedia.is-jobs .TextMedia-body * {
      margin-bottom: 0; }
    .Block--textMedia.is-jobs .TextMedia-body ul {
      display: flex;
      flex-direction: column;
      gap: 0.75rem; }
      .Block--textMedia.is-jobs .TextMedia-body ul li::before {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D'15'%20height%3D'15'%20viewBox%3D'0%200%2015%2015'%20fill%3D'none'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%3E%3Cpath%20d%3D'M5.74997%209.93241L3.31747%207.49991L2.48914%208.32241L5.74997%2011.5832L12.75%204.58324L11.9275%203.76074L5.74997%209.93241Z'%20fill%3D'white'%2F%3E%3C%2Fsvg%3E"); }
    .Block--textMedia.is-jobs .TextMedia-body p, .Block--textMedia.is-jobs .TextMedia-body ul {
      color: inherit; }
  .Block--textMedia.is-jobs .TextMedia-title {
    color: #ffffff;
    margin-bottom: 0; }

.Block--textMedia.accent-grey .TextMedia-object::after {
  background-color: #f9f9f9; }

.Block--textMedia.accent-red .TextMedia-object::after {
  background-color: #C4281A; }

.TextMedia {
  display: flex;
  flex-direction: column;
  gap: 1.875rem; }
  @media screen and (min-width: 48em) {
    .TextMedia {
      flex-direction: row;
      gap: 3.75rem;
      justify-content: space-between; }
      .TextMedia--2by3 {
        align-items: center; }
      .TextMedia--3by2 {
        align-items: flex-start; } }
  .TextMedia .Slider-link {
    position: relative;
    display: block; }
    .TextMedia .Slider-link img {
      width: 100%; }
    .TextMedia .Slider-link .Play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .TextMedia .Media-object--video {
    flex-grow: 1; }
  .TextMedia-body {
    flex-grow: 1;
    max-width: 38.75rem; }
    .TextMedia-body .Container {
      padding: 0; }
    .TextMedia-body .Content .Field-title::before {
      display: none; }
    .TextMedia-body h2:not(.TextMedia-title) {
      margin-bottom: 0.3125rem; }
    .TextMedia-body h3:not(.TextMedia-subtitle) {
      margin-bottom: 0.3125rem; }
    .TextMedia-body p, .TextMedia-body ul {
      color: rgba(49, 49, 49, 0.6); }
    .TextMedia-body > :last-child {
      margin-bottom: 0; }
    .TextMedia--3by2 .TextMedia-body {
      max-width: 38.75rem; }
  .TextMedia-object::after {
    position: relative;
    z-index: -1;
    height: 100%;
    width: 100%;
    display: block; }
  .TextMedia-title {
    position: relative; }
    .TextMedia-title::before {
      position: absolute;
      top: 50%;
      right: unset;
      bottom: unset;
      left: 100%;
      border-bottom: 1px solid;
      content: '';
      display: block;
      width: 1.25rem;
      transform: translateX(62px); }
      @media screen and (min-width: 48em) and (max-width: 69.9375em) {
        .TextMedia-title::before {
          width: 2.5rem; } }
      @media screen and (min-width: 70em) {
        .TextMedia-title::before {
          width: calc((100vw - 65rem) / 2); } }
  .TextMedia-subtitle:not(:first-child) {
    margin-top: 2.5rem; }
  .TextMedia.TextMedia--2by3 .Media-object::after {
    grid-column: 1/3;
    grid-row: 1/3; }
  .TextMedia.TextMedia--2by3 .Media-link,
  .TextMedia.TextMedia--2by3 .Media-image {
    grid-column: 2/4;
    grid-row: 2/4; }
  @media screen and (min-width: 48em) {
    .TextMedia.TextMedia--2by3.TextMedia--right {
      flex-direction: row-reverse; } }
  .TextMedia.TextMedia--2by3.TextMedia--right .Media-object::after {
    grid-column: 2/4;
    grid-row: 1/3; }
  .TextMedia.TextMedia--2by3.TextMedia--right .Media-link,
  .TextMedia.TextMedia--2by3.TextMedia--right .Media-image {
    grid-column: 1/3;
    grid-row: 2/4; }
  .TextMedia.TextMedia--3by2 .Media-object::after, .TextMedia.TextMedia--noRatio .Media-object::after {
    grid-column: 2/4;
    grid-row: 2/4; }
  .TextMedia.TextMedia--3by2 .Media-link,
  .TextMedia.TextMedia--3by2 .Media-image, .TextMedia.TextMedia--noRatio .Media-link,
  .TextMedia.TextMedia--noRatio .Media-image {
    grid-column: 1/3;
    grid-row: 1/3; }
  @media screen and (min-width: 48em) {
    .TextMedia.TextMedia--3by2.TextMedia--right, .TextMedia.TextMedia--noRatio.TextMedia--right {
      flex-direction: row-reverse; } }
  .TextMedia.TextMedia--3by2.TextMedia--right .TextMedia-object, .TextMedia.TextMedia--3by2.TextMedia--right .TextMedia-body, .TextMedia.TextMedia--noRatio.TextMedia--right .TextMedia-object, .TextMedia.TextMedia--noRatio.TextMedia--right .TextMedia-body {
    margin-left: 0; }
    @media screen and (max-width: 47.9375em) {
      .TextMedia.TextMedia--3by2.TextMedia--right .TextMedia-object, .TextMedia.TextMedia--3by2.TextMedia--right .TextMedia-body, .TextMedia.TextMedia--noRatio.TextMedia--right .TextMedia-object, .TextMedia.TextMedia--noRatio.TextMedia--right .TextMedia-body {
        margin-right: 0rem; } }
  .TextMedia.TextMedia--3by2.TextMedia--right .Media-object::after, .TextMedia.TextMedia--noRatio.TextMedia--right .Media-object::after {
    grid-column: 1/3;
    grid-row: 1/3; }
  .TextMedia.TextMedia--3by2.TextMedia--right .Media-link,
  .TextMedia.TextMedia--3by2.TextMedia--right .Media-image, .TextMedia.TextMedia--noRatio.TextMedia--right .Media-link,
  .TextMedia.TextMedia--noRatio.TextMedia--right .Media-image {
    grid-column: 2/4;
    grid-row: 2/4; }
  .TextMedia.TextMedia--noRatio .Media-link {
    width: 100%;
    display: block; }
  @media screen and (min-width: 48em) {
    .Block--sector .TextMedia {
      align-items: flex-start; }
      .Block--sector .TextMedia-object {
        margin-left: 0; } }
  .Block--sector .TextMedia .Content .Field-title.Field-title--h2::before {
    display: none; }
  .Block--sector .TextMedia .Media-object::after {
    grid-column: 1/3;
    grid-row: 1/3; }
  .Block--sector .TextMedia .Media-link,
  .Block--sector .TextMedia .Media-image {
    grid-column: 2/4;
    grid-row: 2/4; }
  .Block--jobs.accent-grey .TextMedia .Media-object::after {
    background-color: #f9f9f9; }
  .Block--jobs.accent-red .TextMedia .Media-object::after {
    background-color: #C4281A; }
  .Block--jobs .TextMedia {
    gap: 3.75rem; }
    @media screen and (min-width: 48em) {
      .Block--jobs .TextMedia {
        align-items: center;
        justify-content: center; }
        .Block--jobs .TextMedia-body {
          margin-top: 0; } }
    @media screen and (max-width: 48em) {
      .Block--jobs .TextMedia {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .Block--jobs .TextMedia-body {
          max-width: 100%;
          width: 100%; } }
    .Block--jobs .TextMedia .Media-object {
      position: relative; }
      .Block--jobs .TextMedia .Media-object::after {
        width: 21.625rem;
        height: calc(100% - 60px);
        position: absolute;
        top: 0rem;
        left: 5.625rem;
        z-index: -1; }
    .Block--jobs .TextMedia .Media-link,
    .Block--jobs .TextMedia .Media-image {
      grid-column: 1/3;
      grid-row: 1/3; }
  .TextMedia .SliderWrapper {
    position: relative; }
    .TextMedia .SliderWrapper .Slider-object {
      text-align: center; }
      .TextMedia .SliderWrapper .Slider-object img {
        width: auto;
        display: inline-block; }
    .TextMedia .SliderWrapper .Slider-button {
      background: rgba(30, 30, 30, 0.6); }
    .TextMedia .SliderWrapper .Slider-button--prev {
      left: 3.75rem; }
  .TextMedia--right .TextMedia-title::before {
    position: absolute;
    top: 50%;
    right: 100%;
    bottom: unset;
    left: unset;
    transform: translateX(-62px); }

.Block--bar {
  position: relative;
  z-index: 1;
  color: #ffffff; }
  @media screen and (min-width: 64em) {
    .Block--bar {
      background-color: #C4281A;
      display: flex; } }
  @media screen and (min-width: 64em) {
    .Block--bar.is-extended .Bar:nth-of-type(2n+2)::after {
      height: 0rem;
      width: 0rem;
      position: absolute;
      top: 0;
      right: -25px;
      bottom: 0;
      z-index: 1;
      background-color: #C4281A;
      border-style: solid;
      border-width: 8.75rem 0 0 8.75rem;
      border-color: transparent transparent transparent #9d1d06;
      content: '';
      display: block;
      transform: translateX(-50%), rotate(180deg);
      width: 0; } }

.Bar {
  transition-property: width;
  transition-duration: 200ms;
  transition-timing-function: linear;
  display: flex;
  align-items: center;
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
  justify-content: center; }
  .Bar:nth-of-type(2n+1) {
    background-color: #C4281A; }
  .Bar:nth-of-type(2n+2) {
    background-color: #9d1d06; }
    @media screen and (min-width: 64em) {
      .Bar:nth-of-type(2n+2) {
        position: relative; }
        .Bar:nth-of-type(2n+2)::before {
          height: 0rem;
          width: 0rem;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 2.1875rem;
          z-index: 1;
          background-color: #9d1d06;
          border-style: solid;
          border-width: 8.75rem 0 0 8.75rem;
          border-color: transparent transparent transparent #C4281A;
          content: '';
          display: block;
          transform: translateX(-50%);
          width: 0; } }
  @media screen and (min-width: 64em) {
    .Bar {
      padding-top: 2.625rem;
      padding-bottom: 2.625rem;
      flex-grow: 1;
      height: 8.75rem; } }
  @media screen and (max-width: 63.9375em) {
    .Bar {
      justify-content: center; } }
  .Bar, .Bar:active, .Bar:focus, .Bar:hover {
    color: #ffffff;
    text-decoration: none; }
  .Bar-item {
    display: flex;
    align-items: center;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    flex-direction: column;
    z-index: 2; }
    @media screen and (min-width: 64em) and (max-width: 63.9375em) {
      .Bar-item {
        display: block; } }
    @media screen and (min-width: 64em) {
      .Bar-item {
        max-width: 40rem;
        width: 100%; } }
  .Bar-object {
    flex-shrink: 0;
    height: 1.875rem;
    width: 1.875rem;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .Bar-body {
    width: 100%; }
  .Bar .Button--bare {
    margin-bottom: 0.625rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center; }
    .Bar .Button--bare .Button-icon {
      margin-top: 8px; }
    @media screen and (max-width: 48em) {
      .Bar .Button--bare {
        margin-bottom: 0; } }

.Block--intro + .Block {
  margin-top: 3.75rem; }

.Intro {
  color: #767676; }
  .Intro-header {
    margin-bottom: 1.25rem; }
    .Intro-header .Nav--breadcrumbs {
      padding-left: 0rem; }
  .Intro-title {
    margin-bottom: 0.625rem;
    color: #9d1d06; }
    .Intro-title:not(.has-shape) {
      text-align: center;
      max-width: 55rem; }
      .Intro-title:not(.has-shape),
      .Intro-title:not(.has-shape) .Shape {
        margin-right: auto;
        margin-left: auto; }
      .Intro-title:not(.has-shape) .Shape {
        margin-bottom: 1.25rem; }
    .ProductsPage .Intro-title {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
      .ProductsPage .Intro-title small {
        font-size: 58.82353%;
        font-weight: 100;
        line-height: 1.4;
        transform: translateY(10%); }
  .Intro-body {
    max-width: 38.75rem; }
  .Intro-text.is-collapsed {
    display: none; }

.Block--boxes + .Block {
  padding-top: 3.75rem; }
  .Block--boxes + .Block--testimonials {
    margin-top: 0; }
  @media screen and (min-width: 48em) {
    .Block--boxes + .Block {
      padding-top: 7.5rem; } }
  .CategoriesPage .Block--boxes + .Block {
    margin-top: 1.875rem;
    padding-top: 1.875rem; }
    @media screen and (min-width: 48em) {
      .CategoriesPage .Block--boxes + .Block {
        margin-top: 3.75rem;
        padding-top: 3.75rem; } }

.Block--boxes .Boxes {
  position: relative;
  z-index: 1;
  padding-bottom: 5rem; }
  .Block--boxes .Boxes::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: #f9f9f9;
    content: '';
    transform: translateY(3.75rem); }
    @media screen and (min-width: 48em) {
      .Block--boxes .Boxes::before {
        transform: translateY(7.5rem); } }

.Block--boxes .Grid-cell {
  display: flex;
  flex-direction: column; }
  .Block--boxes .Grid-cell > .Box {
    flex-grow: 1; }
  .Block--boxes .Grid-cell .Box-body {
    margin-top: auto;
    margin-bottom: auto; }

.Box {
  transition-property: color, font-weight;
  transition-duration: 200ms;
  transition-timing-function: linear;
  position: relative;
  z-index: 0;
  background-color: #ffffff;
  border: 1px solid rgba(177, 177, 176, 0.2);
  display: flex;
  flex-direction: column-reverse;
  font-weight: 100;
  margin-right: auto;
  margin-left: auto;
  width: 100%; }
  .Box:not(.is-product), .Box:not(.is-product):active, .Box:not(.is-product):focus {
    color: currentColor; }
  .Box:not(.is-product):hover {
    color: #C4281A;
    font-weight: 500; }
  .Box:not(.is-product), .Box:not(.is-product):active, .Box:not(.is-product):focus, .Box:not(.is-product):hover {
    text-decoration: none; }
  .Box:not(.is-product)::after {
    height: 0.375rem;
    width: 0rem;
    transition-property: width;
    transition-duration: 200ms;
    transition-timing-function: linear;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    background-color: #C4281A;
    content: '';
    display: block; }
  .Box:not(.is-product):hover::after {
    width: 100%; }
  .Box.is-product .Box-title {
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.375rem;
    color: #414042;
    text-align: left;
    margin-bottom: 0.625rem; }
  .Box.is-product .Box-body {
    padding: 1rem; }
  .Box.is-product .Price {
    text-align: left; }
  .Box-buttons {
    display: flex;
    column-gap: 1.25rem;
    margin-top: 1.125rem; }
    .Box-buttons .Button {
      min-width: auto;
      padding-left: 1.125rem;
      padding-right: 1.125rem; }
  .Box-object {
    background-color: #313131;
    border: 0.625rem solid #ffffff;
    margin-bottom: -0.625rem;
    overflow: hidden; }
  .Box-image {
    width: 100%;
    transition-property: opacity, transform;
    transition-duration: 200ms;
    transition-timing-function: linear; }
    .Box:hover .Box-image {
      opacity: 0.9;
      transform: scale(1.1); }
    .Box.is-product:hover .Box-image {
      opacity: 1;
      transform: scale(1); }
  .Box-body {
    padding: 1.25rem;
    text-align: center;
    min-height: 5.625rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1; }
  .Box-title {
    color: currentColor;
    font-weight: inherit;
    margin-bottom: 0; }

.Info + .Products {
  margin-top: 2.5rem; }
  @media screen and (min-width: 48em) {
    .Info + .Products {
      margin-top: 5rem; } }

@media screen and (min-width: 48em) {
  .Products .Table {
    margin-top: -0.9375rem; } }

@media screen and (min-width: 48em) {
  .Products .Table-cell:last-child {
    width: 33.33333%; } }

.Products-link {
  color: currentColor; }
  .Products-link span {
    transition-property: box-shadow;
    transition-duration: 200ms;
    transition-timing-function: linear;
    box-shadow: 0 1px rgba(177, 177, 176, 0.5); }
  .Products-link .Link-icon {
    margin-right: 0.3125rem; }
  .Products-link:hover {
    color: #C4281A; }
    .Products-link:hover span {
      box-shadow: none; }
  .Products-link, .Products-link:active, .Products-link:focus, .Products-link:hover {
    text-decoration: none; }

.Table-cell--large {
  font-weight: 100;
  line-height: 1.45; }
  @media screen and (min-width: 48em) {
    .Table-cell--large {
      font-size: 1.15rem; } }

.Info {
  color: rgba(49, 49, 49, 0.6); }
  @media screen and (min-width: 48em) {
    .Info {
      display: flex; } }
  @media screen and (max-width: 47.9375em) {
    .Info-object {
      margin-bottom: 2.5rem; } }
  @media screen and (min-width: 48em) {
    .Info-object {
      flex-shrink: 0;
      margin-right: 3.75rem;
      max-width: 24.375rem;
      width: calc(100% / 1040 * 390); } }
  .Info-object > .Slider {
    border: 1px solid rgba(177, 177, 176, 0.2); }
  .Info-object .Slider-object {
    position: relative;
    z-index: 0;
    padding-bottom: 93.75%; }
    .Info-object .Slider-object.is-video {
      opacity: .6; }
  .Info-object .Slider-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); }
  .Info-object .Slider-image {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate3d(-50%, -50%, 0); }
  .Info-object > .Thumbnails {
    display: flex;
    flex-wrap: wrap;
    padding-top: 0.625rem;
    margin-top: -0.625rem;
    margin-left: -0.625rem; }
    .Info-object > .Thumbnails .Thumbnail-item {
      border: 1px solid rgba(177, 177, 176, 0.2);
      cursor: pointer;
      max-width: 5.625rem;
      margin-top: 0.625rem;
      margin-left: 0.625rem; }
    .Info-object > .Thumbnails .Thumbnail-object {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover; }
      .Info-object > .Thumbnails .Thumbnail-object img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .Info-object > .Thumbnails .Thumbnail-object .Thumbnail-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%); }
  .Info-body {
    flex-grow: 1; }
  .Info-title {
    margin-bottom: 0.625rem; }

.Product-info {
  margin-top: -0.9375rem; }

.ProductInfo-item {
  border-bottom: 1px solid rgba(177, 177, 176, 0.4);
  padding: 0.9375rem 0.625rem 0.9375rem 0.625rem; }
  .ProductInfo-item:last-of-type {
    border-bottom: none; }
  @media screen and (min-width: 48em) {
    .ProductInfo-item {
      display: flex; } }

.ProductInfo-label {
  flex-shrink: 0;
  font-weight: inherit; }
  @media screen and (min-width: 48em) {
    .ProductInfo-label {
      width: 30%; } }

.ProductInfo-description {
  color: #313131;
  flex-grow: 1;
  font-weight: 500; }
  @media screen and (max-width: 47.9375em) {
    .ProductInfo-description {
      margin-top: 0.625rem; } }
  @media screen and (min-width: 48em) {
    .ProductInfo-description {
      padding-left: 1.25rem; } }

.AddToCart {
  max-width: 18.75rem; }
  .AddToCart-header {
    margin-bottom: 1.25rem; }

.Downloads {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1.25rem;
  margin-left: -1.25rem; }
  .Downloads > .Download {
    padding-top: 1.25rem;
    padding-left: 1.25rem;
    width: 100%; }
    @media screen and (min-width: 64em) {
      .Downloads > .Download {
        width: 50%; } }

.Download {
  display: inline-flex;
  align-items: center; }
  .Download, .Download:active, .Download:focus, .Download:hover {
    color: currentColor; }
    .Content-body .Download, .Content-body .Download:active, .Content-body .Download:focus, .Content-body .Download:hover {
      color: #313131; }
  .Download:hover {
    text-decoration: none; }
  .Download-icon {
    display: block;
    flex-shrink: 0;
    margin-right: 0.9375rem;
    user-select: none; }

.Block--faq {
  background-color: #C4281A;
  color: #ffffff; }

.Faq-title {
  color: currentColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 3.125rem; }
  .Faq-title .Shape {
    margin-bottom: 1.25rem; }

.Faq-items {
  background-color: rgba(249, 249, 249, 0.1); }

.Faq-item {
  border-top: 1px solid #ffffff; }
  .Faq-item:last-child {
    border-bottom: 1px solid #ffffff; }

.Faq-question {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 87.5%;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  line-height: 2.14;
  padding: 0.9375rem 1.25rem; }
  @media screen and (min-width: 48em) {
    .Faq-question {
      padding-right: 2.5rem;
      padding-left: 2.5rem; } }

.Faq-icon {
  transition-property: transform;
  transition-duration: 200ms;
  transition-timing-function: linear;
  flex-shrink: 0;
  margin-left: 1.25rem; }
  .is-active .Faq-icon {
    transform: rotate(90deg); }

.Faq-answer {
  transition-property: max-height;
  transition-duration: 200ms;
  transition-timing-function: linear;
  max-height: 0;
  overflow: hidden; }
  .Faq-answer :last-child {
    margin-bottom: 0; }

.FaqAnswer-body {
  padding-right: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.25rem; }
  .FaqAnswer-body a:not([class]), .FaqAnswer-body a:not([class]):active, .FaqAnswer-body a:not([class]):focus, .FaqAnswer-body a:not([class]):hover {
    color: currentColor; }
  @media screen and (min-width: 48em) {
    .FaqAnswer-body {
      padding-right: 2.5rem;
      padding-left: 2.5rem; } }

@media screen and (min-width: 48em) {
  .Tips-body {
    margin-left: 3.875rem; } }

.Tips-item + .Tips-item {
  margin-top: 2.5rem; }

.Block--related + .Block--related {
  padding-top: 3.75rem; }
  @media screen and (min-width: 48em) {
    .Block--related + .Block--related {
      padding-top: 7.5rem; } }

.Block--content:first-child {
  margin-top: 2.5rem; }
  @media screen and (min-width: 48em) {
    .Block--content:first-child {
      margin-top: 5rem; } }

@media screen and (min-width: 48em) {
  .Block--content + .Block--apply {
    margin-top: 3.75rem; } }

.Block--content + .Block--content {
  margin-top: 0; }

.Block--content + .Block--columns {
  margin-top: 3.75rem; }

.Content p, .Content ul, .Content ol {
  max-width: 38.75rem; }

.Content-header {
  margin-bottom: 2.5rem; }

.Content-title {
  margin-bottom: 0.625rem; }

.Content-body p:not(:last-child) > .Button {
  margin-bottom: 2.5rem; }

.Content-body > :last-child {
  margin-bottom: 0; }

.Content-innerBody:not(:first-child) {
  margin-top: 2.5rem; }

.Content-innerBody:not(.has-columns) .Field-title[data-counter]::before {
  content: attr(data-counter) ". "; }

.Content-innerBody.has-columns {
  display: grid;
  gap: 2.5rem;
  grid-template-columns: repeat(3, 1fr); }
  @media screen and (max-width: 64em) {
    .Content-innerBody.has-columns {
      grid-template-columns: repeat(1, 1fr); } }
  .Content-innerBody.has-columns .Field-title {
    margin-top: 0; }
    .Content-innerBody.has-columns .Field-title::before {
      display: none; }

.Content-innerBody:last-child > :last-child {
  margin-bottom: 0; }

.Content-innerBody p, .Content-innerBody ul, .Content-innerBody ol {
  max-width: 43.75rem; }

.Content-innerItem {
  break-inside: avoid; }
  .Content-innerItem :last-child {
    margin-bottom: 0; }

.Content-footer {
  color: #C4281A;
  margin-top: 1.25rem; }

.Content-columns {
  position: relative;
  margin-top: 3.75rem;
  margin-bottom: 3.75rem; }
  .Content-columns:before {
    content: '';
    position: absolute;
    top: 0;
    left: -1000%;
    width: 1000%;
    height: 100%;
    background-color: inherit; }
  .Content-columns:after {
    content: '';
    position: absolute;
    top: 0;
    right: -1000%;
    width: 1000%;
    height: 100%;
    background-color: inherit; }

.Content-textColumns {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem; }

.Content .Field-title {
  margin-bottom: 1.875rem;
  margin-top: 2.5rem; }
  @media screen and (min-width: 48em) {
    .Content .Field-title.Field-title--h2 {
      position: relative; }
      .Content .Field-title.Field-title--h2::before {
        position: absolute;
        top: 50%;
        right: 100%;
        border-bottom: 1px solid;
        content: '';
        display: block;
        width: 1.25rem;
        transform: translateX(-62px); } }
    @media screen and (min-width: 48em) and (min-width: 48em) and (max-width: 69.9375em) {
      .Content .Field-title.Field-title--h2::before {
        width: 2.5rem; } }
    @media screen and (min-width: 48em) and (min-width: 70em) {
      .Content .Field-title.Field-title--h2::before {
        width: calc((100vw - 65rem) / 2); } }
  .Content .Field-title[data-counter]::before {
    background-color: transparent;
    border-bottom: none;
    content: attr(data-counter) ". ";
    position: static;
    right: auto;
    top: auto;
    transform: none;
    width: 1.875rem;
    display: inline-block; }

.Content .Media {
  max-width: 38.75rem;
  margin-bottom: 2.5rem;
  width: 100%; }
  @media screen and (min-width: 48em) {
    .Content .Media--left, .Content .Media--right {
      max-width: 35rem; }
    .Content .Media--left {
      clear: left;
      float: left;
      padding-right: 2.5rem; }
    .Content .Media--right {
      clear: right;
      float: right;
      padding-left: 2.5rem; } }
  @media screen and (min-width: 48em) and (max-width: 94.9375em) {
    .Content .Media {
      width: 50%; } }
  .Content .Media-caption {
    border-bottom: 1px solid #b1b1b0;
    text-align: center;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem; }
  .Content .Media-item img {
    width: 100%; }

.Media-object--video .Media-link {
  background-color: #313131; }

.Media-object--video .Media-image {
  opacity: 0.6; }

.Media-link {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr; }
  .Media-link, .Media-link:active, .Media-link:focus, .Media-link:hover {
    color: currentColor;
    text-decoration: none; }
  .Media-link .Media-object,
  .Media-link .Play {
    grid-column: 1/2;
    grid-row: 1/2; }
  .Media-link .Play {
    position: relative;
    z-index: 1;
    align-self: center;
    justify-self: center; }

.Tiles {
  clear: both;
  counter-reset: i; }
  .Tiles .Grid--withGutter {
    margin-top: -0.625rem;
    margin-left: -1.25rem; }
    .Tiles .Grid--withGutter > .Grid-cell {
      padding-top: 0.625rem;
      padding-left: 1.25rem; }
  .Tiles.has-columns .Grid--withGutter {
    margin-top: -1.25rem; }
    .Tiles.has-columns .Grid--withGutter > .Grid-cell {
      padding-top: 1.25rem; }
  .Tiles:not(:first-child) {
    margin-top: 2.5rem; }
  .Tiles:not(:last-child) {
    margin-bottom: 2.5rem; }
    @media screen and (min-width: 48em) {
      .Tiles:not(:last-child) {
        margin-bottom: 3.75rem; } }
  .Tiles .Tile {
    background-color: #f9f9f9;
    height: 100%;
    padding: 2.5rem; }
    .Tiles .Tile :last-child {
      margin-bottom: 0; }
    .Tiles .Tile:not(.has-number) {
      text-align: center; }
      .Tiles .Tile:not(.has-number) .Field-title {
        color: currentColor; }
      .Tiles .Tile:not(.has-number) p {
        max-width: 46.25rem;
        margin-right: auto;
        margin-left: auto; }
      .Tiles .Tile:not(.has-number).is-grey {
        color: #313131; }
    .Tiles .Tile.has-number {
      counter-increment: i; }
      .Tiles .Tile.has-number .Field-title {
        display: flex;
        align-items: baseline; }
        .Tiles .Tile.has-number .Field-title::before {
          content: counter(i) ".";
          display: block;
          width: 2.5rem;
          text-align: center; }
      .Tiles .Tile.has-number .Tile-body {
        padding-left: 2.5rem; }
    .Tiles .Tile.is-red {
      background-color: #C4281A;
      color: #ffffff; }
      .Tiles .Tile.is-red .Link--secondary, .Tiles .Tile.is-red .Link--secondary:active, .Tiles .Tile.is-red .Link--secondary:focus, .Tiles .Tile.is-red .Link--secondary:hover {
        color: currentColor; }

.Categories {
  display: flex;
  flex-wrap: wrap;
  margin-top: -0.625rem;
  margin-left: -0.625rem; }
  .Categories > .Category {
    margin-top: 0.625rem;
    margin-left: 0.625rem; }

.Category {
  background-color: #f9f9f9;
  border: 1px solid rgba(177, 177, 176, 0.2);
  border-radius: 0.9375rem;
  color: rgba(49, 49, 49, 0.6);
  display: block;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }
  .Category, .Category:active, .Category:focus, .Category:hover {
    text-decoration: none; }
  .Category.is-active {
    background-color: #C4281A;
    color: #ffffff; }

.Intro-body + .Filter {
  margin-top: 1.875rem; }

.Jobs-title {
  font-size: inherit;
  font-weight: 700;
  margin-bottom: 0;
  color: #C4281A;
  font-size: 1rem;
  line-height: 1.75rem; }

.Jobs-item {
  border-top: 1px solid rgba(177, 177, 176, 0.4);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 1.25rem;
  line-height: 1.4; }
  .Jobs-item .Nav-icon {
    color: #C4281A; }
  .Jobs-item:last-child {
    border-bottom: 1px solid rgba(177, 177, 176, 0.4); }
  .Jobs-item, .Jobs-item:active, .Jobs-item:focus, .Jobs-item:hover {
    text-decoration: none; }

.Jobs-items {
  margin-top: 1.25rem;
  margin-bottom: 3rem; }

.Block--testimonials .Slider-buttons {
  display: flex;
  align-items: center;
  margin-top: 1.25rem; }
  @media screen and (min-width: 48em) {
    .Block--testimonials .Slider-buttons {
      position: absolute;
      bottom: 3.75rem;
      left: 0;
      margin-bottom: 1.25rem;
      justify-content: center;
      width: 8.125rem; } }

.Block--testimonials .Slider-button {
  transition-property: background-color, border-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  height: 2.5rem;
  width: 2.5rem;
  border: 1px solid #b1b1b0;
  border-radius: 100%;
  color: #313131;
  filter: none;
  position: static;
  transform: none; }
  .Block--testimonials .Slider-button:not(.is-disabled):hover {
    background-color: #C4281A;
    border-color: #C4281A;
    color: #ffffff; }
  .Block--testimonials .Slider-button + .Slider-button {
    margin-left: 0.5rem; }
  .Block--testimonials .Slider-button--prev {
    transform: scaleX(-1); }

@media screen and (min-width: 48em) {
  .Testimonial {
    display: flex;
    align-items: center;
    margin-left: -3.75rem; }
    .Testimonial-body, .Testimonial-object {
      margin-left: 3.75rem; } }

.Testimonial-title {
  color: #313131; }

.Testimonial-body {
  color: rgba(49, 49, 49, 0.6);
  flex-grow: 1;
  font-weight: 100;
  margin-top: 3.75rem;
  max-width: 30.625rem;
  line-height: 1.4; }
  @media screen and (max-width: 47.9375em) {
    .Testimonial-body {
      margin-top: 2.5rem; } }

.Testimonial-meta {
  color: #313131;
  font-weight: 400; }

.Testimonial-function {
  color: #C4281A;
  display: block; }

.Testimonial-object {
  display: inline-grid;
  grid-template-columns: 3.75rem 1fr 3.75rem;
  grid-template-rows: 2.5rem 1fr 2.5rem;
  max-width: 29.8125rem;
  width: 100%; }
  @media screen and (min-width: 48em) {
    .Testimonial-object {
      display: inline-grid;
      grid-template-columns: 8.125rem 1fr 8.125rem;
      grid-template-rows: 3.75rem 1fr 3.75rem; } }
  .Testimonial-object::after {
    position: relative;
    z-index: -1;
    grid-column: 2/4;
    grid-row: 1/3;
    height: 100%;
    width: 100%;
    background-color: #f9f9f9;
    content: '';
    display: block; }
  .Testimonial-object::after {
    grid-column: 1/3;
    grid-row: 1/3; }

.Testimonial-image {
  grid-column: 2/4;
  grid-row: 2/4; }

.Members {
  display: flex;
  flex-wrap: wrap;
  margin-top: -0.625rem;
  margin-left: -1.25rem; }
  .Members--center,
  .Members--center > .Member {
    justify-content: center; }
  .Members-title {
    text-transform: none; }

.Member {
  font-size: 75%;
  display: flex;
  align-items: center;
  padding-top: 0.625rem;
  padding-left: 1.25rem;
  text-align: left; }
  .Member-icon {
    flex-shrink: 0;
    margin-right: 0.625rem; }
    .Members > .Member > .Member-icon {
      color: #b1b1b0; }

.Contact-body {
  align-self: center;
  background-color: #ffffff;
  border: 1px solid rgba(177, 177, 176, 0.2);
  display: flex;
  flex-direction: column;
  padding: 1.875rem; }
  .Contact-body .Nav--socialMedia {
    align-self: flex-end;
    margin-top: auto; }

@media screen and (min-width: 48em) {
  .Contact {
    display: grid;
    grid-template-columns: calc(65% - 10.625rem) 10.625rem 1fr;
    grid-template-rows: 1fr; }
    .Contact-object {
      grid-column: 1/3;
      grid-row: 1/2; }
    .Contact-body {
      position: relative;
      z-index: 1;
      grid-column: 2/4;
      grid-row: 1/2;
      padding: 3.75rem 1.875rem 1.875rem 5rem;
      min-height: 23.75rem;
      max-width: 31.875rem;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem; } }

.Contact-link {
  margin-top: 1.875rem; }

.TextColumns {
  column-count: 2;
  column-width: 18.75rem;
  column-rule: 1px solid rgba(177, 177, 176, 0.2);
  column-gap: 5rem; }
  .TextColumns .TextColumn {
    break-inside: avoid; }
    .TextColumns .TextColumn-title {
      color: #C4281A; }

@media screen and (min-width: 48em) {
  .NumbersInfo {
    display: flex;
    align-items: center;
    margin-left: -5rem; }
    .NumbersInfo-body, .NumbersInfo-object {
      padding-left: 5rem; } }

.NumbersInfo-title {
  color: #313131; }

.NumbersInfo + .Numbers {
  margin-top: 2.5rem; }
  @media screen and (min-width: 48em) {
    .NumbersInfo + .Numbers {
      margin-top: 5rem; } }

.Numbers {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1px;
  margin-left: -1px; }
  .Numbers > .Number {
    border: 1px solid rgba(177, 177, 176, 0.2);
    padding: 1.875rem;
    flex-shrink: 0;
    margin-top: -1px;
    margin-left: -1px;
    width: 100%; }
    @media screen and (min-width: 37.5em) and (max-width: 63.9375em) {
      .Numbers > .Number {
        width: 50%; } }
    @media screen and (min-width: 64em) {
      .Numbers > .Number {
        width: 25%; } }

.Number {
  text-align: center; }
  .Number-object, .Number-value {
    color: #C4281A; }
  .Number-label {
    color: #b1b1b0; }

.Block--team .Filters {
  display: flex;
  gap: 0.625rem;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1.875rem;
  flex-wrap: wrap; }
  .Block--team .Filters-title {
    margin-top: 0.125rem;
    margin-bottom: 0;
    margin-right: 0.3125rem;
    color: #414042;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 1.5px;
    text-transform: uppercase; }

.Team-department + .Team-department {
  margin-top: 3.75rem; }

.Team-department-title {
  color: #C4281A;
  margin-bottom: 1.375rem;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 1.5px; }

.Team .Name {
  margin-top: 0.875rem;
  display: block;
  font-weight: 500;
  font-size: 0.875rem;
  color: #414042;
  line-height: normal; }

.Team .Function {
  margin-top: 0rem;
  display: block;
  font-size: 0.875rem;
  opacity: 0.6;
  color: #414042;
  line-height: normal; }

.TeamMember {
  position: relative;
  z-index: 0;
  background-color: #ffffff;
  overflow: hidden;
  width: 100%; }
  .TeamMember:hover .TeamMember-body {
    transform: translateY(0); }
  .TeamMember-body {
    transition-property: transform;
    transition-duration: 0.275s;
    transition-timing-function: ease;
    background-color: #ffffff;
    border-bottom: 4px solid #C4281A;
    display: flex;
    flex-direction: column;
    row-gap: 0.3125rem;
    padding: 0.5rem 0rem;
    font-size: 0.75rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(100%); }
  .TeamMember-innerBody {
    margin-top: auto;
    margin-bottom: auto;
    padding: 1.25rem;
    text-align: center; }
  .TeamMember-item {
    color: #313131;
    display: flex;
    align-items: center;
    padding: 0rem; }
    .TeamMember-item, .TeamMember-item:active, .TeamMember-item:focus, .TeamMember-item:hover {
      color: currentColor;
      text-decoration: none; }
    .TeamMember-item:hover {
      color: #C4281A; }
  .TeamMember-icon {
    color: #b1b1b0;
    flex-shrink: 0;
    margin-right: 0.625rem; }
  .TeamMember-image {
    width: 100%;
    margin-right: auto;
    margin-left: auto; }
  .TeamMember-title {
    position: absolute;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
    z-index: 1;
    background-image: linear-gradient(to bottom, rgba(49, 49, 49, 0), rgba(49, 49, 49, 0.6));
    color: #ffffff;
    height: 3.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    text-transform: none;
    padding: 0.625rem; }

@media screen and (min-width: 48em) {
  .ProjectsPage .Block + .Block--slider.Block, .ProjectsPage
  .Block--slider.Block + .Block {
    margin-top: 5rem; } }

.Block--slider + p {
  margin-top: 2rem; }

.Block--slider .SliderWrapper {
  position: relative;
  padding-right: 1.25rem;
  padding-left: 1.25rem; }

.Block--slider .Slider-item {
  position: relative; }
  @media screen and (min-width: 48em) {
    .Block--slider .Slider-item {
      width: auto; } }
  @media screen and (max-width: 47.9375em) {
    .Block--slider .Slider-item .Slider-image {
      width: 100%; } }

.Block--slider .Slider-button {
  position: absolute;
  top: 50%;
  z-index: 1;
  transition-property: background-color, border-color, color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  height: 2.5rem;
  width: 2.5rem;
  background-color: #ffffff;
  border: 1px solid #b1b1b0;
  border-radius: 100%;
  color: #313131;
  filter: none;
  margin-top: -1.25rem;
  transform: none; }
  .Block--slider .Slider-button:not(.is-disabled):hover {
    background-color: #C4281A;
    border-color: #C4281A;
    color: #ffffff; }
  .Block--slider .Slider-button + .Slider-button {
    margin-left: 0.5rem; }
  .Block--slider .Slider-button--prev {
    transform: scaleX(-1);
    left: 0; }
  .Block--slider .Slider-button--next {
    right: 0; }

.Block--slider .Slider-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1; }

.Accordion {
  margin-top: 3.125rem; }
  .Accordion-item {
    border-top: 0.0625rem solid #D9D9D9; }
    .Accordion-item.is-active {
      margin-bottom: 1.25rem; }
      .Accordion-item.is-active .Accordion-icon {
        transform: rotate(-90deg); }
  .Accordion-title {
    margin-bottom: 0; }
  .Accordion-button {
    padding: 0.6875rem 0.3125rem 0.6875rem 0rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between; }
  .Accordion-icon {
    transition: all 0.5s ease;
    margin-top: 0.125rem;
    transform: rotate(90deg); }
  .Accordion-body {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease; }

.Block--cta-team .CtaTeam {
  display: flex;
  gap: 4.375rem;
  padding: 0rem 2.5rem; }
  @media screen and (max-width: 47.9375em) {
    .Block--cta-team .CtaTeam {
      flex-direction: column;
      gap: 1.875rem;
      padding: 0rem; } }
  .Block--cta-team .CtaTeam .Member-object {
    max-width: 21.25rem;
    width: 100%; }
    @media screen and (max-width: 47.9375em) {
      .Block--cta-team .CtaTeam .Member-object {
        max-width: 100%; } }
  .Block--cta-team .CtaTeam .Content {
    justify-content: center;
    display: flex;
    flex-direction: column; }
    .Block--cta-team .CtaTeam .Content * {
      margin-bottom: 0; }
    .Block--cta-team .CtaTeam .Content .Subtitle {
      color: #313131;
      margin-top: 1.875rem; }
      @media screen and (max-width: 47.9375em) {
        .Block--cta-team .CtaTeam .Content .Subtitle {
          margin-top: 1.25rem; } }
    .Block--cta-team .CtaTeam .Content .Function {
      font-size: 1rem;
      color: #C4281A;
      margin-top: 0.3125rem; }
    .Block--cta-team .CtaTeam .Content .Contact-links {
      display: flex;
      flex-direction: column;
      margin-top: 2.1875rem;
      gap: 0.3125rem; }
      @media screen and (max-width: 47.9375em) {
        .Block--cta-team .CtaTeam .Content .Contact-links {
          margin-top: 1.25rem; } }
      .Block--cta-team .CtaTeam .Content .Contact-links .Contact-link {
        margin-top: 0;
        align-items: center;
        display: flex;
        gap: 0.625rem;
        color: #414042;
        text-decoration: none;
        opacity: 0.6;
        transition: all 0.5s ease; }
        .Block--cta-team .CtaTeam .Content .Contact-links .Contact-link:hover {
          opacity: 1; }

/* Components: Search */
@media screen and (min-width: 64em) {
  .Search {
    align-self: center; } }

@media screen and (max-width: 63.9375em) {
  .Search {
    margin-top: auto;
    padding-top: 2.5rem; } }

.Search-icon {
  transition-property: color;
  transition-duration: 200ms;
  transition-timing-function: linear;
  cursor: pointer;
  color: currentColor;
  display: block; }
  .Search-icon:active, .Search-icon:focus, .Search-icon:hover {
    color: #C4281A; }

.Search-object {
  margin-right: 2.5rem; }

.Search-body {
  color: #313131; }
  @media screen and (min-width: 64em) {
    .Search-body {
      transition-property: opacity, visibility;
      transition-duration: 200ms;
      transition-timing-function: linear;
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      background-color: #f9f9f9;
      border-top: 1px solid rgba(177, 177, 176, 0.2);
      border-bottom: 1px solid rgba(177, 177, 176, 0.2);
      padding-top: 1.25rem;
      padding-bottom: 1.25rem; }
      html:not(.show-search) .Search-body {
        opacity: 0;
        visibility: hidden; }
      .Search-body .Container {
        display: flex;
        align-items: center; } }
  @media screen and (max-width: 63.9375em) {
    .Search-body .Container {
      padding-right: 0;
      padding-left: 0; } }
  .Search-body .Form {
    display: flex; }
    @media screen and (max-width: 63.9375em) {
      .Search-body .Form {
        max-width: 25rem;
        margin-right: auto;
        margin-left: auto; } }

.Search-field {
  background-color: transparent;
  border: none;
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 0%;
  width: 0; }
  @media screen and (min-width: 64em) {
    .Search-field {
      margin-right: 1.25rem; } }
  @media screen and (max-width: 63.9375em) {
    .Search-field {
      background-color: #f9f9f9;
      border: 1px solid rgba(177, 177, 176, 0.2);
      padding: 0.625rem 1.25rem; } }
  .Search-field.Label {
    color: currentColor;
    margin-bottom: 0; }
  .Search-field::placeholder {
    color: rgba(0, 0, 0, 0.4); }
  html:not(.is-tabbing) .Search-field:focus {
    outline: 0; }

.Search-button.Button:not(.Button--bare) {
  margin-bottom: 0; }
  @media screen and (max-width: 63.9375em) {
    .Search-button.Button:not(.Button--bare) {
      box-shadow: none;
      min-width: 0; } }

@media screen and (min-width: 64em) {
  .Search-field,
  .Search .Form--search {
    flex-grow: 1; } }

.Search .autocomplete-suggestions {
  background-color: #ffffff;
  border: 1px solid rgba(177, 177, 176, 0.2);
  cursor: default;
  max-height: 320px;
  overflow: auto;
  position: absolute;
  top: 100%;
  width: 722px;
  z-index: 9999; }

.Search .autocomplete-suggestion {
  cursor: pointer;
  overflow: hidden;
  padding: 0.3125rem;
  white-space: nowrap; }
  .Search .autocomplete-suggestion a {
    text-decoration: none; }

.Search .autocomplete-selected {
  background-color: #f9f9f9; }

.Search .autocomplete-suggestions strong {
  font-weight: bold; }

.Search-results .Block-item + .Block-item {
  margin-top: 0.625rem; }

.Tip {
  background-color: #C4281A;
  border-radius: 1.25rem;
  color: #ffffff;
  display: flex;
  font-size: 0.875rem;
  font-weight: 700;
  padding: 0.375rem 1.25rem;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  clear: left;
  float: left;
  margin-top: 0.3125rem;
  margin-right: 1.25rem;
  margin-bottom: 0.3125rem; }
  .Tip .Tip-icon {
    display: block;
    margin-right: 0.625rem;
    flex-shrink: 0; }

/* Footer */
.Main.without-footer {
  margin-bottom: 3.75rem; }
  @media screen and (min-width: 48em) {
    .Main.without-footer {
      margin-bottom: 7.5rem; } }

.Footer {
  background-color: #f9f9f9;
  color: rgba(49, 49, 49, 0.6);
  margin-top: 3.75rem; }
  @media screen and (min-width: 48em) {
    .Footer {
      margin-top: 7.5rem; } }
  .Footer-top {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
    margin-top: -2.5rem;
    margin-left: -1.25rem; }
    @media screen and (min-width: 48em) {
      .Footer-top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 5rem;
        padding-bottom: 5rem; } }
    @supports (grid-area: auto) {
      @media screen and (min-width: 48em) and (max-width: 63.9375em) {
        .Footer-top {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          grid-template-rows: repeat(3, auto); } }
      @media screen and (min-width: 64em) {
        .Footer-top {
          display: grid;
          grid-template-columns: repeat(3, minmax(min-content, 22.5rem));
          grid-template-rows: repeat(3, auto); } } }
    .Footer-top .Logo,
    .Footer-top p {
      margin-bottom: 1.875rem; }
  .Footer-item {
    padding-top: 2.5rem;
    padding-left: 1.25rem; }
    @media screen and (min-width: 48em) {
      .Footer-item {
        max-width: 22.5rem;
        width: 100%; }
        @supports (grid-area: auto) {
          .Footer-item {
            max-width: none;
            width: auto; } }
        .Footer-item--certificats {
          max-width: 43.75rem;
          margin-left: auto; }
          @supports (grid-area: auto) {
            .Footer-item--certificats {
              max-width: none;
              margin-left: 0; } } }
    @media screen and (min-width: 48em) and (max-width: 63.9375em) {
      .Footer-item:nth-child(1) {
        grid-column: 1/5;
        grid-row: 1/2; }
      .Footer-item:nth-child(2) {
        grid-column: 1/4;
        grid-row: 2/3; }
      .Footer-item:nth-child(3) {
        grid-column: 4/8;
        grid-row: 2/3; }
      .Footer-item--certificats {
        grid-column: 1/8;
        grid-row: 3/4;
        margin-top: 2.8125rem; } }
    @media screen and (min-width: 64em) {
      .Footer-item:nth-child(1) {
        grid-column: 1/2;
        grid-row: 1/2; }
      .Footer-item:nth-child(2) {
        grid-column: 2/3;
        grid-row: 1/2; }
      .Footer-item:nth-child(3) {
        grid-column: 3/4;
        grid-row: 1/2; }
      .Footer-item--certificats {
        grid-column: 2/4;
        grid-row: 2/3;
        margin-top: 2.8125rem; } }
  .Footer-title {
    border-bottom: 1px solid rgba(49, 49, 49, 0.1);
    color: #313131;
    padding-bottom: 1.25rem;
    margin-bottom: 1.25rem;
    line-height: 1.5; }
  .Footer-bottom {
    border-top: 1px solid rgba(49, 49, 49, 0.1);
    font-size: 75%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }

/* DigitalPulse */
.DigitalPulse {
  color: currentColor;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  margin-left: auto;
  height: 1.5em; }
  .DigitalPulse-icon, .DigitalPulse-body {
    display: block;
    fill: currentColor; }
  .DigitalPulse-label {
    margin-right: 0.5rem;
    flex-shrink: 0; }
  .DigitalPulse-object {
    background-color: #f9f9f9;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
  .DigitalPulse-icon {
    flex-shrink: 0;
    height: 1.85743em;
    transform: translateY(5%);
    width: 1.5em; }
  @media screen and (min-width: 64em) {
    .DigitalPulse-object {
      transition-property: transform, right;
      transition-duration: 300ms;
      transition-timing-function: ease-out;
      min-width: 5.56167em;
      position: relative;
      z-index: 1;
      position: absolute;
      top: 50%;
      right: 0;
      transform: scale(1.01) translateY(-50%); }
    .DigitalPulse:hover .DigitalPulse-object {
      color: currentColor;
      right: 100%; }
    .DigitalPulse-body {
      transition-property: width;
      transition-duration: 300ms;
      transition-timing-function: ease-out;
      margin-left: .3em;
      transform: translateY(12.5%);
      display: flex;
      justify-content: flex-end;
      height: 0.94828em;
      width: 5.56167em; } }

/* Print */
@media print {
  *,
  *::before,
  *::after,
  *::first-letter,
  p::first-line,
  div::first-line,
  blockquote::first-line,
  li::first-line {
    background: transparent !important;
    box-shadow: none !important;
    color: #000000 !important;
    letter-spacing: 0;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]::after {
    content: " (" attr(href) ")"; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: ""; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #9e9e9e;
    page-break-inside: avoid; }
  /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  /**
     * General
     */
  html {
    font-family: sans-serif;
    font-size: 9pt; }
  .Container {
    max-width: none; }
  h1,
  h2,
  h3 {
    margin-bottom: 9pt;
    text-transform: none; }
  h1 {
    font-size: 18pt; }
  h2 {
    font-size: 12pt; }
  h3 {
    font-size: 10pt; }
  /**
     * Custom
     */
  .Header,
  .Footer,
  .Shape,
  .Nav--breadcrumbs,
  .AddToCart,
  .Info-object > .Thumbnails {
    display: none; }
  .Container {
    max-width: none;
    padding-right: 0;
    padding-left: 0; }
  .Main {
    padding-top: 0; }
  .Slider-item {
    width: 100% !important; }
    .Slider-item:not(:only-child):not(.is-active) {
      display: none; }
  .Block-more {
    display: none; }
  .Block--intro + .Block {
    margin-top: 0; }
  .Main > .Block:first-child {
    margin-top: 0; }
  .Info {
    display: flex; }
    .Info-object {
      width: 33.33333%; }
    .Info-body {
      width: 66.66667%; }
  .ProductsPage .Intro-title {
    margin-bottom: 0; }
  .Intro-header {
    margin-bottom: 24pt; }
  .Info-object > .Slider {
    border: 0; }
  .Block-item {
    border: 0;
    padding: 0; }
    .Block-item.Products td:last-child,
    .Block-item.Products th:last-child {
      display: none; }
    .Block-item.Products .Products-link::after {
      content: none; }
  .ProductInfo-item {
    padding: 0;
    padding-bottom: 3pt;
    margin-bottom: 3pt; }
  .ProductInfo-label {
    font-weight: 700; } }
