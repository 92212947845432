.Tooltip {
    @include transition(#{opacity, visibility});
    @include position(fixed, $z: 5);

    @include mq($from: $viewport--lg) {
        position: absolute;
        transform: translateY(#{u($spacing-unit * -0.75)}) translateY(#{u(-6)});
        max-width: u(640);
        width: 50vw;
    }

    &.Block-item {
        padding: u($spacing-unit);

        @include mq($from: $viewport--md) {
            padding: u($spacing-unit * 1.5, $spacing-unit--lg);
        }
    }

    @include mq($from: $viewport--lg) {

        &--right {
            margin-right: u($spacing-unit * 0.75);

            .Caret {
                @include caret(15, 1, right, rgba($cc-grey, 0.2), $cc-grey--light);
                @include position(absolute, $top: u($spacing-unit), $left: 100%, $z: -1);
            }
        }

        &--left {
            margin-left: u($spacing-unit * 0.75);

            .Caret {
                @include caret(15, 1, left, rgba($cc-grey, 0.2), $cc-grey--light);
                @include position(absolute, $top: u($spacing-unit), $right: 100%, $z: -1);
            }
        }
    }

    &-body {

        @include mq($until: $viewport--md - 1) {
            font-size: 75%;
        }

        > :last-child {
            margin-bottom: 0;
        }
    }

    &:not(.is-active) {
        opacity: 0;
        visibility: hidden;
    }
}
