/* Button */

.Button {

    &.is-sticky {
        position: fixed;
        bottom: u(60);
        right: calc((100vw - #{$structure-width}px) / 2);
        z-index: 5;

        @include mq($until: $viewport--xl) {
            right: u(40);
        }
    }

    &:not(.Button--bare) {
        @include transition(#{background-color, box-shadow, transform});
        font-weight: 500;
        letter-spacing: px(0.4);
        line-height: 1.5;
        margin-bottom: u(6);
        max-width: 100%;
        min-width: u(234);

        &:active,
        &.is-active {
            transform: translate(#{u(6), u(6)});
        }
    }

    &--filter {
        padding: u(3, 14, 2);
        border-radius: 15px;
        border: 1px solid rgba(177, 177, 176, 0.20);
        background: $cc-grey--light;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        transition: all 0.5s ease;
        cursor: pointer;
        min-width: auto !important;

        &:hover,
        &.is-active {
            color: $white;
            background-color: $cc-red;
            border-color: $cc-red;
            transform: none !important;
        }
    }

    // Primary
    &--primary {
        box-shadow: u(6, 6) rgba($cc-red, .16);
        background-color: $cc-red;
        color: $white;

        &:active,
        &.is-active {
            box-shadow: 0 0 rgba($cc-red--dark, .16);
        }

        &:active,
        &:focus,
        &:hover,
        &.is-active,
        &.is-focused,
        &.is-hovered {
            background-color: $cc-red--dark;
            color: $white;
        }
    }

    // Secondary
    &--secondary {
        box-shadow: u(6, 6) rgba($cc-black, .16);
        background-color: $cc-black;
        color: $white;

        &:active,
        &.is-active {
            box-shadow: 0 0 rgba($cc-black, .16);
        }

        &:active,
        &:focus,
        &:hover,
        &.is-active,
        &.is-focused,
        &.is-hovered {
            background-color: $black;
            color: $white;
        }
    }

    // Info
    &--info {
        background-color: $blue;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $blue--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $blue;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $blue--700;
            }
        }
    }

    // Success
    &--success {
        background-color: $green;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $green--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $green;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $green--700;
            }
        }
    }

    // Warning
    &--warning {
        background-color: $orange;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $orange--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $orange;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $orange--700;
            }
        }
    }

    // Danger
    &--danger {
        background-color: $red;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $red--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $red;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $red--700;
            }
        }
    }

    // Outline
    &--outline {
        background-color: transparent;
        border-color: currentColor;
    }

    // Large
    &--large {
        @include font-size($button-font-size * 1.25, false);
        padding-right: u($button-padding--r * 2);
        padding-left: u($button-padding--l * 2);
    }

    // Small
    &--small {
        @include font-size($button-font-size * .75, false);
        padding-right: u($button-padding--r / 1.5);
        padding-left: u($button-padding--l / 1.5);
    }

    // Full
    &--full {
        display: block;
        width: 100%;
    }

    // Bare
    &--bare {
        border: 0;
        border-radius: 0;
        padding: 0;
        text-align: left;
        line-height: 1.4;
        font-weight: 600;

        // color: #313131;

        .Teaser &,
        .Bar & {
            font-weight: 800;
        }

        .Banner & {
            font-weight: 750;
            font-size: 18px;
            line-height: 26px;

            .Button-icon {
                color: $cc-red;
            }

            + .Button--bare {
                margin-top: u(10);
            }

            &:hover {
                color: $cc-red;
            }
        }

        .CookieConsent & {

            &:hover {
                text-decoration: underline;
            }
        }

        .Button-icon {
            flex-shrink: 0;
            margin-left: u($spacing-unit--sm);
        }
    }
}
