/* Components: Search */
.Search {

    @include mq($from: $viewport--lg) {
        align-self: center;
    }

    @include mq($until: $viewport--lg - 1) {
        margin-top: auto;
        padding-top: u($spacing-unit--lg);
    }

    &-icon {
        @include transition(color);
        cursor: pointer;
        color: currentColor;
        display: block;

        &:active,
        &:focus,
        &:hover {
            color: $cc-red;
        }
    }

    &-object {
        margin-right: u($spacing-unit--lg);
    }

    &-body {
        color: $cc-black;

        @include mq($from: $viewport--lg) {
            @include transition(#{opacity, visibility});
            @include position(absolute, $top: 100%, $right: 0, $left: 0);
            background-color: $cc-grey--light;
            border-top: px(1) solid rgba($cc-grey, 0.2);
            border-bottom: px(1) solid rgba($cc-grey, 0.2);
            padding-top: u($spacing-unit);
            padding-bottom: u($spacing-unit);

            html:not(.show-search) & {
                opacity: 0;
                visibility: hidden;
            }

            .Container {
                display: flex;
                align-items: center;
            }
        }

        @include mq($until: $viewport--lg - 1) {

            .Container {
                padding-right: 0;
                padding-left: 0;
            }
        }

        .Form {
            display: flex;

            @include mq($until: $viewport--lg - 1) {
                max-width: u(400);
                margin-right: auto;
                margin-left: auto;
            }
        }
    }

    &-field {
        background-color: transparent;
        border: none;
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: 0%;
        width: 0;

        @include mq($from: $viewport--lg) {
            margin-right: u($spacing-unit);
        }

        @include mq($until: $viewport--lg - 1) {
            background-color: $cc-grey--light;
            border: px(1) solid rgba($cc-grey, 0.2);
            padding: u($spacing-unit--sm, $spacing-unit);
        }

        &.Label {
            color: currentColor;
            margin-bottom: 0;
        }

        &::placeholder {
            color: rgba($black, 0.4);
        }

        &:focus {

            html:not(.is-tabbing) & {
                outline: 0;
            }
        }
    }

    &-button {

        &.Button:not(.Button--bare) {
            margin-bottom: 0;

            @include mq($until: $viewport--lg - 1) {
                box-shadow: none;
                min-width: 0;
            }
        }
    }

    &-field,
    .Form--search {

        @include mq($from: $viewport--lg) {
            flex-grow: 1;
        }
    }

    // Autocomplete
    .autocomplete-suggestions {
        background-color: $white;
        border: px(1) solid rgba($cc-grey, 0.2);
        cursor: default;
        max-height: 320px;
        overflow: auto;
        position: absolute;
        top: 100%;
        width: 722px;
        z-index: 9999;
    }

    .autocomplete-suggestion {
        cursor: pointer;
        overflow: hidden;
        padding: u($spacing-unit--xs);
        white-space: nowrap;

        a {
            text-decoration: none;
        }
    }

    .autocomplete-selected {
        background-color: $cc-grey--light;
    }

    .autocomplete-suggestions strong {
        font-weight: bold;
    }

    // Results
    &-results {

        .Block-item + .Block-item {
            margin-top: u($spacing-unit--sm);
        }
    }
}
