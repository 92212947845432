.Nav--footer {
    line-height: 1.88;

    .Nav-item {
        display: flex;

        &::before {
            content: '•';
            flex-shrink: 0;
            margin-right: u($spacing-unit);
        }
    }

    .Nav-link {
        color: currentColor;

        &:active,
        &:focus,
        &:hover,
        &.is-active {
            color: $cc-red;
        }
    }
}
