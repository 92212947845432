.Block--intro {

    + .Block {
        margin-top: u($spacing-unit * 3);
    }
}

.Intro {
    color: $cc-grey--dark;
    
    &-header {
        margin-bottom: u($spacing-unit);

        .Nav--breadcrumbs {
            padding-left: u(0);
        }
    }

    &-title {
        margin-bottom: u(10);
        color: $cc-red--dark;

        &:not(.has-shape) {
            text-align: center;
            max-width: u(880);

            &,
            .Shape {
                margin-right: auto;
                margin-left: auto;
            }

            .Shape {
                margin-bottom: u($spacing-unit);
            }
        }

        .ProductsPage & {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            small {
                font-size: (100% / 34 * 20);
                font-weight: 100;
                line-height: 1.4;
                transform: translateY(10%);
            }
        }
    }

    &-body {
        max-width: u(620);
    }

    &-text {

        &.is-collapsed {
            display: none;
        }
    }
}
