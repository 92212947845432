/* Colors: Custom */

// Grey
$cc-grey:                           #b1b1b0 !default;
$cc-grey--light:                    #f9f9f9 !default;
$cc-grey--dark:                     #767676 !default;
$cc-grey--darker:                   #414042 !default;
$cc-grey--light-50:                 #D9D9D9 !default;
$cc-grey--hover:                    scale-color($cc-grey, $lightness: -30%);

// Black
$cc-black:                          #313131 !default;

// Red
$cc-red:                            #C4281A !default;
$cc-red--dark:                      #9d1d06 !default;
