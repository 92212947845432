.Block--content {

    &:first-child {
        margin-top: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit--xl);
        }
    }

    + .Block--apply {

        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit * 3);
        }
    }

    + .Block--content {
        margin-top: 0;
    }

    + .Block--columns {
        margin-top: u($spacing-unit * 3);
    }
}

.Content {

    p, ul, ol {
        max-width: u(620);
    }
    
    &-header {
        margin-bottom: u($spacing-unit--lg);
    }

    &-title {
        margin-bottom: u($spacing-unit--sm);
    }

    &-body {

        p:not(:last-child) > .Button {
            margin-bottom: u($spacing-unit--lg);
        }

        > :last-child {
            margin-bottom: 0;
        }
    }

    &-innerBody {

        &:not(:first-child) {
            margin-top: u($spacing-unit--lg);
        }

        &:not(.has-columns) {

            .Field-title {

                &[data-counter]::before {
                    content: attr(data-counter) '. ';
                }
            }
        }

        &.has-columns {
            display: grid;
            gap: u(40);
            grid-template-columns: repeat(3, 1fr);

            @include mq($until: $viewport--lg) {
                grid-template-columns: repeat(1, 1fr);
            }

            .Field-title {
                margin-top: 0;
                
                &::before {
                    display: none;
                }
            }
        }

        &:last-child > :last-child {
            margin-bottom: 0;
        }

        p, ul, ol {
            max-width: u(700);
        }
        
    }

    &-innerItem {
        break-inside: avoid;

        :last-child {
            margin-bottom: 0;
        }
    }

    &-footer {
        color: $cc-red;
        margin-top: u($spacing-unit);
    }

    &-columns {
        position: relative;
        margin-top: u($spacing-unit * 3);
        margin-bottom: u($spacing-unit * 3);
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -1000%;
            width: 1000%;
            height: 100%;
            background-color: inherit;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: -1000%;
            width: 1000%;
            height: 100%;
            background-color: inherit;
        }
    }

    &-textColumns {
        margin-top: u($spacing-unit * 3);
        margin-bottom: u($spacing-unit * 3);
    }

    .Field-title {
        margin-bottom: u($spacing-unit * 1.5);
        margin-top: u($spacing-unit--lg);

        &.Field-title--h2 {
            @include mq($from: $viewport--md) {

                position: relative;
    
                &::before {
                    @include position(absolute, $top: 50%, $right: 100%);
                    border-bottom: px(1) solid;
                    content: '';
                    display: block;
                    width: u($structure-responsive-gutter--l);
                    transform: translateX(-62px);
    
                    @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint--sm - 1) {
                        width: u($structure-gutter--l);
                    }
    
                    @include mq($from: $structure-responsive-breakpoint--sm) {
                        width: calc((100vw - #{u($structure-width--sm)}) / 2);
                    }
                }
            }
        }

        &[data-counter] {

            &::before {
                background-color: transparent;
                border-bottom: none;
                content: attr(data-counter) '. ';
                position: static;
                right: auto;
                top: auto;
                transform: none;
                width: u(30px);
                display: inline-block;
            }
        }
    }

    .Media {
        max-width: u(620);
        margin-bottom: u($spacing-unit--lg);
        width: 100%;

        @include mq($from: $viewport--md) {

            &--left,
            &--right {
                max-width: u(520 + $spacing-unit--lg);
            }

            &--left {
                clear: left;
                float: left;
                padding-right: u($spacing-unit--lg);
            }

            &--right {
                clear: right;
                float: right;
                padding-left: u($spacing-unit--lg);
            }
        }

        @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint - 1) {
            width: 50%;
        }

        @include mq($from: $structure-responsive-breakpoint) {

            &--left {
                //margin-left: u(($structure-width - $structure-width--sm) / -2);
            }

            &--right {
                //margin-right: u(($structure-width - $structure-width--sm) / -2);
            }
        }

        &-caption {
            border-bottom: px(1) solid $cc-grey;
            text-align: center;
            padding-top: u($spacing-unit--sm);
            padding-bottom: u($spacing-unit--sm);
        }

        &-item {
            img {
                width: 100%;
            }
        }
    }
}

.Media {

    &-object--video {

        .Media-link {
            background-color: $cc-black;
        }

        .Media-image {
            opacity: 0.6;
        }
    }

    &-link {
        @include grid(1fr, 1fr);

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
            text-decoration: none;
        }

        .Media-object,
        .Play {
            @include grid-area(1, 2, 1, 2);
        }

        .Play {
            @include position(relative, $z: 1);
            align-self: center;
            justify-self: center;
        }
    }
}

.Tiles {
    clear: both;
    counter-reset: i;

    .Grid--withGutter {
        margin-top: u($spacing-unit--sm * -1);
        margin-left: u($spacing-unit * -1);

        > .Grid-cell {
            padding-top: u($spacing-unit--sm);
            padding-left: u($spacing-unit);
        }
    }

    &.has-columns {

        .Grid--withGutter {
            margin-top: u($spacing-unit * -1);

            > .Grid-cell {
                padding-top: u($spacing-unit);
            }
        }
    }

    &:not(:first-child) {
        margin-top: u($spacing-unit--lg);
    }

    &:not(:last-child) {
        margin-bottom: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-bottom: u($spacing-unit--lg * 1.5);
        }
    }

    .Tile {
        background-color: $cc-grey--light;
        height: 100%;
        padding: u($spacing-unit--lg);

        :last-child {
            margin-bottom: 0;
        }

        &:not(.has-number) {
            text-align: center;

            .Field-title {
                color: currentColor;
            }

            p {
                max-width: u(740);
                margin-right: auto;
                margin-left: auto;
            }

            &.is-grey {
                color: $cc-black;
            }
        }

        &.has-number {
            counter-increment: i;

            .Field-title {
                display: flex;
                align-items: baseline;

                &::before {
                    content: counter(i) ".";
                    display: block;
                    width: u($spacing-unit--lg);
                    text-align: center;
                }
            }

            .Tile-body {
                padding-left: u($spacing-unit--lg);
            }
        }

        &.is-red {
            background-color: $cc-red;
            color: $white;

            .Link--secondary {

                &,
                &:active,
                &:focus,
                &:hover {
                    color: currentColor;
                }
            }
        }
    }
}
