/* Elements: Heading */

// Namespace
$heading-namespace:                   $namespace !default;

// Typography
$heading-font-stack:                  inherit !default;

// Sizes
$heading-size-giga:                   96 !default;
$heading-size-mega:                   72 !default;
$heading-size-kilo:                   48 !default;

$heading-size-1:                      40 !default;
$heading-size-2:                      30 !default;
$heading-size-3:                      20 !default;
$heading-size-4:                      14 !default;
$heading-size-5:                      14 !default;
$heading-size-6:                      14 !default;

$heading-size-milli:                  12 !default;
$heading-size-micro:                  10 !default;

// Spacing
$heading-spacing-giga:                true !default;
$heading-spacing-mega:                true !default;
$heading-spacing-kilo:                true !default;

$heading-spacing-1:                   true !default;
$heading-spacing-2:                   $spacing-unit * 1.5 !default;
$heading-spacing-3:                   true !default;
$heading-spacing-4:                   true !default;
$heading-spacing-5:                   true !default;
$heading-spacing-6:                   true !default;

$heading-spacing-milli:               true !default;
$heading-spacing-micro:               true !default;
