/* Lang */
.Nav--lang {

    @include mq($from: $viewport--lg) {
        border-left: px(1) solid $cc-grey;
        margin-left: u($spacing-unit * 1.5);
        padding-left:  u($spacing-unit * 1.5);
    }
    
    @include mq($until: $viewport--lg - 1) {
        order: 1;
    }

    @include mq($from: $viewport--lg) {
        @include position(relative, $z: 1);

        &:not(:hover) {

            > .Nav-list {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    @include mq($until: $viewport--lg - 1) {
        margin-top: u($spacing-unit);
    }

    // Toggle
    .Nav-toggle {
        cursor: default;
        align-items: center;
        display: flex;
        text-transform: uppercase;
    }

    // List
    .Nav-list {
        gap: u(5);
        
        @include mq($from: $viewport--lg) {
            min-width: u(260);
        }

        // Item
        > .Nav-item + .Nav-item {
            margin-top: .25rem;

            @include mq($until: $viewport--lg - 1) {
                margin-top: 0rem;
            }
        }
    }
    > .Nav-list {

        @include mq($from: $viewport--lg) {
            @include transition(#{opacity, visibility});
            @include position(absolute, $top: 100%, $right: 0);
            background-color: $cc-grey--light;
            border: px(1) solid rgba($cc-grey, 0.2);
            color: rgba($cc-black, 0.6);
            flex-direction: column;
            padding: u($spacing-unit--sm, $spacing-unit);
            margin-top: u($spacing-unit--sm);
            line-height: 1.4125;

            &::before {
                @include dimensions(100%, $spacing-unit--sm);
                @include position(absolute, $bottom: 100%, $right: 0);
                content: '';
                // transform: translateY(-100%);
            }
        }

        @include mq($until: $viewport--lg - 1) {
            justify-content: center;
            text-transform: uppercase;
        }
    }

    // Item
    .Nav-item {

    }

    // Link
    .Nav-link {
        color: currentColor;
        display: flex;
        gap: u(10);
    
        .Flag {
            @include dimensions(24);
            border-radius: 100%;
            overflow: hidden;
            object-fit: cover;
        }

        @include mq($until: $viewport--lg - 1) {
            padding: u($spacing-unit--xs);

            &::before {
                content: attr(lang);
            }
        }

        &.is-active {
            display: flex;
            align-items: center;

            .Nav-icon {
                display: block;
                flex-shrink: 0;
                margin-right: u($spacing-unit / 2.5);
            }
        }

        &:hover,
        &.is-active {
            color: $cc-red;
        }
    }

    .Nav-flag {
        max-width: 1rem;
        margin-bottom: .375rem;

        @include mq($until: $viewport--lg - 1) {
            display: none;
        }
    }
}
