.Block--cta {

    .Block--content + & {
        margin-top: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit--xl);
        }
    }
}

.Cta {
    background-color: $cc-grey--light;
    padding: u($spacing-unit * 3, $spacing-unit);
    text-align: center;

    &-body {
        line-height: 1.5;
        max-width: u(870);
        margin-right: auto;
        margin-left: auto;

        > :last-child {
            margin-bottom: 0;
        }

        p, ul, ol {
            margin-bottom: u($spacing-unit * 1.5);
        }
    }

    &-title {
        display: flex;
        flex-direction: column;
        align-items: center;

        .Shape {
            margin-bottom: u($spacing-unit);
        }
    }
}
