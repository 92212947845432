.Block--testimonials {

    .Slider-buttons {
        display: flex;
        align-items: center;
        margin-top: u($spacing-unit);

        @include mq($from: $viewport--md) {
            @include position(absolute, $bottom: u(60), $left: 0);
            margin-bottom: u($spacing-unit);
            justify-content: center;
            width: u(130);
        }
    }

    .Slider-button {
        @include transition(#{background-color, border-color, color});
        @include dimensions(40);
        border: px(1) solid $cc-grey;
        border-radius: 100%;
        color: $cc-black;
        filter: none;
        position: static;
        transform: none;

        &:not(.is-disabled):hover {
            background-color: $cc-red;
            border-color: $cc-red;
            color: $white;
        }

        + .Slider-button {
            margin-left: u($spacing-unit / 2.5);
        }

        &--prev {
            transform: scaleX(-1);
        }
    }
}

.Testimonial {

    @include mq($from: $viewport--md) {
        display: flex;
        align-items: center;
        margin-left: u($spacing-unit * -3);

        &-body,
        &-object {
            margin-left: u($spacing-unit * 3);
        }
    }

    &-title {
        color: $cc-black;
    }

    &-body {
        color: rgba($cc-black, 0.6);
        flex-grow: 1;
        font-weight: 100;
        margin-top: u($spacing-unit * 3);
        max-width: u(490);
        line-height: 1.4;

        @include mq($until: $viewport--md - 1) {
            margin-top: u($spacing-unit--lg);
        }
    }

    &-meta {
        color: $cc-black;
        font-weight: 400;
    }

    &-function {
        color: $cc-red;
        display: block;
    }

    &-object {
        @include grid(u(60) 1fr u(60), u(40) 1fr u(40), true);
        max-width: u(477);
        width: 100%;

        @include mq($from: $viewport--md) {
            @include grid(u(130) 1fr u(130), u(60) 1fr u(60), true);
        }

        &::after {
            @include position(relative, $z: -1);
            @include grid-area(2, 4, 1, 3);
            @include dimensions(100%);
            background-color: $cc-grey--light;
            content: '';
            display: block;
        }

        &::after {
            @include grid-area(1, 3, 1, 3);
        }
    }

    &-image {
        @include grid-area(2, 4, 2, 4);
    }
}
