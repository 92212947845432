/* Logo */
.Logo {
    flex-shrink: 0;
    margin-right: auto;

    .Header & {
        @include transition(width);
        overflow: hidden;

        @include mq($from: $viewport--md, $until: 1099) {
            width: u(181.54);
        }

        @include mq($from: 1100) {
            width: u(337);
        }
    }

    .Header.is-notTop & {

        @include mq($from: $viewport--md) {
            width: u(181.54);
        }
    }

    &-object {
        @include dimensions(181.54, 57);
        display: block;
        max-width: 100%;
        width: auto;
    }
}
