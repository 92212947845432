.Nav--policies {
    line-height: 1.33;

    .Nav-item {
        display: flex;

        &:not(:first-child) {

            &::before {
                content: '|';
                margin-right: u($spacing-unit--xs);
                margin-left: u($spacing-unit--xs);
            }
        }
    }

    .Nav-link {
        color: currentColor;

        &:active,
        &:focus,
        &:hover {
            color: $cc-red;
        }
    }
}
