/* Navigation */
.Navigation {

    &-toggle {
        display: block;
    }

    &-body {
        display: flex;

        &Inner {
            display: flex;

            @include mq($until: $viewport--lg - 1) {
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            }
        }

        .Nav--group {
            display: none;

            @include mq($until: $viewport--lg - 1) {
                display: block;
            }
        }

        @include mq($from: $viewport--lg) {
            margin-left: u($spacing-unit * -1);

            > .Nav,
            .Search {
                padding-left: u($spacing-unit);
            }
        }

        @include mq($until: $viewport--lg - 1) {
            @include position(fixed, u(180), 0, 0, 0, 1);
            @include transition(#{opacity, visibility});
            background-color: $header-bg-color;
            padding: u($spacing-unit);
            display: block;
            overflow-y: auto;
            overflow-x: hidden;

            .Header.is-notTop.is-pinned & {
                top: u(120);
            }

            html:not(.show-nav) & {
                opacity: 0;
                visibility: hidden;
            }
        }

        @include mq($until: $viewport--md - 1) {
            top: u(120);
        }
    }

    .Caret {
        @include caret(12, 1, bottom, currentColor, $header-bg-color);
        margin-left: u($spacing-unit / 2.5);
    }
}

/* Types */
@import '6-components/navigation/main';
@import '6-components/navigation/lang';
@import '6-components/navigation/breadcrumbs';
@import '6-components/navigation/socialMedia';
@import '6-components/navigation/contact';
@import '6-components/navigation/footer';
@import '6-components/navigation/policies';
@import '6-components/navigation/group';
@import '6-components/navigation/pagination';
