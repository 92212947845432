/* Fields: file */
.#{$form-namespace}Form-field--file {

    .Form-field--label {
        display: none;
    }

    .Form-field-input {
        cursor: pointer;
        flex-grow: 1;

        &::file-selector-button {
            @include transition(background-color);
            appearance: none;
            background-color: $cc-red;
            border: none;
            color: $white;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1;
            letter-spacing: px(0.4);
            padding: .875rem 1.75rem;
            text-align: $button-text-align;
            cursor: pointer;
            margin-right: u($spacing-unit);
            &:hover {
                background-color: $cc-red--dark;
                color: $white;
            }
        }
    }

    // Fixed or fluid width
    @if is-false($form-element-fluid) and not is-null($form-element--default) {
        @include mq($from: $viewport--sm) {
            max-width: u($form-element--default);
        }
    }
}
