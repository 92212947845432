/* Header */
.Header {
    @include position(fixed, $top: 0, $right: 0, $left: 0, $z: 4);
    background-color: $white;
    color: $cc-black;
    line-height: 1.5;

    > .Nav--group {
        @include mq($until: $viewport--lg - 1) {
            display: none;
        }
    }

    &-top {
        background-color: $cc-red;
        color: $white;
        height: u(40);

        > .Container {
            display: flex;
            justify-content: space-between;
        }
    }

    &-group-dropdown {
        align-self: flex-start;
        text-decoration: none;
        letter-spacing: px(-0.49);
        padding: u($spacing-unit / 2.5) u($spacing-unit);
        cursor: pointer;
        background-color: $white;
        color: $cc-red;

        @include mq($until: $viewport--lg - 1) {
            background-color: $cc-grey--light;
            width: calc(100% + #{$spacing-unit--lg}px);
            margin-left: u(-$spacing-unit);
            padding: u($spacing-unit) u($spacing-unit--lg);
            margin-top: u($spacing-unit);
        }

        @include mq($until: $viewport--md) {
            padding: u($spacing-unit) u($spacing-unit);
        }

        &:active,
        &:focus,
        &:hover,
        &.is-active {
            background-color: $cc-grey--light;
        }
    }

    &-link {
        align-self: flex-start;
        text-decoration: none;
        letter-spacing: px(-0.49);
        padding-top: u($spacing-unit / 2.5);
        padding-bottom: u($spacing-unit / 2.5);

        &:hover {
            text-decoration: underline;
        }

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
        }
    }

    &-links {
        display: flex;
        gap: u(20);
        flex-direction: row;
        z-index: 3;

        @include mq($until: $viewport--lg) {
            justify-content: center;
            text-align: center;
            width: 100%
        }
    }

    &-bottom {

        > .Container {
            @include transition(height);
            display: flex;
            align-items: center;
            height: u(80);
            padding-top: u($spacing-unit);
            padding-bottom: u($spacing-unit);
            position: static;

            @include mq($from: $viewport--md) {
                height: u(140);
            }
        }
    }

    // Headroom
    &--sticky {
        @include transition(#{background-color, color, height, transform});
        // will-change: transform;

        &.is-unpinned {
            transform: translateY(-100%);
        }

        &.is-notTop.is-pinned {

            > .Header-bottom > .Container {
                height: u(80);
            }
        }

        &.is-notTop {
            box-shadow: 0 0 1px 1px rgba($cc-grey, 0.2);
        }
    }
}
