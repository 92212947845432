.Block--cta-team {

    .CtaTeam {
        display: flex;
        gap: u(70);
        padding: u(0, 40);

        @include mq($until: $viewport--md - 1) {
            flex-direction: column;
            gap: u(30);
            padding: u(0);
        }

        .Member-object {
            max-width: u(340);
            width: 100%;

            @include mq($until: $viewport--md - 1) {
                max-width: 100%;
            }
    
        }

        .Content {
            justify-content: center;
            display: flex;
            flex-direction: column;

            * {
                margin-bottom: 0;
            }

            .Subtitle {
                color: $cc-black;
                margin-top: u(30);

                @include mq($until: $viewport--md - 1) {
                    margin-top: u(20);
                }
            }

            .Function {
                font-size: u(16);
                color: $cc-red;
                margin-top: u(5);
            }

            .Contact-links {
                display: flex;
                flex-direction: column;
                margin-top: u(35);
                gap: u(5);

                @include mq($until: $viewport--md - 1) {
                    margin-top: u(20);
                }

                .Contact-link {
                    margin-top: 0;
                    align-items: center;
                    display: flex;
                    gap: u(10);
                    color: $cc-grey--darker;
                    text-decoration: none;
                    opacity: 0.6;
                    transition: all 0.5s ease;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}