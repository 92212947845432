.Members {
    display: flex;
    flex-wrap: wrap;
    margin-top: u($spacing-unit--sm * -1);
    margin-left: u(-20);

    &--center {

        &,
        > .Member {
            justify-content: center;
        }
    }

    &-title {
        text-transform: none;
    }
}

.Member {
    font-size: 75%;
    display: flex;
    align-items: center;
    padding-top: u($spacing-unit--sm);
    padding-left: u(20);
    text-align: left;

    &-icon {
        flex-shrink: 0;
        margin-right: u($spacing-unit--sm);

        .Members > .Member > & {
            color: $cc-grey;
        }
    }
}
