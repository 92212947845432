/* Blocks */
.Block, .Anchor {

    + .Block, .Anchor {
        margin-top: u($spacing-unit * 3);

        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit * 6);
        }
    }

    .Main > &:first-child {
        margin-top: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit--xl);
        }
    }

    &.has-color {
        padding-top: u($spacing-unit * 3);
        padding-bottom: u($spacing-unit * 3);

        + .has-color {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: u($spacing-unit * -3);
        }

        @include mq($from: $viewport--md) {
            padding-top: u($spacing-unit * 6);
            padding-bottom: u($spacing-unit * 6);

            &:last-child {
                margin-bottom: u($spacing-unit * -6);
            }
        }
    }

    &.is-grey {
        background-color: $cc-grey--light;
        color: rgba($cc-black, 0.6);
    }

    &-item {
        background-color: $cc-grey--light;
        border: px(1) solid rgba($cc-grey, 0.2);
        padding: u($spacing-unit);

        .has-color & {
            background-color: $white;
        }

        :last-child {
            margin-bottom: 0;
        }

        @include mq($from: $viewport--lg) {
            padding: u($spacing-unit--lg);

            &--sm {
                padding: u($spacing-unit * 1.5);
            }

            &--xs {
                padding: u($spacing-unit--sm, $spacing-unit);
            }
        }

        + .Block-item {
            border-top: none;
        }
    }

    &Item-body {
        max-width: u(540);
        margin-right: auto;
        margin-left: auto;
    }

    &-body {
        max-width: u(640);
    }

    &-more {
        text-align: center;
        margin-top: u($spacing-unit * 3);
    }
}
