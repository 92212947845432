/* Pagination */
.Nav--pagination {
    margin-top: u($spacing-unit--xl);

    .Nav-list {
        justify-content: center;
        margin-top: u($spacing-unit / -2.5);
        margin-left: u($spacing-unit / -2.5);
    }

    .Nav-item {
        margin-top: u($spacing-unit / 2.5);
        margin-left: u($spacing-unit / 2.5);

        &--prev {

            .Nav-icon {
                transform: scaleX(-1);
            }
        }
    }

    .Nav-link {
        @include transition(#{background-color, border-color, color});
        @include dimensions(40);
        border: px(1) solid $cc-grey;
        border-radius: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: static;
        transform: none;

        &,
        &:active,
        &:focus,
        &:hover {
            color: $cc-black;
        }

        &.is-active,
        &:not(.is-disabled):hover {
            background-color: $cc-red;
            border-color: $cc-red;
            color: $white;
        }
    }
}
