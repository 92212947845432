.Products {

    .Info + & {
        margin-top: u($spacing-unit--lg);

        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit--xl);
        }
    }

    .Table {

        @include mq($from: $viewport--md) {
            margin-top: u($table-padding-t * -1);
        }

        &-cell {

            @include mq($from: $viewport--md) {

                &:last-child {
                    width: (100% / 3);
                }
            }
        }
    }

    &-link {
        color: currentColor;

        span {
            @include transition(box-shadow);
            box-shadow: 0 px(1) rgba($border-color, .5);
        }

        .Link-icon {
            margin-right: u($spacing-unit--xs);
        }

        &:hover {
            color: $cc-red;

            span {
                box-shadow: none;
            }
        }

        &,
        &:active,
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }
}

.Table-cell {

    &--large {
        font-weight: 100;
        line-height: 1.45;

        @include mq($from: $viewport--md) {
            font-size: u($font-size * $paragraph-lead-modifier);
        }
    }
}

.Info {
    color: rgba($cc-black, 0.6);

    @include mq($from: $viewport--md) {
        display: flex;
    }

    &-object {

        @include mq($until: $viewport--md - 1) {
            margin-bottom: u($spacing-unit--lg);
        }

        @include mq($from: $viewport--md) {
            flex-shrink: 0;
            margin-right: u($spacing-unit * 3);
            max-width: u(390);
            width: calc(100% / 1040 * 390);
        }

        > .Slider {
            border: px(1) solid rgba($cc-grey, 0.2);
        }

        .Slider-object {
            @include position(relative, $z: 0);
            padding-bottom: (100% / 16 * 15);
            &.is-video {
                opacity: .6;
            }
        }

        .Slider-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%)translateY(-50%);
        }

        .Slider-image {
            @include position(absolute, $top: 50%, $left: 50%, $z: 1);
            transform: translate3d(-50%, -50%, 0);
        }

        > .Thumbnails {
            display: flex;
            flex-wrap: wrap;
            padding-top: u($spacing-unit--sm);
            margin-top: u($spacing-unit--sm * -1);
            margin-left: u($spacing-unit--sm * -1);

            .Thumbnail-item {
                border: px(1) solid rgba($cc-grey, 0.2);
                cursor: pointer;
                max-width: u(90);
                margin-top: u($spacing-unit--sm);
                margin-left: u($spacing-unit--sm);

            }

            .Thumbnail-object {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .Thumbnail-icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%)translateY(-50%);
                }
            }
        }
    }

    &-body {
        flex-grow: 1;
    }

    &-title {
        margin-bottom: u($spacing-unit--sm);
    }
}

.Product {

    &-info {
        margin-top: u($table-padding-t * -1);
    }

    &Info {

        &-item {
            border-bottom: px(1) solid $table-border-color;
            padding: u($table-padding-t, $table-padding-r, $table-padding-b, $table-padding-l);

            &:last-of-type {
                border-bottom: none;
            }

            @include mq($from: $viewport--md) {
                display: flex;
            }
        }

        &-label {
            flex-shrink: 0;
            font-weight: inherit;

            @include mq($from: $viewport--md) {
                width: 30%;
            }
        }

        &-description {
            color: $cc-black;
            flex-grow: 1;
            font-weight: 500;

            @include mq($until: $viewport--md - 1) {
                margin-top: u($spacing-unit--sm);
            }

            @include mq($from: $viewport--md) {
                padding-left: u($spacing-unit);
            }
        }
    }
}

.AddToCart {
    // margin-top: u($spacing-unit * 1.5);
    max-width: u(300);

    &-header {
        margin-bottom: u($spacing-unit);
    }
}

.Downloads {
    display: flex;
    flex-wrap: wrap;
    margin-top: u($spacing-unit * -1);
    margin-left: u($spacing-unit * -1);

    > .Download {
        padding-top: u($spacing-unit);
        padding-left: u($spacing-unit);
        width: 100%;

        @include mq($from: $viewport--lg) {
            width: 50%;
        }
    }
}

.Download {
    display: inline-flex;
    align-items: center;

    &,
    &:active,
    &:focus,
    &:hover {
        color: currentColor;

        .Content-body & {
            color: $cc-black;
        }
    }


    &:hover {
        text-decoration: none;
    }

    &-icon {
        display: block;
        flex-shrink: 0;
        margin-right: u($spacing-unit * 0.75);
        user-select: none;
    }
}
