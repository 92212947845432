.Block--textMedia {

    &.is-jobs {

        .TextMedia {
            margin-left: 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: u(15);

            @include mq($until: $viewport--lg - 1) {
                display: flex;
                flex-direction: column;
            }

            &-object, .Media-object {
                display: block;
                grid-column: 1/3;
                margin-left: 0;

                &::after {
                    display: none;
                }
            }

            &-body {
                background-color: $cc-red;
                color: $white;
                margin-left: 0;
                margin-top: 0;
                grid-column: 3/4;
                padding: u(40);
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: u(24);
                width: 100%;
                max-width: 100%;

                * {
                    margin-bottom: 0;
                }
                
                ul {
                    display: flex;
                    flex-direction: column;
                    gap: u(12);

                    li {
                        &::before {            
                            background-image: svg-url("<svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.74997 9.93241L3.31747 7.49991L2.48914 8.32241L5.74997 11.5832L12.75 4.58324L11.9275 3.76074L5.74997 9.93241Z' fill='white'/></svg>");
                        }
                    }
                }

                p, ul {
                    color: inherit;
                }
            }

            &-title {
                color: $white;
                margin-bottom: 0;
            }
        }
    }

    &.accent-grey {

        .TextMedia-object {

            &::after {
                background-color: $cc-grey--light;
            }
        }
    }

    &.accent-red {
        
        .TextMedia-object {
            
            &::after {
                background-color: $cc-red;
            }
        }
    }
}

.TextMedia {
    display: flex;
    flex-direction: column;
    gap: u($spacing-unit * 1.5);

    @include mq($from: $viewport--md) {
        flex-direction: row;
        gap: u($spacing-unit * 3);
        justify-content: space-between;
        //margin-left: u($spacing-unit * -3);

        &--2by3 {
            align-items: center;
        }

        &--3by2 {
            align-items: flex-start;
        }

        &-body,
        &-object {
            //margin-left: u($spacing-unit * 3);
        }
    }

    .Slider-link {
        position: relative;

        
        display: block;

        img {
            width: 100%;
        }

        .Play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .Media-object--video {
        flex-grow: 1;
    }

    &-body {
        flex-grow: 1;
        //margin-top: u($spacing-unit * 3);
        max-width: u(620);

        .Container {
            padding: 0;
        }

        .Content {

            .Field-title {
                &::before {
                    display: none;
                }
            }
        }

        h2 {
            &:not(.TextMedia-title) {
                margin-bottom: u(5);
            }
        }

        h3 {
            &:not(.TextMedia-subtitle) {
                margin-bottom: u(5);
            }
        }

        p, ul {
            color: rgba($cc-black, 0.6);
        }

        > :last-child {
            margin-bottom: 0;
        }

        .TextMedia--3by2 & {
            max-width: u(620);
        }

        .TextMedia--2by3 & {

            @include mq($until: $viewport--md - 1) {
                //margin-top: u($spacing-unit--lg);
            }
        }
    }

    &-object {

        .TextMedia > &, .TextMedia > .Media-link > & {
            //@include grid(u(60) 1fr u(60), u(60) 1fr u(60), true);
        }

        .TextMedia--2by3 > &, .TextMedia--2by3 > .Media-link > & {

            @include mq($from: $viewport--md) {
                //@include grid(u(90) 1fr u(90), u(60) 1fr u(60), true);
            }
        }

        &::after {
            @include position(relative, $z: -1);
            @include dimensions(100%);
            //content: '';
            display: block;
        }
    }

    &-title {
        position: relative;
        &::before {
            @include position(absolute, $top: 50%, $right: unset, $bottom: unset, $left: 100%);
            border-bottom: px(1) solid;
            content: '';
            display: block;
            width: u($structure-responsive-gutter--l);
            transform: translateX(62px);

            @include mq($from: $viewport--md, $until: $structure-responsive-breakpoint--sm - 1) {
                width: u($structure-gutter--l);
            }

            @include mq($from: $structure-responsive-breakpoint--sm) {
                width: calc((100vw - #{u($structure-width--sm)}) / 2);
            }
        }
    }

    &-subtitle {

        &:not(:first-child) {
            margin-top: u($spacing-unit--lg);
        }
    }

    &.TextMedia--2by3 {

        .Media-object::after {
            @include grid-area(1, 3, 1, 3);
        }

        .Media-link,
        .Media-image {
            @include grid-area(2, 4, 2, 4);
        }

        &.TextMedia--right {
            @include mq($from: $viewport--md) {
                flex-direction: row-reverse;
            }

            .Media-object::after {
                @include grid-area(2, 4, 1, 3);
            }

            .Media-link,
            .Media-image {
                @include grid-area(1, 3, 2, 4);
            }
        }
    }

    &.TextMedia--3by2, &.TextMedia--noRatio {

        .Media-object::after {
            @include grid-area(2, 4, 2, 4);
        }

        .Media-link,
        .Media-image {
            @include grid-area(1, 3, 1, 3);
        }

        &.TextMedia--right {
            @include mq($from: $viewport--md) {
                flex-direction: row-reverse;
            }

            .TextMedia {
                &-object, &-body {
                    margin-left: 0;
                    //margin-right: u($spacing-unit * 3);

                    @include mq($until: $viewport--md - 1) {
                        margin-right: u(0);
                    }
                }
            }

            .Media-object::after {
                @include grid-area(1, 3, 1, 3);
            }

            .Media-link,
            .Media-image {
                @include grid-area(2, 4, 2, 4);
            }
        }
    }
    &.TextMedia--noRatio {
        .Media-link {
            width: 100%;
            display: block;
        }
    }

    .Block--sector & {

        @include mq($from: $viewport--md) {
            align-items: flex-start;

            &-object {
                margin-left: 0;
            }
        }

        .Content {

            .Field-title.Field-title--h2 {
                
                &::before {
                    display: none;
                }
            }
        }

        .Media-object::after {
            @include grid-area(1, 3, 1, 3);
        }

        .Media-link,
        .Media-image {
            @include grid-area(2, 4, 2, 4);
        }
    }

    .Block--jobs.accent-grey & {
        .Media-object {
    
            &::after {
                background-color: $cc-grey--light;
            }
        }
    }

    .Block--jobs.accent-red & {
        .Media-object {
    
            &::after {
                background-color: $cc-red;
            }
        }
    }
    
    .Block--jobs & {
        gap: u(60);

        @include mq($from: $viewport--md) {
            align-items: center;
            justify-content: center;

            &-body {
                margin-top: 0;
            }
        }

        @include mq($until: $viewport--md) {

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &-body {
                max-width: 100%;
                width: 100%;
            }       
        }

        .Media-object {
            position: relative;
            //padding-top: u(60);

            &::after {
                //content: "";
                width: u(346);
                height: calc(100% - 60px);
                position: absolute;
                top: u(0);
                left: u(90);
                z-index: -1;
            }

        }

        .Media-link,
        .Media-image {
            @include grid-area(1, 3, 1, 3);
        }
    }
    .SliderWrapper {
        position: relative;
        .Slider-object {
            text-align: center;
            img {
                width: auto;
                display: inline-block;
            }
        }
        .Slider-button{
            
            background: rgba(30, 30, 30, .6);
        }
        .Slider-button--prev {
            left: 3.75rem;
        }
    }

    &--right {
        .TextMedia-title {
            &::before {
                @include position(absolute, $top: 50%, $right: 100%, $bottom: unset, $left: unset);
                transform: translateX(-62px);
            }
        }
    }
}
