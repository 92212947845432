.Block--bar {
    @include position(relative, $z: 1);
    color: $white;

    @include mq($from: $viewport--lg) {
        background-color: $cc-red;
        display: flex;
    }

    &.is-extended {
        .Bar {
            &:nth-of-type(2n+2) {
                @include mq($from: $viewport--lg) {

                    &::after {
                        @include dimensions(0);
                        @include position(absolute, $top: 0, $left: null, $right: -25px, $bottom: 0, $z: 1);
                        background-color: $cc-red;
                        border-style: solid;
                        border-width: u(140) 0 0 u(140);
                        border-color: transparent transparent transparent $cc-red--dark;
                        content: '';
                        display: block;
                        transform: translateX(-50%), rotate(180deg);
                        width: 0;
                    }
                }
            }
        }
    }
}

.Bar {
    @include transition(width);
    display: flex;
    align-items: center;
    padding-top: u($spacing-unit * 1.5);
    padding-bottom: u($spacing-unit * 1.5);
    justify-content: center;

    &:nth-of-type(2n+1) {
        background-color: $cc-red; 
    }

    &:nth-of-type(2n+2) {
        background-color: $cc-red--dark;

        @include mq($from: $viewport--lg) {
            position: relative;

            &::before {
                @include dimensions(0);
                @include position(absolute, $top: 0, $left: u(35), $bottom: 0, $z: 1);
                background-color: $cc-red--dark;
                border-style: solid;
                border-width: u(140) 0 0 u(140);
                border-color: transparent transparent transparent $cc-red;
                content: '';
                display: block;
                transform: translateX(-50%);
                width: 0;
            }
        }
    }

    @include mq($from: $viewport--lg) {
        padding-top: u($spacing-unit--lg + 2);
        padding-bottom: u($spacing-unit--lg + 2);
        flex-grow: 1;
        height: u(140);

        // &:hover {
        //     width: 60%;
        // }

        // &:not(:hover) {
        //     width: 40%;
        // }
    }

    @include mq($until: $viewport--lg - 1) {
        justify-content: center;
    }

    &,
    &:active,
    &:focus,
    &:hover {
        color: $white;
        text-decoration: none;
    }

    &-item {
        display: flex;
        align-items: center;
        padding-right: u($structure-responsive-gutter--r);
        padding-left: u($structure-responsive-gutter--r);
        flex-direction: column;
        z-index: 2;

        @include mq($from: $viewport--lg, $until: $viewport--lg - 1) {
            display: block;
        }

        @include mq($from: $viewport--lg) {
            max-width: u(600 + if($structure-responsive-gutter--r > 0 and $structure-gutter--r != $structure-responsive-gutter--r, $structure-gutter--r, null));
            width: 100%;
        }
    }

    &-object {
        flex-shrink: 0;
        @include dimensions(30);
        margin-top: u(10);
        margin-bottom: u(10);
    }

    &-body {
        width: 100%;
    }

    .Button--bare {
        margin-bottom: u(10);
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;

        .Button-icon {
            margin-top: 8px;
        }


        @include mq($until: $viewport--md) {
            margin-bottom: 0;
        }
    }
}