.Contact {

    &-body {
        align-self: center;
        background-color: $white;
        border: px(1) solid rgba($cc-grey, 0.2);
        display: flex;
        flex-direction: column;
        padding: u($spacing-unit * 1.5);

        .Nav--socialMedia {
            align-self: flex-end;
            margin-top: auto;
        }
    }

    @include mq($from: $viewport--md) {
        @include grid(calc(65% - #{u(170)}) u(170) 1fr, 1fr);

        &-object {
            @include grid-area(1, 3, 1, 2);
        }

        &-body {
            @include position(relative, $z: 1);
            @include grid-area(2, 4, 1, 2);
            padding: u($spacing-unit * 3, $spacing-unit * 1.5, $spacing-unit * 1.5, $spacing-unit--xl);
            min-height: u(380);
            max-width: u(510);
            margin-top: u($spacing-unit--lg);
            margin-bottom: u($spacing-unit--lg);
        }
    }

    &-link {
        margin-top: u($spacing-unit * 1.5);
    }
}

