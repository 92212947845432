/* Mixin: Caret */
@mixin caret($caret-width, $border-width, $direction, $border-color, $background-color) {
    $width: $caret-width / 2;
    position: relative;

    &:before,
    &:after {
        content: "";
        position: absolute;
    }

    @if $direction == top {
        @include dimensions($width * 2, $width);

        &:before {
            top: 0;
            left: 0;
            border-bottom: u($width) solid $border-color;
            border-left: u($width) solid transparent;
            border-right: u($width) solid transparent;
        }

        &:after {
            left: u($border-width);
            top: u($border-width);
            border-bottom: u($width - $border-width) solid $background-color;
            border-left: u($width - $border-width) solid transparent;
            border-right: u($width - $border-width) solid transparent;
        }
    } @else if $direction == right {
        @include dimensions($width, $width * 2);

        &:before {
            top: 0;
            left: 0;
            border-left: u($width) solid $border-color;
            border-top: u($width) solid transparent;
            border-bottom: u($width) solid transparent;
        }

        &:after {
            left: 0;
            top: u($border-width);
            border-left: u($width - $border-width) solid $background-color;
            border-top: u($width - $border-width) solid transparent;
            border-bottom: u($width - $border-width) solid transparent;
        }
    } @else if $direction == bottom {
        @include dimensions($width * 2, $width);

        &:before {
            top: 0;
            left: 0;
            border-top: u($width) solid $border-color;
            border-left: u($width) solid transparent;
            border-right: u($width) solid transparent;
        }

        &:after {
            left: u($border-width);
            top: 0;
            border-top: u($width - $border-width) solid $background-color;
            border-left: u($width - $border-width) solid transparent;
            border-right: u($width - $border-width) solid transparent;
        }
    } @else if $direction == left {
        $w: $width * 2;
        $h: $width * 2 / 5 * 4;
        @include dimensions($w, $h);

        &:before {
            top: 0;
            left: 0;
            border-right: u($w) solid $border-color;
            border-top: u($h) solid transparent;
            border-bottom: u($h) solid transparent;
        }

        &:after {
            left: u($border-width);
            top: u($border-width);
            border-right: u($w - $border-width) solid $background-color;
            border-top: u($h - $border-width) solid transparent;
            border-bottom: u($h - $border-width) solid transparent;
        }
    }
}
