/* Footer */
.Main.without-footer {
    margin-bottom: u($spacing-unit * 3);

    @include mq($from: $viewport--md) {
        margin-bottom: u($spacing-unit * 6);
    }
}

.Footer {
    background-color: $footer-bg-color;
    color: rgba($cc-black, 0.6);
    margin-top: u($spacing-unit * 3);

    @include mq($from: $viewport--md) {
        margin-top: u($spacing-unit * 6);
    }

    // Top
    &-top {
        padding-top: u($spacing-unit * 3);
        padding-bottom: u($spacing-unit * 3);
        margin-top: u($spacing-unit--lg * -1);
        margin-left: u($spacing-unit * -1);

        @include mq($from: $viewport--md) {
            display: flex;
            flex-wrap: wrap;
            // @include grid(repeat(3, minmax(min-content, #{u(340 + $spacing-unit)})), repeat(3, auto));
            justify-content: space-between;
            padding-top: u($spacing-unit--xl);
            padding-bottom: u($spacing-unit--xl);
        }

        @supports( grid-area: auto ) {

            @include mq($from: $viewport--md, $until: $viewport--lg - 1) {
                @include grid(repeat(6, 1fr), repeat(3, auto));
            }

            @include mq($from: $viewport--lg) {
                @include grid(repeat(3, minmax(min-content, #{u(340 + $spacing-unit)})), repeat(3, auto));
            }
        }

        .Logo,
        p {
            margin-bottom: u($spacing-unit * 1.5);
        }
    }

    &-item {
        padding-top: u($spacing-unit--lg);
        padding-left: u($spacing-unit);

        @include mq($from: $viewport--md) {
            max-width: u(340 + $spacing-unit);
            width: 100%;

            @supports( grid-area: auto ) {
                max-width: none;
                width: auto;
            }

            &--certificats {
                max-width: u(340 * 2 + $spacing-unit);
                margin-left: auto;

                @supports( grid-area: auto ) {
                    max-width: none;
                    margin-left: 0;
                }
            }
        }

        @include mq($from: $viewport--md, $until: $viewport--lg - 1) {

            &:nth-child(1) {
                @include grid-area(1, 5, 1, 2);
            }

            &:nth-child(2) {
                @include grid-area(1, 4, 2, 3);
            }

            &:nth-child(3) {
                @include grid-area(4, 8, 2, 3);
            }

            &--certificats {
                @include grid-area(1, 8, 3, 4);
                margin-top: u(45);
            }
        }

        @include mq($from: $viewport--lg) {

            @for $i from 1 through 3 {

                &:nth-child(#{$i}) {
                    @include grid-area($i * 1, $i + 1, 1, 2);
                }
            }

            &--certificats {
                @include grid-area(2, 4, 2, 3);
                margin-top: u(45);
            }
        }
    }

    &-title {
        border-bottom: px(1) solid rgba($cc-black, 0.1);
        color: $cc-black;
        padding-bottom: u($spacing-unit);
        margin-bottom: u($spacing-unit);
        line-height: 1.5;
    }

    // Bottom
    &-bottom {
        border-top: px(1) solid rgba($cc-black, 0.1);
        font-size: 75%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: u($spacing-unit);
        padding-bottom: u($spacing-unit);
    }
}
