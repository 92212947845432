/* Table */
.#{$table-namespace}Table {
    background-color: $table-bg-color;
    border: 0;
    border-spacing: 0;
    table-layout: fixed;
    text-align: left;
    width: 100%;

    th,
    td {
        padding-top: u($table-padding-t);
        padding-bottom: u($table-padding-t);

        &:not(:first-child) {
            padding-left: u($table-padding-l);
        }

        &:not(:last-child) {
            padding-right: u($table-padding-r);
        }
    }

    td {
        vertical-align: middle;
    }

    th {
        padding-top: u($spacing-unit);
        padding-bottom: u($spacing-unit);
        vertical-align: top;
    }

    thead {
        background-color: $table-header-color;
        border-bottom: 2px solid $table-border-color;
    }

    tbody {

        td {
            border-top: px(1) solid $table-border-color;
        }

        tr:last-child > td {

            @include mq($from: $viewport--md) {
                border-bottom: px(1) solid $table-border-color;
            }
        }
    }

    tfoot {
        background-color: $table-footer-color;
        border-top: px(2) solid $table-border-color;
    }

    // Responsive
    @include mq($until: $viewport--md - 1) {

        &.is-responsive {
            border-width: 0;

            th,
            td {
                padding: 0;
            }

            tr + tr {
                border-top: px(1) solid $table-border-color;
                margin-top: u($spacing-unit);
                padding-top: u($spacing-unit);
            }

            td + td {
                margin-top: u($spacing-unit--sm);
            }

            &,
            tbody,
            tfoot,
            tr {
                display: block;
            }

            tbody {

                tr {
                    display: flex;
                    flex-direction: column;
                }

                td {
                    border-top: 0;

                    tr:last-child > & {
                        border-bottom: 0;
                    }
                }
            }

            thead {
                display: none;
            }

            tfoot {
                border: 1px solid $table-border-color;
                border-top-width: 2px;
            }

            .Table-cell {
                display: flex;
                vertical-align: top;
                width: 100%;

                // Style labels
                &[data-label]::before {
                    content: attr(data-label);
                    font-size: 90%;
                    font-weight: 500;
                    display: block;
                    flex-shrink: 0;
                    text-align: left;
                    width: u(120);
                }
            }
        }
    }
}

// Striped
.#{$table-namespace}Table--striped {

    tbody {

        tr:nth-child(even) {
            background-color: $table-bg-color--accent;
        }
    }

    // Responsive
    @include mq($until: $viewport--lg - 1) {

        &.is-responsive {

            tbody {

                // Remove background color
                tr:nth-child(even) {
                    background-color: transparent;
                }
            }
        }
    }
}

// Minimized
.#{$table-namespace}Table--minimized {

    td {
        padding: 0 !important;
        border: 0 !important;
    }
}