/* Objects: FlexEmbed */

// Namespace
$flexEmbed-namespace:                   $namespace !default;

// Ratios
$flexEmbed-ratios: (
    "2by1": (2:1),
    "3by1": (3:1),
    "3by2": (3:2),
    "4by3": (4:3),
    "16by9": (16:9),
    "1by2": (1:2),
    "1by3": (1:3),
    "2by3": (2:3),
    "3by4": (3:4),
    "9by16": (9:16)
) !default;
