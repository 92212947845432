.Nav--group {
    height: 0;
    opacity: 0;
    overflow: hidden;

    @include mq($from: $viewport--lg) {
        position: absolute;
        z-index: 2;
        background: white;
    }
    

    @include mq($until: $viewport--lg - 1) {
        width: calc(100% + #{$spacing-unit--lg}px);
        margin-left: u(-$spacing-unit);
        margin-top: u(6);
    }

    &.is-active {
        height: auto;
        opacity: 1;
        transition: all 0.5s ease;
        overflow: auto;
    }

    .Nav-list {
        display: grid;
        gap: u(6);

        &.has-3-items {
            grid-template-columns: repeat(3, 1fr);

            @include mq($until: $viewport--lg) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        &.has-4-items {
            grid-template-columns: repeat(4, 1fr);

            @include mq($until: $viewport--lg) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
    
    .Nav-item {
        background-color: $cc-grey--light;
    }

    .Nav-link {
        padding: u(40);
        color: $cc-grey--darker;
        height: 100%;

        @include mq($until: $viewport--lg - 1) {
            padding: u(20, 40);
        }

        @include mq($until: $viewport--md) {
            padding: u(20);
        }

        &:active,
        &:focus,
        &:hover {
            background-color: $cc-red;
            color: $white;
            
        }

        .Title {
            font-weight: 700;
            font-size: u(20);
            line-height: u(28);
            margin-bottom: u(8);
            color: inherit;
        }

        .Text {
            font-weight: 400;
            font-size: u(14);
            line-height: u(21);
            letter-spacing: -0.492308px;
            margin-bottom: 0;
        }
    }
}
