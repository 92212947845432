.Block--banner {
    color: $cc-black;

    @include mq($from: $viewport--md) {

        &:first-child {
            margin-top: u($spacing-unit--lg);
        }
    }
}

.Banner {

    @include mq($from: $viewport--md) {
        @include grid(calc(65% - #{u(230)}) minmax(min-content, #{u(230)}) 1fr, 1fr);

        &-object {
            @include grid-area(1, 3, 1, 2);
        }

        &-body {
            @include grid-area(2, 4, 1, 2);
            align-self: center;

            @include mq($from: $viewport--md) {
                margin-top: u($spacing-unit--lg);
                margin-bottom: u($spacing-unit--lg);
            }
        }
    }

    &-body {
        @include position(relative, $z: 1);
        background-color: $cc-grey--light;
        font-weight: 100;
        padding: u($spacing-unit--lg);
        line-height: 1.4;

        @include mq($from: $viewport--md) {
            padding: u($spacing-unit--xl);
        }
    }

    &-title {
        margin-bottom: u($spacing-unit--sm);
        color: inherit;

        @include mq($from: $viewport--md) {
            line-height: 1.5;
        }

        strong {
            color: $cc-red--dark;
            font-weight: inherit;
        }
    }
}
