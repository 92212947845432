.Block--faq {
    background-color: $cc-red;
    color: $white;
}

.Faq {

    &-title {
        color: currentColor;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: u($spacing-unit * 2.5);

        .Shape {
            margin-bottom: u($spacing-unit);
        }
    }

    &-items {
        background-color: rgba($cc-grey--light, 0.1);
    }

    &-item {
        border-top: px(1) solid $white;

        &:last-child {
            border-bottom: px(1) solid $white;
        }
    }

    &-question {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 87.5%;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: px(1.5);
        line-height: 2.14;
        padding: u($spacing-unit * 0.75, $spacing-unit);

        @include mq($from: $viewport--md) {
            padding-right: u($spacing-unit--lg);
            padding-left: u($spacing-unit--lg);
        }
    }

    &-icon {
        @include transition(transform);
        flex-shrink: 0;
        margin-left: u($spacing-unit);

        .is-active & {
            transform: rotate(90deg);
        }
    }

    &-answer {
        @include transition(max-height);
        max-height: 0;
        overflow: hidden;

        :last-child {
            margin-bottom: 0;
        }
    }

    &Answer-body {
        padding-right: u($spacing-unit);
        padding-bottom: u($spacing-unit);
        padding-left: u($spacing-unit);

        a:not([class]) {

            &,
            &:active,
            &:focus,
            &:hover {
                color: currentColor;
            }
        }

        @include mq($from: $viewport--md) {
            padding-right: u($spacing-unit--lg);
            padding-left: u($spacing-unit--lg);
        }
    }
}
