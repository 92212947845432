.Block--gallery {

    .Block + &.Block,
    &.Block + .Block {

        .ProjectsPage & {

            @include mq($from: $viewport--md) {
                margin-top: u($spacing-unit--xl);
            }
        }
    }

    .Block--content + &.Block,
    &.Block + .Block--content {

        @include mq($from: $viewport--md) {
            margin-top: u($spacing-unit * 3);
        }
    }
}

.Gallery {
    margin-top: u($spacing-unit * -0.75);
    margin-left: u($spacing-unit * -0.75);

    @include mq($from: $viewport--sm) {

        &--3 {
            @include grid(repeat(3, 1fr), auto);

            .Gallery-item {

                &:nth-child(1) {
                    @include grid-area(1, 2, 1, 2);
                }

                &:nth-child(2) {
                    @include grid-area(2, 3, 1, 2);
                }

                &:nth-child(3) {
                    @include grid-area(3, 4, 1, 2);
                }
            }

            .Gallery-object {
                padding-bottom: (100% / 15 * 16);
            }
        }

        &--4 {
            @include grid(repeat(3, 1fr), auto auto);

            .Gallery-item {

                &:nth-child(1) {
                    @include grid-area(1, 3, 1, 2);

                    .Gallery-object {
                        padding-bottom: (100% / 795 * 416);
                    }
                }

                &:nth-child(2) {
                    @include grid-area(3, 4, 1, 2);

                    .Gallery-object {
                        padding-bottom: (100% / 15 * 16);
                    }
                }

                &:nth-child(3) {
                    @include grid-area(1, 2, 2, 3);

                    .Gallery-object {
                        padding-bottom: (100% / 15 * 16);
                    }
                }

                &:nth-child(4) {
                    @include grid-area(2, 4, 2, 3);

                    .Gallery-object {
                        padding-bottom: (100% / 795 * 416);
                    }
                }
            }
        }
    }

    + .Gallery {
        padding-top: u($spacing-unit * 0.75);
    }

    &-item {
        padding-top: u($spacing-unit * 0.75);
        padding-left: u($spacing-unit * 0.75);
    }

    &-image,
    &-object {
        height: 100%;
    }

    @include mq($from: $viewport--sm) {

        &-object {
            @include position(relative, $z: 0);
        }

        &-image {
            @include position(absolute, 0, 0, 0, 0, 1);
            @include dimensions(100%);
            object-fit: cover;
        }
    }
}
