.Block--bigBanner {
    position: relative;
    color: $cc-black;
    padding-top: u($spacing-unit * 3)!important;
    padding-bottom: u($spacing-unit * 3);

    @include mq($from: $viewport--md) {

        &:first-child {
            margin-top: u($spacing-unit--lg);
        }
    }

    &--first {
        margin-top: 0!important;
    }
}

.BigBannerImage-object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.BigBanner {
    position: relative;

    &-body {
        @include position(relative, $z: 1);
        background-color: transparentize($cc-grey--light, .1);
        font-weight: 400;
        padding: u($spacing-unit--lg);
        line-height: 1.4;
        max-width: 38rem;

        @include mq($from: $viewport--md) {
            padding: u($spacing-unit--xl);
        }
    }

    &-title {
        margin-bottom: u($spacing-unit--sm);
        color: inherit;

        @include mq($from: $viewport--md) {
            line-height: 1.5;
        }

        strong {
            color: $cc-red--dark;
            font-weight: inherit;
        }
    }

    & .Button {
        margin-top: u($spacing-unit--lg);
    }
}
