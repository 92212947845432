/* Objects: Grid */

// Namespace
$grid-namespace:                   $namespace !default;

// Gutter
$grid-gutter:                      $spacing-unit !default;
$grid-xs-gutter:                   $spacing-unit--xs !default;
$grid-sm-gutter:                   $spacing-unit * 0.75 !default;
$grid-md-gutter:                   $grid-gutter * 1.5 !default;
$grid-lg-gutter:                   $spacing-unit * 2.25 !default;
$grid-xl-gutter:                   $spacing-unit * 3 !default;

// Map
$grid-gutters: (
    xs: $grid-xs-gutter,
    sm: $grid-sm-gutter,
    md: $grid-md-gutter,
    lg: $grid-lg-gutter,
    xl: $grid-xl-gutter
) !default;
