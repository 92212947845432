/* Main */
html {
    background-color: $bg-color;
    color: $text-color;
    font-family: $font-sans;
    height: 100%;

    &.fonts-loaded {
        font-family: $font-daxline-pro;
    }

    &.hide-scroll {
        overflow: hidden;
    }
}

body {
    min-height: 100%;
    position: relative;

    @include mq($from: $viewport--md) {

        &::after {
            @include transition(#{opacity, visibility});
            @include position(fixed, 0, 0, 0, 0, 2);
            background-color: rgba($cc-black, .6);
            content: '';
            transition-delay: 200ms;
            opacity: 0;
            visibility: hidden;

            .show-nav &,
            .show-search & {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
