.Block--boxes {

    + .Block {
        padding-top: u($spacing-unit * 3);

        &--testimonials {
            margin-top: 0;
        }

        @include mq($from: $viewport--md) {
            padding-top: u($spacing-unit * 6);
        }

        .CategoriesPage & {
            margin-top: u($spacing-unit * 1.5);
            padding-top: u($spacing-unit * 1.5);

            @include mq($from: $viewport--md) {
                margin-top: u($spacing-unit * 3);
                padding-top: u($spacing-unit * 3);
            }
        }
    }

    .Boxes {
        @include position(relative, $z: 1);
        padding-bottom: u($spacing-unit--xl);

        &::before {
            @include position(absolute, 0, 0, 0, 0, -1);
            background-color: $cc-grey--light;
            content: '';
            transform: translateY(#{u($spacing-unit * 3)});

            @include mq($from: $viewport--md) {
                transform: translateY(#{u($spacing-unit * 6)});
            }
        }
    }

    .Grid-cell {
        display: flex;
        flex-direction: column;

        > .Box {
            flex-grow: 1;
        }

        .Box-body {
            margin-top: auto;
            margin-bottom: auto;
        }
    }
}

.Box {
    @include transition(#{color, font-weight});
    @include position(relative, $z: 0);
    background-color: $white;
    border: px(1) solid rgba($cc-grey, 0.2);
    display: flex;
    flex-direction: column-reverse;
    font-weight: 100;
    //max-width: u(280);
    margin-right: auto;
    margin-left: auto;
    width: 100%;

    &:not(.is-product) {
        &,
        &:active,
        &:focus {
            color: currentColor;
        }
    
        &:hover {
            color: $cc-red;
            font-weight: 500;
        }
    
        &,
        &:active,
        &:focus,
        &:hover {
            text-decoration: none;
        }
    
        &::after {
            @include dimensions(0, 6);
            @include transition(width);
            @include position(absolute, $right: 0, $bottom: 0, $left: 0, $z: 3);
            background-color: $cc-red;
            content: '';
            display: block;
        }
    
        &:hover::after {
            width: 100%;
        }
    }

    &.is-product {
        .Box-title {
            font-weight: 500;
            font-size: u(15);
            line-height: u(22);
            color: #414042;
            text-align: left;
            margin-bottom: u(10);
        }

        .Box-body {
            padding: u(16);
        }
        
        .Price {
            text-align: left;
        }
    }

    &-buttons {
        display: flex;
        column-gap: u(20);
        margin-top: u(18);

        .Button {
            min-width: auto;
            padding-left: u(18);
            padding-right: u(18);
        }
    }
    
    &-object {
        background-color: $cc-black;
        border: u($spacing-unit--sm) solid $white;
        margin-bottom: u($spacing-unit--sm * -1);
        overflow: hidden;
    }

    &-image {
        width: 100%;
        @include transition(#{opacity, transform});

        .Box:hover & {
            opacity: 0.9;
            transform: scale(1.1);
        }

        .Box.is-product:hover & {
            opacity: 1;
            transform: scale(1);
        }
    }

    &-body {
        padding: u($spacing-unit);
        text-align: center;
        min-height: u(90);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
    }

    &-title {
        color: currentColor;
        font-weight: inherit;
        margin-bottom: 0;
    }
}
