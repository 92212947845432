.Accordion {
    margin-top: u($spacing-unit--sm * 5);

    &-item {
        border-top: u(1) solid $cc-grey--light-50;

        &.is-active {
            margin-bottom: u(20);

            .Accordion-icon {
                transform: rotate(-90deg);
            }
        }
    }

    &-title {
        margin-bottom: 0;
    }

    &-button {
        padding: u(11, 5, 11, 0);
        cursor: pointer;
        display: flex;
        justify-content: space-between;
    }

    &-icon {
        transition: all 0.5s ease;
        margin-top: u(2);
        transform: rotate(90deg);
    }

    &-body {
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s ease;
    }
}