.Categories {
    display: flex;
    flex-wrap: wrap;
    margin-top: u($spacing-unit--sm * -1);
    margin-left: u($spacing-unit--sm * -1);

    > .Category {
        margin-top: u($spacing-unit--sm);
        margin-left: u($spacing-unit--sm);
    }
}

.Category {
    background-color: $cc-grey--light;
    border: px(1) solid rgba($cc-grey, 0.2);
    border-radius: u($spacing-unit * 0.75);
    color: rgba($cc-black, 0.6);
    display: block;
    padding-right: u($spacing-unit * 0.75);
    padding-left: u($spacing-unit * 0.75);

    &,
    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
    }

    &.is-active {
        background-color: $cc-red;
        color: $white;
    }
}

.Filter {

    .Intro-body + & {
        margin-top: u($spacing-unit * 1.5);
    }
}
