.Block--slider {

    .Block + &.Block,
    &.Block + .Block {

        .ProjectsPage & {

            @include mq($from: $viewport--md) {
                margin-top: u($spacing-unit--xl);
            }
        }
    }

    & + p {
        margin-top: 2rem;
    }

    .Slider {

        &Wrapper {
            position: relative;
            padding-right: u(20);
            padding-left: u(20);
        }
    }

    .Slider-item {
        position: relative;

        @include mq($from: $viewport--md) {
            width: auto;
        }

        @include mq($until: $viewport--md - 1) {

            .Slider-image {
                width: 100%;
            }
        }
    }

    .Slider-button {
        @include position(absolute, $top: 50%, $z: 1);
        @include transition(#{background-color, border-color, color});
        @include dimensions(40);
        background-color: $white;
        border: px(1) solid $cc-grey;
        border-radius: 100%;
        color: $cc-black;
        filter: none;
        margin-top: u(-20);
        transform: none;

        &:not(.is-disabled):hover {
            background-color: $cc-red;
            border-color: $cc-red;
            color: $white;
        }

        + .Slider-button {
            margin-left: u($spacing-unit / 2.5);
        }

        &--prev {
            transform: scaleX(-1);
            left: 0;
        }

        &--next {
            right: 0;
        }
    }

    .Slider-link {
        @include position(absolute, 0, 0, 0, 0, 1);
    }
}
