.Block--columns {
    background-color: $cc-red;
    color: $white;
    padding-top: u($spacing-unit * 3);
    padding-bottom: u($spacing-unit * 3);

    a:not([class]) {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
        }
    }

    @include mq($from: $viewport--md) {
        padding-top: u($spacing-unit * 5);
        padding-bottom: u($spacing-unit * 5);
    }

    + .Block--content {
        margin-top: u($spacing-unit * 3);
    }

    &--white {
        background-color: white;
        padding-top: u($spacing-unit);
        padding-bottom: u($spacing-unit);
        color: $cc-grey--dark;

        .Column-object {
            background-color: transparent;
            padding: 0;
        }

        .Column-title {
            color: $cc-red;
        }

        @include mq($until: $viewport--sm) {
            .Column-object {
                height: auto;
            }
        }
    }
}

.Column {

    &.has-link {
        color: currentColor;
        text-decoration: none;

        &:hover {

            .Column-icon {
                transform: scale(1.2);
            }
        }

        .Column-icon {
            @include transition(transform);
        }

        + .Column-button {
            margin-top: 1rem;
        }
    }

    &-object {
        @include dimensions(150);
        background-color: $white;
        border-radius: 100%;
        color: $cc-black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: auto;
        margin-bottom: u($spacing-unit * 1.5);
        margin-left: auto;
        padding: 0 1rem;
    }

    &-body {
        text-align: center;
        max-width: u(280);
        margin-right: auto;
        margin-left: auto;

        @include mq($until: $viewport--md - 1) {
            max-width: 80%;
        }

        :last-child {
            margin-bottom: 0;
        }

        + .Column-button {
            margin-top: 1rem;
        }
    }

    &-title {
        margin-bottom: u($spacing-unit--sm);
        color: inherit;
    }

    &-button {
        min-width: unset!important;
    }
}

