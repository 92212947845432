.Nav--contact {
    font-size: 125%;
    font-weight: 100;

    .Nav-item {
        display: flex;
        align-items: center;

        + .Nav-item {
            margin-top: u($spacing-unit--sm);
        }
    }

    .Nav-object {
        color: $white;
    }

    .Nav-link {
        color: $cc-black;
        flex-grow: 1;

        &:active,
        &:focus,
        &:hover {
            color: $cc-red;
        }
    }

    .Nav-object {
        @include dimensions(40);
        background-color: $cc-red;
        border-radius: 100%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: u($spacing-unit);
    }
}
