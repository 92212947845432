/* Slider: navigation */
.Slider-button {
    @include transition(opacity);
    @include dimensions(41, 41);
    @include position(absolute, $top: 50%, $z: 10);
    cursor: pointer;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%) translateY(#{u(-24.5)});
    filter: drop-shadow(0 0 px($spacing-unit / 2.5) rgba($black, 0.5));

    &.is-disabled {
        cursor: auto;
        opacity: 0.35;
    }

    &.is-locked {
        display: none;
    }
}

.Slider-button--prev,
.Slider--rtl .Slider-button--next {
    left: 0;
    transform: scaleX(-1) translateY(-50%) translateY(#{u(-24.5)});
}

.Slider-button--next,
.Slider--rtl .Slider-button--prev {
    right: 0;
}
