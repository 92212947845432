/* Main */
.Nav--main {

    @include mq($until: $viewport--lg - 1) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: u(20);
    }

    @include mq($until: $viewport--md - 1) {
        padding-left: u(0);
    }

    // List
    > .Nav-list {
        margin-left: u($spacing-unit * -1.5);

        @include mq($until: $viewport--lg - 1) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }

        // Item
        > .Nav-item {
            padding-left: u($spacing-unit * 1.5);

            &.has-children {
                display: flex;
                align-items: center;
                
                &.is-small {
                    right: auto;
                    left: auto;


                    [data-level="1"] {

                        > .Nav-item > .Nav-link {
                            margin-bottom: u(0);
                        }

                        .Nav-item {
                            + .Nav-item {
                                margin-top: u(5);
                            }
                        }
                    }

                    .Nav--children {
                        right: auto;
                        left: auto;
                        top: 130px;
                        border-left: px(1) solid rgba($cc-grey, 0.2);
                        border-right: px(1) solid rgba($cc-grey, 0.2);
                        padding: 0.625rem 1.25rem;
                    }

                    &::before {
                        display: none;
                    }
                }

            }

            @include mq($until: $viewport--lg - 1) {

                + .Nav-item {
                    margin-top: u($spacing-unit);
                }

                .show-nav & {
                    @include transition(#{opacity, transform}, #{300ms, 1s}, #{linear, cubic-bezier(.19,1,.22,1)});

                    @for $i from 1 through 10 {
                        $delay: (0.02s * $i);

                        &:nth-child(#{$i}) {
                            transition-delay: $delay;
                        }
                    }
                }

                html:not(.show-nav) & {
                    opacity: 0;
                    transform: translateY(#{u($spacing-unit)});
                }
            }
        }
    }


    // Link
    .Nav-link {
        color: currentColor;

        &.Delta {
            text-transform: uppercase;
        }

        &:active,
        &:focus,
        &:hover,
        &.is-active {
            color: $cc-red;
        }
    }

    // Children
    .Nav--children {
        @include transition(#{opacity, visibility});
        @include position(absolute, $top: u(180), $right: 0, $left: 0);
        background-color: $cc-grey--light;
        border-top: px(1) solid rgba($cc-grey, 0.2);
        border-bottom: px(1) solid rgba($cc-grey, 0.2);
        color: $cc-black;
        padding-top: u($spacing-unit * 3);
        padding-bottom: u($spacing-unit * 3);

        .Header.is-notTop.is-pinned & {
            top: u(120);
        }

        &::before {
            @include dimensions(100%, 60);
            @include position(absolute, $top: 0);
            content: '';
            transform: translateY(-100%);
        }

        > .Container {

            > .Nav-list {
                display: grid;
                grid-template-columns: repeat(4, minmax(0, 1fr));
                gap: u($spacing-unit * 1.5);

                > .Nav-item {}
            }
        }

        [data-level="1"] {

            > .Nav-item > .Nav-link {
                margin-bottom: u($spacing-unit);
            }
        }

        [data-level="2"] {
            color: rgba($cc-black, 0.6);
            line-height: 1.4125;

            .Nav-item {

                + .Nav-item {
                    margin-top: u($spacing-unit / 2.5);
                }

                &.is-active {
                    color: $cc-red;
                    display: flex;
                    align-items: center;

                    .Nav-icon {
                        display: block;
                        flex-shrink: 0;
                        margin-right: u($spacing-unit / 2.5);
                    }
                }
            }
        }
    }

    .Nav-item:not(.is-hover) > .Nav--children {
        opacity: 0;
        visibility: hidden;
    }
}
