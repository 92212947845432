.Tips {

    &-body {

        @include mq($from: $viewport--md) {
            margin-left: u(62);
        }
    }

    &-item {

        & + & {
            margin-top: u($spacing-unit--lg);
        }
    }
}
